import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { DataLayerVariablesFunnelEvent, GAEventKey } from 'types';

import { getFunnelEventVariables } from 'helpers/funnelAnalytics';

import services from 'services';

type FunnelAnalyticsProps = {
  customEvent?: GAEventKey;
  customVariables?: Partial<DataLayerVariablesFunnelEvent>;
};

export const FunnelAnalytics: React.FC<FunnelAnalyticsProps> = ({ customEvent, customVariables }): null => {
  const { pathname } = useLocation();

  const sendAnalyticsData = useCallback(() => {
    const event = customEvent ?? GAEventKey.FUNNEL_ANALYTICS_PAGE_VIEW;
    const eventVariables = getFunnelEventVariables({
      pathname,
      customVariables,
    });

    services.analyticsService.pushToDataLayer({
      event,
      variables: eventVariables,
    });
  }, [customEvent, customVariables, pathname]);

  useEffect(() => {
    sendAnalyticsData();
  }, [pathname, sendAnalyticsData]);

  return null;
};

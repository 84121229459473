export const LOCALSTORAGE_KEYS = {
  // GENERAL
  LOCALE: 'locale',

  // UI
  SIDEMENU_CATEGORIES_MINIMIZED: 'sideMenuCategoriesMinimized',

  // AUTHENTICATION
  ACCESS_TOKEN: 'accessToken',
  AUTH_KEY: 'authKey',
  USER_DEVICE_ID: 'userDeviceId',

  // MULTI-DEVICE MANAGEMENT (aka MobileToken)
  MULTI_DEVICE_TOKEN_AKA_MOBILE_TOKEN: 'multiDeviceToken',

  // SIP/VoIP
  SIP_INSTANCE_ID: 'sipInstanceId',
  SIP_CALLS_INCOMING_RINGTONE_DISABLED: 'sipCallsIncomingRingtoneDisabled',
  SIP_CALLS_WEBRTC_ADVANCED_MEDIA_CONSTRAINTS_ENABLED: 'sipCallsWebRTCAdvancedMediaConstraintsEnabled',
  SIP_CALLS_WEBRTC_HD_CODEC_ENABLED: 'sipCallsWebRTCHdCodecEnabled',

  // BROWSER TABS
  BROWSER_TABS_ACTIVE_ID: 'browserTabsActiveId',

  // INFO BAR
  INFO_BAR_BROWSER_RECOMMENDATION_DISMISSED: 'infoBarBrowserRecommendationDismissed',

  // FUNNEL ANALYTICS
  FUNNEL_ANALYTICS_DATA: 'funnelAnalyticsData',
} as const;

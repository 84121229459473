import classnames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { showWarningToast } from '@onoff/toast-notification';

import { selectUserKycRequired, useWebAppSelector } from '@redux';
import { ButtonColorScheme, ButtonDashboard } from 'components/Button';
import { IconCommonNames } from 'components/Icon';
import { ModalsDialpad, ModalsNewThread } from 'components/Modals';
import { useCategoriesCapabilities, useUserCapabilities } from 'hooks';
import { useSIPCallMethodsOutgoing } from 'sip';

import styles from './SubHeaderActions.module.scss';

const SubHeaderActions: React.FC = () => {
  const intl = useIntl();

  const [isModalNewThreadOpen, setIsModalNewThreadOpen] = useState<boolean>(false);
  const [isModalDialpadOpen, setIsModalDialpadOpen] = useState<boolean>(false);
  const { canUserMakeCalls, canUserSendSMS, isUserB2B } = useUserCapabilities();
  const { canAnyOfCategoriesSendSMS } = useCategoriesCapabilities();
  const { canUserMakeCallsBySIP } = useSIPCallMethodsOutgoing();
  const isKycRequired = useWebAppSelector(selectUserKycRequired);

  const onClickHandlerOpenDialPad = (): void => {
    setIsModalDialpadOpen(true);
  };

  const onClickHandlerModalNewThread = useCallback(
    (toggle: boolean): void => {
      if (canAnyOfCategoriesSendSMS) {
        setIsModalNewThreadOpen(toggle);
      } else {
        showWarningToast({
          heading: intl.formatMessage({ id: 'Notifications.LandlineNumbers.title' }),
          message: intl.formatMessage({ id: 'Notifications.LandlineNumbers.description' }),
        });
      }
    },
    [canAnyOfCategoriesSendSMS, intl],
  );

  return (
    <div className={styles.root}>
      {canUserMakeCalls && (
        <>
          <ButtonDashboard
            onClick={onClickHandlerOpenDialPad}
            iconName={IconCommonNames.CALL}
            text={intl.formatMessage({ id: 'SubHeader.Actions.make_a_call' })}
            colorScheme={isUserB2B ? ButtonColorScheme.B2B : ButtonColorScheme.B2C}
            isDisabled={!canUserMakeCallsBySIP || isKycRequired}
          />

          <ModalsDialpad
            isOpen={isModalDialpadOpen}
            onRequestClose={() => setIsModalDialpadOpen(false)}
          />
        </>
      )}
      {canUserSendSMS && (
        <>
          <ButtonDashboard
            className={classnames(styles.buttonMessage)}
            onClick={() => onClickHandlerModalNewThread(true)}
            iconName={IconCommonNames.MESSAGE}
            text={intl.formatMessage({ id: 'SubHeader.Actions.new_message' })}
            colorScheme={isUserB2B ? ButtonColorScheme.B2B : ButtonColorScheme.B2C}
            isDisabled={isKycRequired}
          />

          <ModalsNewThread
            isOpen={isModalNewThreadOpen}
            onClose={() => onClickHandlerModalNewThread(false)}
          />
        </>
      )}
    </div>
  );
};

export default SubHeaderActions;

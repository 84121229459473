import { Reducer } from '@reduxjs/toolkit';

import { PURCHASE } from '@constants';

import { PurchaseActionTypes, PurchaseReduxState, REDUX_ACTION_TYPES } from '../../types';

export const purchaseInitialState: PurchaseReduxState = {
  isForbidden: false,
  isFailureGeneral: false,
  categoryId: '',
  virtualPhoneNumber: '',
  virtualPhoneNumberAsFormatted: '',
  email: '',
  plans: [],
  summary: {
    isLoading: true,
    isFailure: false,
    data: {
      price: 0,
      productId: '',
      month: PURCHASE.DEFAULT_PLAN_PERIOD,
      currencyCode: '',
    },
  },
  submission: {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    codeError: '',
    codePaymentResponse: '',
  },
};

export const purchaseReducer: Reducer<PurchaseReduxState, PurchaseActionTypes> = (state, action) => {
  const reducerState: PurchaseReduxState = state || purchaseInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.PURCHASE_SET_IS_FORBIDDEN: {
      const { isForbidden } = action;

      return {
        ...purchaseInitialState,
        isForbidden,
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_SET_IS_FAILURE_GENERAL: {
      const { isFailureGeneral } = action;

      return {
        ...reducerState,
        isFailureGeneral,
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_RESTART_AGAIN: {
      return {
        ...reducerState,
        isFailureGeneral: false,
        submission: purchaseInitialState.submission,
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_SET_DATA: {
      const { categoryId, virtualPhoneNumber, virtualPhoneNumberAsFormatted, email } = action;

      return {
        ...reducerState,
        isFailureGeneral: false,
        categoryId,
        virtualPhoneNumber,
        virtualPhoneNumberAsFormatted,
        email,
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_REMOVE_DATA: {
      return purchaseInitialState;
    }

    case REDUX_ACTION_TYPES.PURCHASE_SET_SUMMARY_IS_LOADING: {
      const { isLoading } = action;

      return {
        ...reducerState,
        summary: {
          ...reducerState.summary,
          isLoading,
        },
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_SET_SUMMARY_IS_FAILURE: {
      const { isFailure } = action;

      return {
        ...reducerState,
        summary: {
          ...reducerState.summary,
          isFailure,
        },
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_SET_SUMMARY_DATA: {
      const { data } = action;

      return {
        ...reducerState,
        summary: {
          isLoading: false,
          isFailure: false,
          data,
        },
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_SET_SUBMISSION_IS_LOADING: {
      const { isLoading } = action;

      return {
        ...reducerState,
        submission: {
          ...reducerState.submission,
          isLoading,
        },
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_SET_SUBMISSION_IS_FAILURE: {
      const { isFailure } = action;

      return {
        ...reducerState,
        submission: {
          ...reducerState.submission,
          isFailure,
        },
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_SET_SUBMISSION_IS_SUCCESS: {
      const { isSuccess } = action;

      return {
        ...reducerState,
        submission: {
          ...purchaseInitialState.submission,
          isSuccess,
        },
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_SET_SUBMISSION_ERROR_CODES: {
      const { codeError, codePaymentResponse } = action;

      return {
        ...reducerState,
        submission: {
          ...reducerState.submission,
          codeError,
          codePaymentResponse,
        },
      };
    }

    default:
      return reducerState;
  }
};

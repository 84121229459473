export enum ButtonSize {
  DEFAULT = 'default',
  LARGE = 'large',
}

export enum ButtonColorScheme {
  B2B = 'b2b',
  B2C = 'b2c',
}

export enum ButtonDashboardVariant {
  DEFAULT = 'default',
  OUTLINED = 'outlined',
}

import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { EnvTemplate } from 'types';

import { ErrorCore, ErrorType } from 'components/Error';
import { FunnelAnalytics } from 'components/FunnelAnalytics';
import { ApplicationPaths } from 'routes';

import styles from './ErrorNotFound.module.scss';

const ErrorNotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const returnToHomepage = () => {
    navigate(ApplicationPaths.ROOT);
  };

  return (
    <>
      <ErrorCore
        type={ErrorType.ERROR_404}
        onClick={returnToHomepage}
        title={intl.formatMessage({ id: 'ErrorPage.page_not_found' })}
        description={intl.formatMessage({ id: 'ErrorPage.page_does_not_exist' })}
        buttonText={intl.formatMessage({ id: 'ErrorPage.home_page' })}
        classNameIllustration={styles.illustration}
      />
      <FunnelAnalytics customVariables={{ envTemplate: EnvTemplate.ERROR_NOT_FOUND }} />
    </>
  );
};

export default ErrorNotFoundPage;

import { createSelector } from '@reduxjs/toolkit';

import { Category } from 'types';

import {
  changeCategoriesColors,
  filterCategoriesByActiveLandlineNumbers,
  filterCategoriesByCanMakeCalls,
  filterCategoriesByCanSendMessages,
  getCategoriesByVisibility,
  sortCategoriesByExpiredFirst,
} from 'helpers';

import {
  SelectCategories,
  SelectCategoriesCancelSubscriptionStatus,
  SelectCategoriesFetchStatisticsStatus,
  SelectCategoriesState,
  SelectCategoriesStatistics,
  SelectCategoriesStatutes,
  SelectCategory,
  SelectCategoryById,
  SelectCategoryByPhoneNumber,
  SelectCategoryBySubscriptionId,
  SelectCategoryCountryIsoCodeById,
  SelectCategoryStatisticsByCategoryId,
} from '../../types';

export const selectCategoriesState: SelectCategoriesState = (state) => state.categories;

export const selectCategoriesIsLoading = createSelector([selectCategoriesState], (categories) => categories.isLoading);

export const selectCategoriesData = createSelector([selectCategoriesState], (categories) => categories.data);

export const selectCategoriesDataWithDynamicColor = createSelector([selectCategoriesData], (data) =>
  changeCategoriesColors(data),
);

export const selectCategories: SelectCategories = createSelector([selectCategoriesData], (data) => Object.values(data));

export const selectHasCategories = createSelector([selectCategories], (categories) => categories.length > 0);

export const selectCategoryById: SelectCategoryById = (categoryId?: Category['id']) =>
  createSelector([selectCategories], (categories) => categories.find(({ id }) => id === categoryId));

export const selectCategoryBySubscriptionId: SelectCategoryBySubscriptionId = (
  subscriptionId: Category['subscriptionId'],
) =>
  createSelector([selectCategories], (categories) =>
    categories.find((category) => category.subscriptionId === subscriptionId),
  );

export const selectCategoryCountryIsoCodeById: SelectCategoryCountryIsoCodeById = (categoryId?: Category['id']) =>
  createSelector([selectCategoryById(categoryId)], (category) => category?.virtualPhoneNumber?.countryIsoCode);

export const selectCategoriesWithDynamicColor: SelectCategories = createSelector(
  [selectCategoriesDataWithDynamicColor],
  (data) => Object.values(data),
);

export const selectCategoryWithDynamicColor: SelectCategory = (categoryId) =>
  createSelector([selectCategoriesDataWithDynamicColor], (data) => data[categoryId]);

export const selectCategoriesByVisibilityWithDynamicColor = createSelector(
  [selectCategoriesWithDynamicColor],
  (categories) => getCategoriesByVisibility(categories),
);

export const selectCategoriesByVisibilityAndExpiredFirst = createSelector([selectCategories], (categories) =>
  sortCategoriesByExpiredFirst(getCategoriesByVisibility(categories)),
);

export const selectCategoriIdsByVisibilityAndExpiredFirst = createSelector(
  [selectCategoriesByVisibilityAndExpiredFirst],
  (categories) => categories.map((category) => category.id),
);

export const selectCategoriesByActiveLandlineNumbers = createSelector(
  [selectCategoriesWithDynamicColor],
  (categories) => filterCategoriesByActiveLandlineNumbers(categories),
);

export const selectCategoriesByCanSendMessages = createSelector([selectCategoriesWithDynamicColor], (categories) =>
  filterCategoriesByCanSendMessages(categories),
);

export const selectCategoriesByCanMakeCalls = createSelector([selectCategoriesWithDynamicColor], (categories) =>
  filterCategoriesByCanMakeCalls(categories),
);

export const selectCategoryByPhoneNumber: SelectCategoryByPhoneNumber = (
  phoneNumber: Category['virtualPhoneNumber']['number'],
) =>
  createSelector([selectCategories], (categories) => ({
    categoryByPhoneNumber: categories.find(({ virtualPhoneNumber: { number } }) => number === phoneNumber),
  }));

export const selectCategoriesStatistics: SelectCategoriesStatistics = createSelector(
  [selectCategoriesState],
  (categoriesState) => categoriesState.statistics,
);

export const selectCategoryStatisticsByCategoryId: SelectCategoryStatisticsByCategoryId = (categoryId) =>
  createSelector([selectCategoriesStatistics], (statistics) =>
    statistics.find((item) => item.categoryId === categoryId),
  );

export const selectCategoriesStatutes: SelectCategoriesStatutes = createSelector(
  [selectCategoriesState],
  (categoriesState) => categoriesState.statuses,
);

export const selectCategoriesCancelSubscriptionStatus: SelectCategoriesCancelSubscriptionStatus = createSelector(
  [selectCategoriesStatutes],
  (categoriesStatuses) => categoriesStatuses.unsubscribeNumber,
);

export const selectCategoriesFetchStatisticsStatus: SelectCategoriesFetchStatisticsStatus = createSelector(
  [selectCategoriesStatutes],
  (categoriesStatuses) => categoriesStatuses.fetchStatistics,
);

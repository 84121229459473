import {
  CallLog,
  CallLogResponse,
  GetCallTimeConfigReturn,
  GetCallDurationAsFormattedProps,
  GetCallDurationAsFormattedReturn,
} from 'types';

export const addCallLogDefaultValue = (callLog: CallLogResponse): CallLog => {
  const {
    id = '',
    categoryId = '',
    secondPartyPhone = '',
    endedAt = '',
    startedAt = '',
    duration = 0,
    status = 'UNKNOWN',
    incoming = false,
    callRecording = {},
    isTransferredCall = false,
    transferorNumber = '',
    transfereeNumber = '',
    transferableNumber = '',
    callNote = '',
    callTags = [],
  } = callLog || {};

  return {
    id,
    categoryId,
    secondPartyPhone,
    endedAt,
    startedAt,
    duration,
    status,
    incoming,
    callRecording,
    isTransferredCall,
    transferorNumber,
    transfereeNumber,
    transferableNumber,
    callNote,
    callTags,
  };
};

export const isCallLogMissed = (callLog: CallLog): boolean => {
  const { incoming, status, duration } = callLog;
  const missedCallsStatuses: CallLog['status'][] = ['IVR', 'VMS'];

  return incoming === true && (duration === 0 || missedCallsStatuses.includes(status));
};

export const isCallLogFilteredByMissedCalls = ({
  callLog,
  isMissedCalls,
}: {
  callLog: CallLog;
  isMissedCalls: boolean;
}): boolean => {
  if (isMissedCalls === false) {
    return true;
  }

  return isCallLogMissed(callLog);
};

export const getCallTimeConfig = (duration: number): GetCallTimeConfigReturn => {
  const hours = duration > 0 ? Math.floor(duration / 3600) : 0;
  const minutes = duration > 0 ? Math.floor((duration - hours * 3600) / 60) : 0;
  const seconds = duration > 0 ? duration - hours * 3600 - minutes * 60 : 0;

  return {
    hours,
    minutes,
    seconds,
  };
};

export const getCallDurationAsFormatted = ({
  callDuration,
}: GetCallDurationAsFormattedProps): GetCallDurationAsFormattedReturn => {
  const { hours, minutes, seconds } = getCallTimeConfig(callDuration);
  const isSecondsVisible = seconds >= 1 || [hours, minutes, seconds].every((x) => x === 0);

  const hoursFormatted = hours > 0 ? `${Math.floor(hours)}h` : '';
  const minutesFormatted = minutes > 0 ? `${Math.floor(minutes)}min` : '';
  const secondsFormatted = isSecondsVisible ? `${Math.floor(seconds)}sec` : '';

  const callDurationAsFormatted = [hoursFormatted, minutesFormatted, secondsFormatted].join(' ').trim();
  return { callDurationAsFormatted };
};

export const findCallLogById = (callLogs: CallLog[], callLogId: string) =>
  callLogs.find((callLog) => callLog.id === callLogId);

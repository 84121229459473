/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApplicationLogicErrorResponse {
  code?: string;
  codes?: string[];
  description?: string;
  args?: string[];
  additionalInfo?: Record<string, object>;
}

export type EmptyResponse = object;

export interface EmailUnsubscribeRequest {
  token: string;
}

export interface Creator {
  categoryId: string;
}

export interface GetGroupThreadIdRequest {
  creator: Creator;
  receiver: GroupReceiver;
}

export interface GroupReceiver {
  phoneNumbers: string[];
}

export interface GetThreadIdResponse {
  threadId?: string;
  isOnoff?: boolean;
  participants?: MessageThreadParticipantData[];
  group?: boolean;
  name?: string;
  photoUrl?: string;
}

export interface MessageThreadParticipantData {
  id?: string;
  threadId?: string;
  userId?: string;
  categoryId?: string;
  phoneNumber?: string;
  serviceNumber?: boolean;
  group?: boolean;
  info?: ParticipantInfo;
}

export interface ParticipantInfo {
  name?: string;
  imageUrl?: string;
}

export interface SendMessageRequest {
  threadId: string;
  messageType: 'TEXT' | 'AUDIO' | 'VIDEO' | 'IMAGE' | 'INSTA_VIDEO';
  content: string;
  contentType?: string;
  originalFilename?: string;

  /** @format int64 */
  mediaLength?: number;

  /** @format int32 */
  delayInMinutes?: number;
}

export interface SendMessageResponse {
  contentUrl?: string;
  createdAt?: string;
  id?: string;
  nextAttemptAt?: string;
  targetParticipantId?: string;
  threadId?: string;
  videoThumbnail?: string;
  group?: boolean;
}

export interface StringCollectionRequest {
  ids: string[];
}

export interface GetThreadIdRequest {
  creator: Creator;
  receiver: Receiver;
}

export interface Receiver {
  phoneNumber?: string;
}

export interface CustomerSupportRequest {
  content: string;
  attachmentId?: string;
  osVersion?: string;
  appVersion?: string;
  mobile?: string;
  misc?: string;
}

export interface IncomingCallStatusRequestV2 {
  transactionId: string;
  incomingCallId?: string;
  callState:
    | 'RECEIVED'
    | 'ACCEPTED'
    | 'CANCELLED'
    | 'SHOWN'
    | 'SIP_INVITE_RECEIVED'
    | 'SIP_REGISTERED'
    | 'CALLING_CANCELLED_RECEIVED'
    | 'BUSY'
    | 'REGISTER_INITIATED'
    | 'REGISTER_SUCCESSFUL'
    | 'CUSTOM_ERROR'
    | 'CALL_QUALITY';
  ignoredBecauseLate?: boolean;
  pushChannel?: 'PUBNUB' | 'FIREBASE';
  connectionType?: string;
  customError?: string;

  /** @format float */
  callQuality?: number;
}

export interface CheckEmailRequest {
  email: string;
  locale: string;
  mobileToken?: string;
}

export interface CheckEmailResponse {
  emailAlreadyRegistered?: boolean;
  noPasswordSet?: boolean;
}

export interface UploadVoicemailGreetingRequest {
  categoryId: string;

  /** @format binary */
  voicemailGreetingFile: File;
}

export interface VoicemailUrlResponse {
  url?: string;
}

export interface UpdateTimeZoneRequest {
  timeZone: string;
}

export interface UpdateSimCardDetailsRequest {
  simCountryIsoCode?: string;
  roaming?: boolean;
  roamingCountryIsoCode?: string;
}

export interface UpdateSmsForCreditsRequest {
  categoryId: string;
  smsForCredits: boolean;
}

export interface UpdatePushTokenRequest {
  pushToken: string;
  pushTokenSpecial?: string;
}

export interface UpdatePubnubSettingsRequest {
  pubnubEnabled: boolean;
}

export interface UpdatePubnubSettingsResponse {
  pubnubEnabled?: boolean;
}

export interface UpdateOffersUnsubscribeRequest {
  offersUnsubscribe?: boolean;
}

export interface UpdateNextAppRateDateRequest {
  rateResultSuccessful: boolean;
}

export interface UpdateLocaleRequest {
  locale: string;
}

export interface UpdateDeviceLogsUploadStatusRequest {
  fileName?: string;
  success?: boolean;
}

export interface UpdateCallOutMethodRequest {
  callOutMethod: 'GSM' | 'VOIP';
}

export interface UpdateCallAnswerMethodRequest {
  callAnswerMethod: 'CALLBACK' | 'VOIP' | 'OFFLINE';
}

export enum ColorType {
  COLORTYPE1 = 'COLOR_TYPE_1',
  COLORTYPE2 = 'COLOR_TYPE_2',
  COLORTYPE3 = 'COLOR_TYPE_3',
  COLORTYPE4 = 'COLOR_TYPE_4',
  COLORTYPE5 = 'COLOR_TYPE_5',
  COLORTYPE6 = 'COLOR_TYPE_6',
  COLORTYPE7 = 'COLOR_TYPE_7',
  COLOR_TYPE_EXPIRED = 'COLOR_TYPE_EXPIRED',
  COLOR_TYPE_DISABLED = 'COLOR_TYPE_DISABLED',
}

export interface UpdateCategoryRequest {
  id: string;
  name: string;

  /** @pattern ^#[0-9A-Fa-f]{6}$ */
  color?: string;
  colorType?: ColorType;
}

export interface UpdateCategoryLocaleRequest {
  categoryId: string;
  categoryLocale?: string;
}

export interface CategoryCoreUpdateRequest {
  categoryId: string;
  name: string;

  /** @pattern ^#[0-9A-Fa-f]{6}$ */
  color?: string;
  colorType?: ColorType;
}

export interface UpdateCallQualitySurveyRequest {
  enabled: boolean;
}

export interface UpdateAppVersionRequest {
  appVersion: string;
}

export interface UpdateAdvertisingIdRequest {
  advertisingId: string;
}

export interface CategoriesVisibilitySwitchRequest {
  categoryIds: string[];
  visible: boolean;
}

export interface CategoryVisibilitySwitchRequest {
  categoryId: string;
  visible: boolean;
}

export interface CategoryStatusSwitchRequest {
  categoryId: string;
  enable?: boolean;
  callEnable?: boolean;
  messageEnable?: boolean;
  voicemailEnable?: boolean;
  clirEnable?: boolean;
}

export enum AppStore {
  INTERNAL_ACQUISITION = 'INTERNAL_ACQUISITION',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  BYTEL = 'BYTEL',
  B2B = 'B2B',
  CREDIT_CARD = 'CREDIT_CARD',
  TRANSATEL_PORT_IN = 'TRANSATEL_PORT_IN',
}

export interface CategoryDataImageV3 {
  usedAsUserSearchProfilePic?: boolean;
  imageUrl?: string;

  /**
   * @format int32
   * @min 0
   * @max 5
   */
  imageIndex: number;
}

export interface CategoryDataV3Impl {
  id?: string;
  created?: string;
  name?: string;
  color?: string;
  colorType?: ColorType;
  subscriptionId?: string;
  source?: AppStore;
  callEnabled?: boolean;
  voicemailEnabled?: boolean;
  messageEnabled?: boolean;
  clirEnabled?: boolean;
  callForwardingEnabled?: boolean;
  forwardMode?: 'ALL_CALLS' | 'MISSED_CALLS';
  active?: boolean;
  isVisible?: boolean;
  isPrivate?: boolean;
  isUsedForProfilePic?: boolean;

  /** @format int64 */
  callsBadge?: number;

  /** @format int64 */
  messagesBadge?: number;

  /** @format int64 */
  voicemailsBadge?: number;
  profileImageUrl?: string;
  privatePass?: string;
  categoryImages?: CategoryDataImageV3[];
  virtualPhoneNumber: VirtualPhoneNumberDataV3;
  scheduling?: CategorySchedulingInfo;
}

export interface CategorySchedulingInfo {
  enabled?: boolean;
  next?: CategorySchedulingNext;
}

export interface CategorySchedulingNext {
  /** @format date-time */
  at?: string;
  action?: 'ON' | 'OFF';
}

export interface VirtualPhoneNumberDataV3 {
  number?: string;
  expirationDate?: string;
  countryIsoCode?: string;
  state?: 'UNVALIDATED' | 'IN_PROGRESS' | 'NORMAL' | 'VALIDATED' | 'SIM' | 'TRIAL';
  planStatus?: 'SUBSCRIPTION_ACTIVE' | 'SUBSCRIPTION_CANCELLED' | 'PURCHASE_ACTIVE' | 'EXPIRED';
  voiceSupported?: boolean;
  smsSupported?: boolean;
  mmsSupported?: boolean;
  cancellationAllowed?: boolean;
}

export interface CategoryPrivacySwitchRequest {
  categoryId: string;
  switchOn: boolean;
  privatePassword?: string;
}

export interface SetCallForwardingRequest {
  categoryId: string;
  enabled: boolean;
  phoneNumber: string;
  forwardMode: 'ALL_CALLS' | 'MISSED_CALLS';
}

export interface SetCallForwardingResponse {
  name?: string;
  status?: 'SAVED' | 'NOT_COMPLETE';
}

export interface VoipSendFeedbackRequest {
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  callRating: number;
  problemIds?: number[];
}

export interface SaveSimpleContactImageRequest {
  contactId: string;

  /** @format binary */
  contactImage: File;
}

export interface SimpleContactImageResponse {
  imageUrl?: string;
}

export interface SaveCallNoteRequest {
  transactionId?: string;
  callLogId?: string;
  value: string;
  isCallInProgress?: boolean;
}

export interface RestoreDefaultVoicemailGreetingRequest {
  categoryId: string;
}

export interface PortInStartRequest {
  phoneNumber: string;
  portabilityCode: string;
  portDate: string;
}

export interface PortInStatusResponse {
  number?: string;
  portInStartDate?: string;
  portDate?: string;
  cancelLimitDate?: string;
  cancelAllowed?: boolean;
  status?: string;
}

export interface PortInCancelRequest {
  phoneNumber: string;
}

export interface PlansStatisticsRequest {
  categoryIds: string[];
}

export interface PlanStatisticsResponse {
  planStatistics?: PlansStatistics[];
}

export interface PlansStatistics {
  categoryId: string;

  /**
   * Can be expiration date or plan refill date
   * @format date-time
   */
  date: string;
  planStatus: 'SUBSCRIPTION_ACTIVE' | 'SUBSCRIPTION_CANCELLED' | 'PURCHASE_ACTIVE' | 'EXPIRED';
  unlimited?: boolean;

  /** @format int32 */
  calls?: number;

  /** @format int32 */
  remainingCalls?: number;

  /** @format int32 */
  sms?: number;

  /** @format int32 */
  remainingSms?: number;

  /** @format int32 */
  planDuration?: number;

  /** @format int32 */
  nextPlanDuration?: number;
}

export interface ActiveSubscriptionDetails {
  productId: string;
  transactionId: string;
  purchaseToken?: string;
}

export interface AndroidPurchaseDetails {
  productId: string;
  purchaseToken?: string;
}

export interface PlansAvailableRequest {
  virtualPhoneNumber: string;
  productId?: string;
  activeSubscriptions?: ActiveSubscriptionDetails[];
  purchaseHistory?: AndroidPurchaseDetails[];
  forceNormalProducts?: boolean;
  creditCardCountries?: string[];
}

export interface Data {
  name?: string;
  planType?:
    | 'SMALL'
    | 'MEDIUM'
    | 'MEDIUM_V2'
    | 'LARGE'
    | 'TRIAL'
    | 'PREMIUM'
    | 'BUSINESS_PLAN_1'
    | 'BUSINESS_PLAN_2'
    | 'BUSINESS_PLAN_3';
  unlimited?: boolean;

  /** @format int32 */
  calls?: number;

  /** @format int32 */
  sms?: number;

  /** @format int32 */
  premiumCalls?: number;

  /** @format int32 */
  premiumSms?: number;
  showNoTrial?: boolean;
  productIds?: ProductIdRO[];
}

export interface NumberUsageNote {
  headerText?: string;
  bodyText?: string;
  confirmationText?: string;
}

export interface PlanCondition {
  iconUrl?: string;
  title?: string;
  text?: string;
  link?: string;
}

export interface PlanInformation {
  title?: string;
  description?: string;
  iconUrl?: string;
}

export interface PlansAvailableResponseV2 {
  information?: PlanInformation[];
  conditions?: PlanCondition[];
  conditionComments?: string[];
  plans?: Data[];
  numberUsageNote?: NumberUsageNote;
}

export interface ProductIdRO {
  /** @format int32 */
  month?: number;
  productId?: string;
  autoRenewable?: boolean;
  hasTrialSupport?: boolean;
}

export interface PriceIncreasePromptFeedbackRequest {
  virtualPhoneNumber: string;
  userFeedback: 'SKIPPED' | 'PROCEEDED' | 'MUTED';
}

export interface PriceIncreaseAcceptedRequest {
  virtualPhoneNumber: string;
  receipt: ReceiptRequest;
}

export interface PriceInfo {
  currency?: string;
  products?: Product[];
}

export interface Product {
  appProductId?: string;
  price?: number;
}

export interface ReceiptRequest {
  deviceType: 'ANDROID' | 'IOS' | 'WEB' | 'CHROMEAPP' | 'MSTEAMS';
  body: string;
  appProductId: string;
  transactionId?: string;
  priceInfo?: PriceInfo;
}

export interface BuyPlanRequest {
  virtualPhoneNumber: string;
  categoryId?: string;
  category?: CreateCategoryRequestV3;
  planType:
    | 'SMALL'
    | 'MEDIUM'
    | 'MEDIUM_V2'
    | 'LARGE'
    | 'TRIAL'
    | 'PREMIUM'
    | 'BUSINESS_PLAN_1'
    | 'BUSINESS_PLAN_2'
    | 'BUSINESS_PLAN_3';
  receipt?: ReceiptRequest;
}

export interface CreateCategoryRequestV3 {
  name: string;

  /** @pattern ^#[0-9A-Fa-f]{6}$ */
  color?: string;
  colorType?: ColorType;
}

export interface BuyPlanRO {
  categoryId?: string;
  status?: 'SUBSCRIPTION_ACTIVE' | 'SUBSCRIPTION_CANCELLED' | 'PURCHASE_ACTIVE' | 'EXPIRED';
  national?: boolean;
  soldAsTrial?: boolean;
  virtualPhoneNumber?: VirtualPhoneNumberDataV3;
}

export interface PlanConditionRO {
  iconUrl?: string;
  text?: string;
  link?: string;
}

export interface PlanInformationRO {
  title?: string;
  description?: string;
  iconUrl?: string;
}

export interface PlansAvailableRO {
  information?: PlanInformationRO[];
  conditions?: PlanConditionRO[];
  conditionComments?: string[];
  plans?: Data[];
  numberUsageNote?: NumberUsageNote;
}

export interface PasswordRecoveryRequest {
  email: string;
  mobileToken?: string;
  initiatorApp?: 'WEB_SUBSCRIPTION';
}

export interface OutgoingCallStatusRequest {
  transactionId: string;
  callId?: string;
  callState?: 'SIP_INVITE_RECEIVED' | 'SIP_REGISTERED' | 'CALL_QUALITY' | 'CANCELLED' | 'CUSTOM_ERROR';

  /** @format float */
  callQuality?: number;
  customError?: string;
}

export interface OnOffCallUpdateRequest {
  enabled: boolean;
}

export interface SimNumberValidationPrepareCallRequest {
  /** @pattern ^([A-Z]){2}$ */
  userSimCountryCode: string;
}

export interface Error {
  message?: string;
  description?: string;
}

export interface RegisterValidationCallResponse {
  tsan?: string;

  /** @format int32 */
  timeout?: number;
  error?: Error;
}

export interface OnBoardingRegisterRequest {
  mobileToken: string;
  pushToken?: string;
  pushTokenSpecial?: string;
  locale: string;
  timeZone: string;
  advertisingId?: string;
  googleClientId?: string;
  email: string;
  virtualPhoneNumber?: string;
  simCountryIsoCodes: string[];
  receipt?: ReceiptRequest;
  deviceName?: string;
  deviceHardware?: string;
  deviceOSName?: string;
  marketingOptIn?: boolean;
  emailVerificationCode?: string;
  password?: string;
}

export interface OnBoardingPurchaseResult {
  national?: boolean;
  soldAsTrial?: boolean;
}

export interface OnBoardingRegisterResponse {
  id?: string;
  userDeviceId?: string;
  accessToken?: string;
  authKey?: string;
  callAnswerMethod?: string;
  purchaseResult?: OnBoardingPurchaseResult;
  forceDeviceLogout?: boolean;
  showEmergencyCallsNotAllowedMessage?: boolean;
}

export interface OnBoardingLoginRequest {
  mobileToken: string;
  pushToken?: string;
  pushTokenSpecial?: string;
  locale: string;
  timeZone: string;
  advertisingId?: string;
  googleClientId?: string;
  email: string;
  virtualPhoneNumber?: string;
  simCountryIsoCodes: string[];
  receipt?: ReceiptRequest;
  deviceName?: string;
  deviceHardware?: string;
  deviceOSName?: string;
  marketingOptIn?: boolean;
  emailVerificationCode?: string;
  password?: string;
}

export interface OnBoardingCheckReceiptIOSRequest {
  simCountryIsoCodes: string[];
  mobileToken: string;
  locale: string;
  timeZone: string;
  pendingTransactions?: TransactionDetails[];
  receiptBody?: string;
}

export interface TransactionDetails {
  productId: string;
  transactionId: string;
  originalTransactionId?: string;
}

export interface OnBoardingCheckReceiptResponse {
  oldFlow?: boolean;
  convertPendingToComplete?: string[];
  relatedUserEmails?: string[];
  onBoardingDetails?: OnBoardingDetails;
}

export interface OnBoardingDetails {
  interruptedPurchase?: boolean;
  purchaseDetails?: OnBoardingPurchaseDetails;
}

export interface OnBoardingPurchaseDetails {
  virtualPhoneNumber?: string;
  countryIsoCode?: string;
  productIds?: ProductIdRO[];
  info?: PurchaseInfo;
  showNoTrial?: boolean;
}

export interface PurchaseInfo {
  title?: string;
  description?: string;

  /** @format int32 */
  trialLength?: number;
  disclaimer?: string;
}

export interface OnBoardingCheckReceiptAndroidRequest {
  simCountryIsoCodes: string[];
  mobileToken: string;
  locale: string;
  timeZone: string;
  activeSubscriptions?: ActiveSubscriptionDetails[];
  purchaseHistory?: AndroidPurchaseDetails[];
  appStoreCurrency?: string;
}

export interface SendValidationCodeRequest {
  phoneNumber: string;
}

export interface SendValidationCodeResponseV3 {
  /** @format int64 */
  secondsTillNextAttempt?: number;
  actuallySentSms?: boolean;
}

export interface CheckValidationCodeRequest {
  phoneNumber: string;
  code: string;
}

export interface KycPersonalDocumentRequest {
  categoryId: string;
  countryIsoCode?: string;
  documentNumber: string;
  documentFrontFileId?: string;
  documentFrontOriginalFilename?: string;
  documentBackFileId?: string;
  documentBackOriginalFilename?: string;
  documentType:
    | 'PASSPORT'
    | 'ID_CARD'
    | 'DRIVERS_LICENSE'
    | 'RESIDENCE_PERMIT'
    | 'VISA'
    | 'INVOICE'
    | 'UTILITY_BILL'
    | 'TAX_STATEMENT'
    | 'BANK_STATEMENT'
    | 'OTHER';
}

export interface KycPersonalAddressResponse {
  status?: string;
  nextStep?:
    | 'SIM_NUMBER_VALIDATION'
    | 'PERSONAL_DETAILS'
    | 'ADDRESS_VALIDATION'
    | 'GEO_VALIDATION_OF_ADDRESS'
    | 'DOCUMENT_VALIDATION'
    | 'MANUAL_VALIDATION'
    | 'PROOF_OF_ADDRESS'
    | 'PROOF_OF_ADDRESS_WAITING_DECISION'
    | 'KYB_VALIDATION_REQUIRED'
    | 'FINISH_STEP';
}

export interface CountryInfo {
  countryIsoCode?: string;
  countryName?: string;
}

export interface DocumentTypeField {
  value?: string;
  text?: string;
}

export interface InputField {
  fieldLabel?: string;
  fieldType?: 'TEXT' | 'ISO_DATE' | 'COUNTRY_SELECTOR' | 'CHECKBOX' | 'HIDDEN' | 'IMAGE_URL' | 'SELECTOR';
  fieldName?:
    | 'firstName'
    | 'lastName'
    | 'birthDate'
    | 'birthCountryIsoCode'
    | 'birthCity'
    | 'countryIsoCode'
    | 'city'
    | 'province'
    | 'postcode'
    | 'confirmed'
    | 'address_line'
    | 'documentType'
    | 'documentNumber'
    | 'documentFrontUploadUrl'
    | 'documentBackUploadUrl'
    | 'documentFrontFileId'
    | 'documentBackFileId'
    | 'documentFrontOriginalFilename'
    | 'documentBackOriginalFilename';
  fieldValue?: string;
  optional?: boolean;
  editable?: boolean;
}

export interface KycPersonalDocumentInfoResponse {
  infoMessage?: string;
  documentTypeSelectorData?: DocumentTypeField[];
  countrySelectorData?: CountryInfo[];
  requiredPersonalDocumentDetails?: InputField[];
}

export interface PersonalDetailsSaveRequest {
  categoryId: string;
  firstName: string;
  lastName: string;

  /** @format date-time */
  birthDate?: string;

  /** @pattern ^([A-Z]){2}$ */
  birthCountryIsoCode?: string;
  birthCity?: string;
}

export interface KycPersonalDetailsSaveResponse {
  status?: string;
  nextStep?:
    | 'SIM_NUMBER_VALIDATION'
    | 'PERSONAL_DETAILS'
    | 'ADDRESS_VALIDATION'
    | 'GEO_VALIDATION_OF_ADDRESS'
    | 'DOCUMENT_VALIDATION'
    | 'MANUAL_VALIDATION'
    | 'PROOF_OF_ADDRESS'
    | 'PROOF_OF_ADDRESS_WAITING_DECISION'
    | 'KYB_VALIDATION_REQUIRED'
    | 'FINISH_STEP';
}

export interface KycPersonalAddressRequest {
  categoryId: string;
  countryIsoCode: string;
  city: string;
  province?: string;
  address_line: string;
  postcode: string;
  confirmed?: string;
}

export interface AddressAutocompleteRequest {
  countryIsoCode: string;
  cityCode?: string;
  postCode?: string;
  cityName?: string;
  query: string;

  /**
   * @format int32
   * @min 3
   * @max 20
   */
  limit: number;
}

export interface AddressAutocompleteResponse {
  autocompleteList?: AutocompleteAddressElement[];
}

export interface AutocompleteAddressElement {
  name?: string;
  cityCode?: string;
  postCode?: string;

  /** @format int32 */
  score?: number;
  cityName?: string;
  context?: string;
}

export interface InitiateCallTransferRequest {
  calleeNumber: string;
  categoryId: string;
  isWarmTransfer?: boolean;
}

export interface InitiateCallTransferResponse {
  transferCallId?: string;
}

export interface DeviceDataRequest {
  hardware?: string;
  osName?: string;
  name?: string;
}

export interface DeleteVoicemailsRequest {
  voicemailIds: string[];
}

export interface DeleteVoicemailRequest {
  voicemailId: string;
}

export interface DeleteCategoryRequest {
  categoryId: string;
}

export interface DeleteCallLogsRequest {
  categoryIds: string[];
}

export interface DeleteCallLogRequest {
  id: string;
}

export interface HomePhoneBookRequest {
  homePhoneBookDeviceToken: string;
  homePhoneBookName: string;
}

export interface ChooseNewPasswordRequest {
  firstPwd: string;
  secondPwd: string;
  token: string;
}

export interface ChooseNewPasswordResponse {
  status?: string;
}

export interface CheckPasswordRequest {
  password: string;
}

export interface CheckPasswordResponse {
  result?: boolean;
}

export interface CheckGoogleTransactionsRequest {
  purchaseTokens: string[];
}

export interface CheckGoogleTransactionsResponse {
  usedPurchaseTokens?: string[];
}

export interface CheckAppleTransactionsRequest {
  transactions: TransactionDetails[];
}

export interface CheckAppleTransactionsResponse {
  usedTransactionIds?: string[];
  pendingTransactionIds?: string[];
}

export interface ChangeUserEmailRequest {
  email: string;
  emailVerificationCode?: string;
}

export interface ChangePrivatePassRequest {
  categoryId: string;
  privatePassword: string;
}

export interface HomePhoneBookSortOrderRequest {
  sortOrder: 'FirstNameFirst' | 'LastNameFirst';
}

export interface HomePhoneBookDisplayOrderRequest {
  displayOrder: 'FirstNameFirst' | 'LastNameFirst';
}

export interface ChangePasswordRequest {
  oldPassword?: string;
  newPassword: string;
}

export interface ChangeUserNameRequest {
  firstName?: string;
  lastName?: string;
}

export interface CaptchaVerifyRegisterTokenRequest {
  recaptchaToken: string;

  /** If present then provided token will be registered as valid for some time to perform next important callouts */
  mobileToken?: string;
}

export interface CaptchaVerifyRegisterTokenResponse {
  /** Token to be used in important callouts to rate or time-limit requests */
  mobileToken?: string;
}

export interface CaptchaStatusRequest {
  /** Mobile token of device. In some cases when 'validation' is disabled, this token will be registered as valid for some time to perform next calls */
  mobileToken?: string;
}

export interface CaptchaStatusResponse {
  captchaRequired?: boolean;
}

export interface WarmTransferCompleteRequest {
  transferCallId: string;
}

export interface WarmTransferCancelRequest {
  transferCallId: string;
}

export interface CancelCallRecordingRequest {
  transactionId?: string;
}

export interface B2BCallTagAssignRequest {
  callLogId: string;
  tagIds: string[];
}

export interface CallQualitySurveySubmitRequest {
  transactionId: string;

  /** @format int32 */
  rating: number;
  reasons?: string[];
  otherReason?: string;
  attachmentId?: string;
  wifi?: boolean;
  roaming?: boolean;
  linphoneVersion?: string;
  jitter?: number;

  /** @format int32 */
  packetLoss?: number;

  /** @format int32 */
  averageQuality?: number;

  /** @format int32 */
  latePacketsCumulativeNumber?: number;
  localLateRate?: number;
  localLossRate?: number;
  receiverInterarrivalJitter?: number;
  receiverLossRate?: number;
  roundTripDelay?: number;
  senderInterarrivalJitter?: number;
  senderLossRate?: number;
}

export interface BlockNumberRequest {
  phoneNumber: string;
  blocked: boolean;
  markAsSpam?: boolean;
}

export interface CancelUserOfferSubscriptionRequest {
  subscriptionId: string;
}

export interface UserOfferBuyRequest {
  receipt: ReceiptRequest;
}

export interface OnBoardingPlansAvailableRequest {
  virtualPhoneNumber: string;
  productId?: string;
  activeSubscriptions?: ActiveSubscriptionDetails[];
  purchaseHistory?: AndroidPurchaseDetails[];
  forceNormalProducts?: boolean;
  creditCardCountries?: string[];
  locale: string;
  simCountryIsoCodes: string[];
  mobileToken: string;
  appStoreCurrency?: string;
}

export interface OnBoardingListNumbersForCountryRequest {
  locale: string;
  simCountryIsoCodes: string[];
  mobileToken: string;
  selectedCountryIsoCode?: string;
  numberToSearch?: string;
  vip?: boolean;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  offset: number;

  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit: number;
}

export interface ListNumbersRO {
  portingAvailable?: boolean;
  numbers?: NumberRO[];
  cheapestProductId?: string;
}

export interface NumberRO {
  virtualPhoneNumber?: string;
  formattedVirtualPhoneNumber?: string;
  countryIsoCode?: string;
  vip?: boolean;
}

export interface OnBoardingListCountriesRequest {
  locale: string;
  simCountryIsoCodes: string[];
  mobileToken: string;
  appStoreCurrency?: string;
}

export enum NumberFunctionality {
  FULL = 'FULL',
  FULL_MMS = 'FULL_MMS',
  VOICE_ALLOWED = 'VOICE_ALLOWED',
  SMS_ALLOWED = 'SMS_ALLOWED',
  MMS_ALLOWED = 'MMS_ALLOWED',
}

export interface OnBoardingCountryCodeRO {
  code: string;
  phoneCode: string;
  name: string;
  supportedFunctionality?: NumberFunctionality;
  vipAvailable?: boolean;

  /** @format int32 */
  months?: number;
  productId?: string;
}

export interface OnBoardingListCountriesResponse {
  availableCountries?: OnBoardingCountryCodeRO[];
  unavailableCountries?: OnBoardingCountryCodeRO[];
  popularCountries?: OnBoardingCountryCodeRO[];
}

export interface EmailValidationVerifyCodeRequest {
  emailVerificationCode: string;
}

export interface EmailValidationSendCodeAuthenticatedRequest {
  /** If missing then existing user email will be used */
  email?: string;
}

export interface EmailValidationSendCodeResponse {
  /** @format date-time */
  newCodeCanBeSentAt?: string;
}

export interface AddCategorySchedulingRequestV2 {
  categoryId: string;
  enabled: boolean;
  type?: CategorySchedulingType;
  schedule?: CategoryScheduleV2[];
}

export interface CategoryScheduleV2 {
  timeRanges: TimeRange[];

  /**
   *
   *          Might be null, depends on the type in the request.
   *          If type is CUSTOM then day should be present,
   *          if type is EVERYDAY or WORKDAY then ignore this field;
   */
  day?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
}

export enum CategorySchedulingType {
  EVERYDAY = 'EVERYDAY',
  WORKDAY = 'WORKDAY',
  CUSTOM = 'CUSTOM',
}

export interface TimeRange {
  from: string;
  until: string;
  enabled?: boolean;
}

export interface CategorySchedulingResponseV2 {
  categoryId?: string;
  enabled?: boolean;
  type?: CategorySchedulingType;
  next?: CategorySchedulingNext;
  schedule?: CategoryScheduleV2[];
}

export interface AddCategorySchedulingRequest {
  categoryId: string;
  enabled: boolean;
  type?: CategorySchedulingType;
  schedule?: CategorySchedule[];
}

export interface CategorySchedule {
  enabled: boolean;
  from: string;
  until: string;

  /**
   *
   *          Might be null, depends on the type in the request.
   *          If type is CUSTOM then day should be present,
   *          if type is EVERYDAY or WORKDAY then ignore this field;
   */
  day?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
}

export interface CategorySchedulingResponse {
  categoryId?: string;
  enabled?: boolean;
  type?: CategorySchedulingType;
  next?: CategorySchedulingNext;
  schedule?: CategorySchedule[];
}

export interface CancelSubscriptionRequest {
  categoryId: string;
}

export interface AppleResolveAccountRequest {
  receiptBody?: string;
}

export interface OnBoardingPlansAvailableRequestV2 {
  virtualPhoneNumber: string;
  productId?: string;
  activeSubscriptions?: ActiveSubscriptionDetails[];
  purchaseHistory?: AndroidPurchaseDetails[];
  forceNormalProducts?: boolean;
  creditCardCountries?: string[];
  locale: string;
  mobileToken: string;
  simCountryIsoCodes: string[];
  appStoreCurrency?: string;
}

export interface EmailValidationSendCodeRequest {
  email: string;

  /** Previously verified token to allow email sending */
  mobileToken: string;
  locale: string;
}

export interface AddCreditsRequest {
  receipt: ReceiptRequest;
}

export interface VpnUpdateProviderRequest {
  phoneNumber: string;
  phoneNumberProvider?: string;
}

export interface VpnUnregisterRequest {
  phoneNumber: string;
}

export interface VpnReleasePortInRequest {
  phoneNumber: string;
}

export interface VpnRegisterRequest {
  phoneNumber: string;
  referenceId?: string;

  /** @format date-time */
  timeToChange: string;
}

export interface VpnAssistPortInRequest {
  countryIsoCode: string;
  phoneNumberProvider: string;
}

export interface VpnAssistPortInResponse {
  phoneNumber?: string;
}

export interface UpdateUserEmailCorrectnessRequest {
  email: string;
  state: boolean;
}

export interface SimpleValueResponseBoolean {
  value?: boolean;
}

export interface UsersForClosingRequest {
  /** @format date-time */
  nextDate?: string;
}

export interface UsersForClosingResponse {
  /** @format date-time */
  nextDate?: string;
  isFinished?: boolean;
}

export interface ProcessIncomingCallRequest {
  caller: string;
  callee: string;
  transactionId: string;
  callId: string;
  clir: boolean;
}

export interface BillingInfo {
  id?: string;
  callType?:
    | 'VOICE_MO'
    | 'VOICE_MO_PREMIUM'
    | 'VOICE_MT'
    | 'VOICE_MT_OFFLINE'
    | 'SMS_MO'
    | 'SMS_MT'
    | 'SMS_MO_1'
    | 'SMS_MO_2'
    | 'UNKNOWN';
  shouldHaveTariffCode?: boolean;
  tariffCode?: string;
}

export interface CallActionResponse {
  action?: 'CALLBACK' | 'VMS' | 'BUSY' | 'IVR' | 'CONNECT' | 'FORWARD' | 'CALL' | 'DROP';
  fallback?: 'CALLBACK' | 'VMS' | 'BUSY' | 'IVR' | 'CONNECT' | 'FORWARD' | 'CALL' | 'DROP';
  forwardNumber?: string;
  file?: string;

  /** @format int32 */
  timeout?: number;
}

export interface CallRecordingResponse {
  bucket?: string;
  file?: string;
}

export interface IncomingCallUser {
  id?: string;
  simCountryIsoCode?: string;
  vpnCountryIsoCode?: string;
  callAnswerMethod?: 'CALLBACK' | 'VOIP' | 'OFFLINE';
  promptId?: string;
}

export interface ProcessIncomingCallResponse {
  transactionId?: string;
  voipInfrastructure?: 'ONOFF' | 'GREETINGS' | 'MICROSOFT';
  user?: IncomingCallUser;
  allocatedTsan?: string;

  /** @format int32 */
  sipProvisionalResponse?: number;
  callAction?: CallActionResponse;
  callRecording?: CallRecordingResponse;
  lawfulInterceptRecording?: CallRecordingResponse;
  error?: Error;
  billingInfo?: BillingInfo;
}

export interface AnonymousCallCallbackRequest {
  transactionId?: string;
  callId?: string;
  error?: string;
}

export interface PrepareOfflineOutgoingCallRequest {
  callId?: string;
  transactionId?: string;
  caller?: string;
  tsanNumber?: string;
  tsanCountryIsoCode?: string;
}

export interface CalleeResponse {
  number?: string;
  isOnOff?: boolean;
  voipInfrastructure?: 'ONOFF' | 'GREETINGS' | 'MICROSOFT';
  simCountryIsoCode?: string;
  vpnCountryIsoCode?: string;
  callAnswerMethod?: 'CALLBACK' | 'VOIP' | 'OFFLINE';
}

export interface CallerResponse {
  number?: string;
  locale?: string;
  billing?: BillingInfo;
  callRecording?: CallRecordingResponse;
  lawfulInterceptionRecording?: CallRecordingResponse;
}

export interface ErrorResponse {
  message?: string;
}

export interface PrepareOfflineOutgoingCallResponse {
  transactionId?: string;
  caller?: CallerResponse;
  callee?: CalleeResponse;
  error?: ErrorResponse;
  callAction?: CallActionResponse;
}

export interface IncomingCallTelecomRequest {
  MT_RegisterCALLArgs: Payload;
}

export interface Payload {
  callId?: string;
  TransactionId: string;
  Leg1_CallingParty?: string;
  Leg1_CalledParty: string;
  Leg1_CLIR: boolean;

  /** @pattern ^([0-9]){3,30}$ */
  TSAN: string;
}

export interface IncomingCallTelecomResponse {
  MT_RegisterCALLResponse?: Payload;
}

export interface GetCalleeInformationRequest {
  caller: string;
  callee: string;
}

export interface GetCalleeInformationResponse {
  voipInfrastructure?: 'ONOFF' | 'GREETINGS' | 'MICROSOFT';
  user?: IncomingCallUser;
  error?: Error;
}

export interface IncomingCallCancelTelecomRequest {
  MT_CancelCALLArgs: Payload;
}

export interface IncomingCallCancelTelecomResponse {
  MT_CancelCALLResponse?: Payload;
}

export interface IdListRequest {
  ids: string[];
}

export interface IdListResponse {
  ids?: string[];
}

export interface CustomerWithDiscount {
  userId?: string;
  categoryId?: string;
  phoneNumber?: string;
  email?: string;

  /** @format double */
  discount?: number;
  message?: string;

  /** @format date-time */
  datePartition?: string;
}

export interface SendCustomerDiscountSmsRequest {
  customersWithDiscount: CustomerWithDiscount[];
}

export interface StatusOkResponse {
  status?: string;
}

export interface GetCustomersWithDiscountRequest {
  /**
   * @format int32
   * @min 0
   * @max 20
   */
  counter: number;
}

export interface GetCustomersForDiscountResponse {
  customerInfos?: CustomerWithDiscount[];
}

export interface MarkGracePeriodOverProcessedRequest {
  /** @format date-time */
  currentDate?: string;
  countries?: string[];
}

export interface ListRequestString {
  items?: string[];

  /**
   * @format int32
   * @max 10000
   */
  limit?: number;
}

export interface GetAutoRenewableSubscriptionsRequest {
  /** @format date-time */
  expirationDateStart: string;

  /** @format date-time */
  expirationDateEnd: string;
  lastId?: string;

  /**
   * @format int32
   * @min 1
   * @max 500
   */
  limit: number;
}

export interface GetAutoRenewableSubscriptionsResponse {
  subscriptions?: Subscription[];
}

export interface Subscription {
  id?: string;
  userId?: string;
  categoryId?: string;
  subscriptionId?: string;
  status?: SubscriptionStatus;
  appStore?: AppStore;
  userBlocked?: boolean;
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED',
}

export interface CancelAutoRenewableSubscriptionRequest {
  subscriptionId: string;
  userNoteText?: string;
}

export interface B2BAutorenewableSubscriptionRenewRequest {
  subscriptionId: string;
}

export interface SmppMessageRequest {
  id: string;

  /** @format date-time */
  created: string;
  phoneNumber: string;
  secondPartyPhoneNumber: string;
  body: string;

  /** @format int32 */
  partsNumber?: number;
}

export interface IncomingSmppMessageResponse {
  targetNumberIsActiveOnoffNumber?: boolean;
}

export interface SmppDeliveredRequest {
  messageId: string;

  /** @format date-time */
  deliveredAt: string;
}

export interface AppleSubscriptionInfoV2Request {
  payload?: string;
}

export interface AppleEventPayload {
  sandbox?: boolean;
  notification_type?:
    | 'CONSUMPTION_REQUEST'
    | 'CANCEL'
    | 'DID_CHANGE_RENEWAL_PREF'
    | 'DID_CHANGE_RENEWAL_STATUS'
    | 'DID_FAIL_TO_RENEW'
    | 'DID_RECOVER'
    | 'RENEWAL'
    | 'ONOFF_RENEWAL'
    | 'DID_RENEW'
    | 'INITIAL_BUY'
    | 'INTERACTIVE_RENEWAL'
    | 'PRICE_INCREASE_CONSENT'
    | 'REVOKE'
    | 'REFUND';
  password?: string;
  auto_renew_status?: boolean;
  latest_receipt?: string;
  latest_receipt_info?: LegacyReceipt;
  latest_expired_receipt?: string;
  latest_expired_receipt_info?: LegacyReceipt;
  unified_receipt?: EventUnifiedReceipt;
  auto_renew_product_id?: string;
  environment?: string;
}

export interface AppleSubscriptionInfoRequest {
  payload?: AppleEventPayload;
}

export interface EventLatestReceiptInfo {
  /** @format int64 */
  cancellation_date_ms?: number;
  cancellation_reason?: string;

  /** @format int64 */
  expires_date_ms?: number;
  is_in_intro_offer_period?: string;
  is_trial_period?: string;
  is_upgraded?: string;
  original_transaction_id?: string;
  product_id?: string;

  /** @format int64 */
  purchase_date_ms?: number;
  quantity?: string;
  transaction_id?: string;
  web_order_line_item_id?: string;
}

export interface EventPendingRenewalInfo {
  auto_renew_product_id?: string;
  auto_renew_status?: string;
  expiration_intent?: string;

  /** @format int64 */
  grace_period_expires_date_ms?: number;
  is_in_billing_retry_period?: string;
  original_transaction_id?: string;
  price_consent_status?: string;
  product_id?: string;
  web_order_line_item_id?: string;
}

export interface EventUnifiedReceipt {
  environment?: string;
  latest_receipt?: string;
  latest_receipt_info?: EventLatestReceiptInfo[];
  pending_renewal_info?: EventPendingRenewalInfo[];

  /** @format int32 */
  status?: number;
}

export interface LegacyReceipt {
  product_id?: string;
  transaction_id?: string;
  original_transaction_id?: string;

  /** @format int64 */
  expires_date?: number;

  /** @format int64 */
  cancellation_date_ms?: number;
  web_order_line_item_id?: string;
  cancellation_reason?: string;
}

export interface AppleSubscriptionInfoResponse {
  replayInProduction?: boolean;
}

export interface AndroidSubscriptionInfoRequest {
  payload?: Payload;
}

export interface GooglePlayRefundRequest {
  orderIds: string[];
}

export interface PrevalidatePurchaseRequestV2 {
  userId: string;
  virtualPhoneNumber: string;
  productId: string;
  creditCardCountryIsoCodeList: string[];
}

export interface PrevalidateUserOfferPurchaseRequest {
  userId: string;
  productId: string;
  trial: boolean;
}

export interface EnableUserOfferRequest {
  userId: string;
  productId: string;
  purchaseId: string;
  invoiceNumber: string;

  /** @format date-time */
  paymentMadeAt: string;
  price: number;
  currencyCode: string;
  userRemoteAddress: string;
  trial: boolean;
}

export interface RenewSubscriptionRequest {
  subscriptionId: string;
  productId: string;
  invoiceNumber: string;
  price: number;
  currencyCode: string;
}

export interface RefundRequest {
  invoiceNumber: string;
  originalPurchaseId: string;
  price?: number;
  refundAmount?: number;
  currencyCode?: string;
}

export interface PrevalidatePurchaseForExistingCategoryRequest {
  userId: string;
  virtualPhoneNumber: string;
  categoryId: string;
  productId: string;
  creditCardCountries?: string[];
}

export interface GetProductsDurations {
  productIds: string[];
}

export interface GetProductsDurationsResponse {
  products?: Product[];
}

export interface CreateCategoryAssignPlanRequest {
  userId: string;
  virtualPhoneNumber: string;
  productId: string;
  purchaseId: string;
  invoiceNumber: string;

  /** @format date-time */
  paymentMadeAt: string;
  price: number;
  currencyCode: string;
  userRemoteAddress: string;
}

export interface CreateCategoryAssignPlanResponse {
  categoryId?: string;
}

export interface AssignPlanForExistingCategoryRequest {
  userId: string;
  categoryId: string;
  virtualPhoneNumber: string;
  productId: string;
  purchaseId: string;
  invoiceNumber: string;

  /** @format date-time */
  paymentMadeAt: string;
  price: number;
  currencyCode: string;
  userRemoteAddress: string;
}

export interface PortOutStatusUpdateRequest {
  phoneNumber: string;
  transactionId: string;
  newState:
    | 'PORT_IN_PREPARE'
    | 'PORT_IN_REQUESTED'
    | 'PORT_IN_PROGRESS'
    | 'PORT_IN_CANCELLING'
    | 'PORT_IN_CANCELLED'
    | 'PORT_IN_REJECTED'
    | 'PORT_IN_COMPLETE'
    | 'PORT_OUT_RIO'
    | 'PORT_OUT_ELIGIBILITY'
    | 'PORT_OUT_REJECTED'
    | 'PORT_OUT_CANCELLED'
    | 'PORT_OUT_COMPLETE';
}

export interface PortOutPortabilityCodeRequest {
  phoneNumber: string;
  portabilityCode: string;
}

export interface PortOutCheckEligibilityRequest {
  phoneNumber: string;
}

export interface PortOutCheckEligibilityResponse {
  valid?: boolean;
}

export interface PortInStatusUpdateRequest {
  referenceId?: string;
  phoneNumber: string;
  transactionId: string;
  newState:
    | 'PORT_IN_PREPARE'
    | 'PORT_IN_REQUESTED'
    | 'PORT_IN_PROGRESS'
    | 'PORT_IN_CANCELLING'
    | 'PORT_IN_CANCELLED'
    | 'PORT_IN_REJECTED'
    | 'PORT_IN_COMPLETE'
    | 'PORT_OUT_RIO'
    | 'PORT_OUT_ELIGIBILITY'
    | 'PORT_OUT_REJECTED'
    | 'PORT_OUT_CANCELLED'
    | 'PORT_OUT_COMPLETE';

  /** @format date-time */
  portDate?: string;
}

export interface VoiceTrafficHistoryRequest {
  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  requestedNumber: string;
  offsetId?: string;

  /**
   * @format int32
   * @min 0
   * @max 10000
   */
  limit: number;
}

export interface VoiceTrafficDetailsDto {
  id?: string;
  firstPartyNumber?: string;
  secondPartyNumber?: string;
  transferNumber?: string;
  callStatus?:
    | 'START'
    | 'ANSWER'
    | 'FALLBACK'
    | 'ABORT'
    | 'ERROR'
    | 'END'
    | 'BUSY'
    | 'UNKNOWN_SUBSCRIBER'
    | 'BROKEN_LOG'
    | 'IVR'
    | 'VMS'
    | 'VALIDATION'
    | 'FORWARD'
    | 'UNKNOWN'
    | 'DROPPED_VERIFICATION';
  incoming?: boolean;

  /** @format date-time */
  startedAt?: string;

  /** @format date-time */
  endedAt?: string;

  /** @format int64 */
  duration?: number;
}

export interface VoiceTrafficHistoryResponse {
  voiceTrafficDetails?: VoiceTrafficDetailsDto[];
}

export interface TrafficHistoryRequest {
  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  requestedNumber: string;
}

export interface MessageTrafficDetailsDto {
  firstPartyNumber?: string;
  secondPartyNumber?: string;
  contentType?: 'TEXT' | 'AUDIO' | 'VIDEO' | 'IMAGE' | 'INSTA_VIDEO';
  messageType?: 'SMS' | 'MMS' | 'INTERNAL';

  /** @format int64 */
  mediaLength?: number;
  incoming?: boolean;

  /** @format date-time */
  created?: string;
}

export interface TrafficHistoryResponse {
  voiceTrafficDetails?: VoiceTrafficDetailsDto[];
  messageTrafficDetails?: MessageTrafficDetailsDto[];
}

export interface SendEmailRequest {
  endUserCommunication:
    | 'MISSED_FOR_EXPIRED_CATEGORY'
    | 'KYC_REQUIRED_INCOMING_COMMUNICATION'
    | 'DOCUMENT_VALIDATION_SUCCESS'
    | 'KYC_DOCUMENT_REJECTED_POTENTIAL_SCAMMER'
    | 'USER_CLOSE_INACTIVE'
    | 'USER_BLOCKED_TOO_MANY_INVALID_LOGIN_ATTEMPTS'
    | 'USER_REGISTRATION_WITHOUT_TRIAL'
    | 'USER_REGISTRATION_GREETINGS'
    | 'USER_REGISTRATION_BOUYGUES'
    | 'CIRCULATION_NUMBER'
    | 'CATEGORY_REMOVED_BY_USER'
    | 'NORMAL_PAID_RENEWAL'
    | 'NORMAL_PAID_PURCHASE'
    | 'NUMBER_PURCHASE'
    | 'NUMBER_PURCHASE_NO_SMS'
    | 'RIO_CODE_RETRIEVAL'
    | 'NUMBER_PORT_OUT_SUCCESS'
    | 'NUMBER_PORT_OUT_WARNING'
    | 'NUMBER_PORT_OUT_REJECTED'
    | 'NUMBER_PORT_IN_PENDING'
    | 'NUMBER_PORT_IN_IOS_INSTRUCTIONS'
    | 'NUMBER_PORT_IN_CANCELLED_GENERIC'
    | 'NUMBER_PORT_IN_CANCELLED_BY_USER'
    | 'NUMBER_PORT_IN_CANCELLED_NO_CONFIRMATION'
    | 'NUMBER_PORT_IN_REJECTED'
    | 'NUMBER_PORT_IN_CONFIRMATION_SMS'
    | 'NUMBER_PORT_OUT_UK_PORTABILITY_CODE_REQUESTED'
    | 'NUMBER_PORT_OUT_UK_PORTABILITY_CODE'
    | 'NUMBER_PORT_OUT_UK_PORTABILITY_FORMAT_INVALID'
    | 'CHANGE_USER_EMAIL_TO_OLD_ADDRESS'
    | 'CHANGE_USER_EMAIL_TO_NEW_ADDRESS'
    | 'PASSWORD_RECOVERY'
    | 'B2B_PASSWORD_RECOVERY'
    | 'PASSWORD_RECOVERY_CONFIRMATION'
    | 'PASSWORD_CHANGE_IN_APP_CONFIRMATION'
    | 'ACCOUNT_DELETION'
    | 'CUSTOM_MESSAGE'
    | 'CUSTOM_MESSAGE_V2'
    | 'SUBSCRIPTION_AUTOMATIC_CANCELLATION'
    | 'SUBSCRIPTION_MANUAL_CANCELLATION'
    | 'SUBSCRIPTION_ERROR'
    | 'SUBSCRIPTION_PAYMENT_ERROR'
    | 'SUBSCRIPTION_PAYMENT_ERROR_IOS'
    | 'SUBSCRIPTION_TRANSFER'
    | 'BLOCKED_INTERNATIONAL_CALL_TO_DEMO_NUMBER'
    | 'USER_EXPIRY_CREDITS_ONE_MONTH_EMAIL'
    | 'USER_EXPIRY_CREDITS_ONE_WEEK_EMAIL'
    | 'USER_EXPIRY_CREDITS_ONE_DAY_EMAIL'
    | 'USER_OUT_OF_BUNDLE_SMS'
    | 'USER_OUT_OF_BUNDLE_SMS_WITH_REDEEM'
    | 'USER_OUT_OF_BUNDLE_CALL'
    | 'USER_OUT_OF_BUNDLE_CALL_WITH_REDEEM'
    | 'USER_OUT_OF_IN_ONOFF_BUNDLE_CALL'
    | 'USER_OUT_OF_IN_ONOFF_BUNDLE_CALL_WITH_REDEEM'
    | 'USER_LACK_OF_BUNDLE_SMS'
    | 'USER_LACK_OF_BUNDLE_SMS_WITH_REDEEM'
    | 'USER_LACK_OF_BUNDLE_CALL'
    | 'USER_LACK_OF_BUNDLE_CALL_WITH_REDEEM'
    | 'USER_LACK_OF_IN_ONOFF_BUNDLE_CALL'
    | 'USER_LACK_OF_IN_ONOFF_BUNDLE_CALL_WITH_REDEEM'
    | 'SUPPORT_USER_AUTOMATIC_REPLY'
    | 'USER_EXPIRY_CREDITS_ONE_MONTH'
    | 'USER_EXPIRY_CREDITS_ONE_WEEK'
    | 'USER_EXPIRY_CREDITS_ONE_DAY'
    | 'B2B_USER_ONBOARDING'
    | 'B2B_USER_RESET_PASSWORD'
    | 'B2B_MIGRATE_TEAMS_ONOFF'
    | 'B2B_MIGRATE_TEAMS_ONOFF_NO_ACCOUNT'
    | 'CALL_CANCELLED_HIDDEN_CALLER'
    | 'CALL_CANCELLED_MSISDN_MISMATCH'
    | 'EMAIL_VALIDATION_CODE'
    | 'WSJ_NUMBER_PURCHASE'
    | 'WSJ_RECEIPT'
    | 'WSJ_OFFER_RECEIPT'
    | 'WSJ_NUMBER_AND_OFFER_RECEIPT'
    | 'WSJ_RECURRING_PAYMENT_FAILURE'
    | 'WSJ_OFFER_RECURRING_PAYMENT_FAILURE'
    | 'WSJ_CARD_EXPIRING_SOON'
    | 'PREMIUM_TRIAL_WELCOME'
    | 'PREMIUM_TRIAL_REMINDER'
    | 'USER_PURCHASE_REFUND'
    | 'POLICE_REPORTS_INVOICE';
  userId?: string;
  email?: string;
  locale?: string;
  parameters?: Record<string, string>;
}

export interface MessageThreadsRequest {
  /** @format date-time */
  endDate: string;
  requestedNumber: string;
  offsetThreadId?: string;

  /**
   * @format int32
   * @min 0
   * @max 10000
   */
  limit: number;
}

export interface MessageThreadsResponse {
  messageThreads?: MessageUserThreadDto[];
}

export interface MessageUserThreadDto {
  threadId?: string;
  userId?: string;
}

export interface MessageHistoryRequest {
  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  messageThreads: MessageUserThreadDto[];

  /**
   * @format int32
   * @min 0
   * @max 10000
   */
  limit: number;
}

export interface MessageHistoryResponse {
  messageTrafficDetails?: MessageTrafficDetailsDto[];
  processedThreadIds?: string[];
}

export interface AddressDto {
  addressLine1?: string;
  addressLine2?: string;
  cityName?: string;
  postcode?: string;
  countryName?: string;
}

export interface FindUserRequest {
  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  endDate?: string;
  firstPartyNumbers?: string[];
  user?: PersonDto;
  includePaymentMeans?: boolean;
}

export interface PersonDto {
  firstName?: string;
  lastName?: string;
  address?: AddressDto;

  /** @format date-time */
  birthDate?: string;
}

export interface CategoryDto {
  firstPartyNumber?: string;
  categoryId?: string;

  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  endDate?: string;
  eventLogs?: EventLogDto[];
}

export interface EventLogDto {
  /** @format date-time */
  created?: string;
  event?: string;
}

export interface FindUserResponse {
  users?: UserDto[];
}

export interface PaymentMeansDto {
  paymentMeansType?: string;
  paymentReference?: string;
  transactionLabel?: string;
  price?: number;

  /** @format date-time */
  date?: string;
}

export interface UserDto {
  targetPhoneNumbers?: string[];
  categories?: CategoryDto[];
  userIdentity?: PersonDto;
  invoiceAddress?: AddressDto;
  paymentMeans?: PaymentMeansDto[];
}

export interface CallsEnablingAndPhonesLimitValidationRequest {
  userId?: string;

  /** @format int32 */
  phonesAmount?: number;
}

export interface OnOffCallCountryCodeData {
  countryIsoCode?: string;
  onOffCallEnabled?: boolean;
}

export interface OnOffCallStatusRequest {
  onOffCallStatus: 'ENABLED' | 'DISABLED' | 'ALLOCATING_TSANS' | 'DEALLOCATING_TSANS';
  userId: string;
}

export interface SimNumberValidationCallbackRequest {
  transactionId: string;
  userRealPhone?: string;
  callbackParams?: Record<string, object>;
  error?: Error;
}

export interface OnOffCallSyncTsansRequest {
  userId: string;
}

export interface MmsMessageRequest {
  id: string;

  /** @format date-time */
  created: string;
  phoneNumber: string;
  secondPartyPhoneNumber: string;
  body: string;
  type: 'TEXT' | 'AUDIO' | 'VIDEO' | 'IMAGE' | 'INSTA_VIDEO';

  /** @format int64 */
  mediaLength?: number;
  transactionId?: string;
}

export interface MmsCheckNumberRequest {
  number: string;
}

export interface MmsCheckNumberResponse {
  onoff?: boolean;
  status?: string;
}

export interface LocalizationRequest {
  locale: string;
  messageKey: string;
  args?: object[];
}

export interface SimpleValueResponseString {
  value?: string;
}

export interface SendSmsByIntegrationsRequest {
  messageType: 'TEXT' | 'AUDIO' | 'VIDEO' | 'IMAGE' | 'INSTA_VIDEO';
  content: string;
  userId: string;
  sourcePhoneNumber: string;
  targetPhoneNumber: string;
}

export interface SendSmsByIntegrationsResponse {
  id?: string;

  /** @format date-time */
  createdAt?: string;
}

export interface GreetingsForwardingRouteRequest {
  /** @pattern ^([A-Z]){2}$ */
  simCountryCode: string;
  forwardToNumbers: string[];
  userId: string;
}

export interface GreetingsForwardingRouteResponse {
  responseMap?: Record<string, boolean>;
}

export interface GreetingsNumberValidationRequest {
  /** @pattern ^([A-Z]){2}$ */
  userSimCountryCode: string;

  /** @pattern ^([0-9])+$ */
  greetingNumber: string;
  userId: string;
}

export interface PhoneNumbersRequest {
  numbers?: string[];
}

export interface PhoneNumbersResponse {
  numbers?: string[];
}

export interface ContactRateLimitValidationRequest {
  userId?: string;

  /** @format int32 */
  contactsSize?: number;
  userDeviceId?: string;
  globalCheckOnly?: boolean;
}

export interface CategoriesValidationRequest {
  userId?: string;
  categoryIds?: string[];
}

export interface ValidateCategoriesResponse {
  value?: boolean;
  nonValidCategories?: string[];
}

export interface GetCategoryInfoRequest {
  categoryIds: string[];
}

export interface CategoryInfo {
  userId?: string;
  id?: string;
  number?: string;
  countryIsoCode?: string;
}

export interface GetCategoryInfoResponse {
  categoryInfoList?: CategoryInfo[];
}

export interface TransferCallPushRequest {
  transferredCallId: string;
  status: string;
  reason?: string;
}

export interface BillingQueueOperationRequestV2 {
  messageId?: string;
  idempotencyKey: string;
  originalIdempotencyKey?: string;
  jsonBody?: string;
  urlParams?: string[];
  billingCallName: string;

  /** @format int32 */
  retryCounter?: number;
  billingOperationType?: 'CHECK' | 'PROCESS';
}

export interface BillingQueueResponse {
  status?: 'OK' | 'NOK';
}

export interface B2BUpdateUserBlockedStatusRequest {
  blocked: boolean;
  organizationId: string;

  /** The ID of a B2B user (optional parameter). NOTE: If the userId is not provided, then ALL users which belong to the specified organization will be blocked / unblocked. */
  userId?: string;
}

export interface B2BUpdateGlobalCreditsAmountRequest {
  idempotencyKey: string;
  organizationId: string;
  isReduction: boolean;

  /** @format int32 */
  amount: number;
}

export interface B2BUpdateCategoryTypeRequest {
  categoryId: string;
  categoryType: 'ONOFF' | 'GREETINGS' | 'MICROSOFT';
}

export type B2BEmptyRO = object;

export interface B2BUnReserveNumbersRequest {
  organizationId: string;
  phoneNumbers: string[];
}

export interface B2BToggleKybRequiredRequest {
  userIds: string[];
  required: boolean;
}

export interface B2BSendEmailRequest {
  organizationId: string;
  userIds: string[];
}

export interface B2BSendMigrationEMailRequest {
  organizationId: string;
  userIds: string[];
  withOnBoarding?: boolean;
}

export interface B2BResumePlansForCategoryRequest {
  userId: string;
  organizationId: string;
  categoryId: string;
}

export interface B2BResetUserPasswordRequest {
  userId: string;
  organizationId: string;
  initiator: string;
  initiatorEmail: string;
}

export interface B2BReserveNumbersRequest {
  organizationId: string;
  phoneNumbers: string[];
}

export interface B2BRenewPlanRequest {
  userId: string;
  organizationId: string;
  categoryId: string;

  /** @format date-time */
  expirationDate: string;
  planType?:
    | 'SMALL'
    | 'MEDIUM'
    | 'MEDIUM_V2'
    | 'LARGE'
    | 'TRIAL'
    | 'PREMIUM'
    | 'BUSINESS_PLAN_1'
    | 'BUSINESS_PLAN_2'
    | 'BUSINESS_PLAN_3';
}

export interface B2BRenewPlansRequest {
  plans: B2BRenewPlanRequest[];
}

export interface B2BReleaseB2CNumberToB2BUserRequest {
  sourceUserEmail: string;
  virtualPhoneNumber: string;
  b2bUserId: string;
}

export interface BillingHocsOperationRegisterOrganizationRequest {
  organizationId: string;
  organizationName: string;
}

export interface B2BReallocateCategoryRequest {
  categoryId: string;
  destinationUserId: string;
  copyTraffic: boolean;
}

export interface B2BReallocateCategoryRO {
  categoryId?: string;
}

export interface ManageGreetingsForCategoryRequest {
  organizationId: string;
  categoryId: string;
  enabled?: boolean;
}

export interface B2BDetailedReportRequest {
  organizationId: string;

  /** @format date-time */
  fromDateTime: string;

  /** @format date-time */
  toDateTime: string;
  timezone: string;
}

export interface B2BUpdateUserRequest {
  userId: string;
  organizationId: string;
  firstName: string;
  lastName: string;
  email: string;
  nullifyPassword?: boolean;
}

export interface UserDevices {
  userId: string;
  deviceIds: string[];
}

export interface UserDevicesForLogoutRequest {
  userDevicesList: UserDevices[];
}

export interface UserIdsRequest {
  userIds: string[];
}

export interface UserIdDeviceElement {
  deviceId?: string;
  name?: string;
  hardware?: string;
  os?: string;

  /** @format date-time */
  lastLogin?: string;
  loggedIn?: boolean;
}

export interface UserIdDevicesResponse {
  userId?: string;
  devices?: UserIdDeviceElement[];
}

export interface BillingHocsOperationDeleteOrganizationRequest {
  organizationId: string;
}

export interface B2BDeleteCategoryRequest {
  userId: string;
  organizationId: string;
  categoryId: string;
}

export interface B2BDeleteUserRequest {
  userId: string;
  employeeId: string;
}

export interface B2BDeleteUsersRequest {
  organizationId: string;
  users: B2BDeleteUserRequest[];
  movePhoneNumbersToQuarantine: boolean;
}

export interface B2BCreateCategoriesPlansRequest {
  categories: B2BCreateCategoryPlanRequest[];
}

export interface B2BCreateCategoryPlanRequest {
  id?: string;
  userId: string;
  organizationId: string;
  categoryName?: string;

  /** @pattern ^#[0-9A-Fa-f]{6}$ */
  categoryColor?: string;
  categoryColorType?: ColorType;

  /** @pattern ^([A-Z]){2}$ */
  countryIsoCode: string;
  planType:
    | 'SMALL'
    | 'MEDIUM'
    | 'MEDIUM_V2'
    | 'LARGE'
    | 'TRIAL'
    | 'PREMIUM'
    | 'BUSINESS_PLAN_1'
    | 'BUSINESS_PLAN_2'
    | 'BUSINESS_PLAN_3';
  national: boolean;

  /** @format date-time */
  expirationDate: string;
  autorenewable?: boolean;
  phoneNumber?: string;
  type?: string;
}

export interface B2BCreateCategoriesPlansRO {
  categories?: B2BCreateCategoryPlanRO[];
}

export interface B2BCreateCategoryPlanRO {
  id?: string;
  userId?: string;
  categoryId?: string;
  virtualPhoneNumber?: string;
  type?: string;
}

/**
 * Account Type of the user
 */
export enum AccountType {
  NO_TYPE = 'NO_TYPE',
  STARTER = 'STARTER',
  PREMIUM = 'PREMIUM',
  ESSENTIAL = 'ESSENTIAL',
  BUSINESS1 = 'BUSINESS_1',
  BUSINESS2 = 'BUSINESS_2',
  BUSINESS3 = 'BUSINESS_3',
}

export interface B2BCreateUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  organizationId: string;
  employeeId: string;
  locale: string;

  /** Account Type of the user */
  accountType?: AccountType;
  kybRequired?: boolean;
}

export interface B2BCreateUsersRequest {
  users: B2BCreateUserRequest[];
  sendEmail?: boolean;
}

export interface B2BCreateUserRO {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  employeeId?: string;
  organizationId?: string;

  /** Account Type of the user */
  accountType?: AccountType;
  kybRequired?: boolean;
}

export interface B2BCreateUsersRO {
  users?: B2BCreateUserRO[];
}

export interface B2BEmailListRequest {
  emails: string[];
}

export interface B2BEmailAvailabilityRO {
  statuses?: EmailStatus[];
}

export interface EmailStatus {
  email?: string;
  isUsed?: boolean;
  isDomainBlacklisted?: boolean;
}

export interface B2BChangeAccountTypeRequest {
  organizationId: string;

  /** Account Type of the user */
  accountType: AccountType;
}

export interface B2BCancelPlansForCategory {
  userId: string;
  organizationId: string;
  categoryId: string;
}

export interface RemoteAddress {
  ip?: string;
  port?: string;
}

export interface TokenInfoRequest {
  token?: string;
  remoteAddress?: RemoteAddress;
  userAgent?: string;
}

export interface Device {
  id?: string;
}

export interface Organization {
  id?: string;
}

export interface TokenInfoV2Response {
  user?: User;
  error?: Error;
}

export interface User {
  id?: string;
  email?: string;
  device?: Device;
  organization?: Organization;
}

export interface PasswordRecoveryRequestedRequest {
  userId: string;
  email: string;
  token: string;
  initiatorApp?: 'WEB_SUBSCRIPTION';
}

export interface PasswordChangedRequest {
  userId: string;
  token: string;
  password: string;
}

export interface PasswordChangedResponse {
  error?: Error;
}

export interface CallFlow {
  profile_index?: string;
  caller_profile?: CallerProfile;
}

export interface CallStats {
  audio?: CallStatsAudio;
}

export interface CallStatsAudio {
  inbound?: CallStatsAudioInbound;
}

export interface CallStatsAudioInbound {
  mos?: string;
  quality_percentage?: string;
  skip_packet_count?: string;
}

export interface CallerProfile {
  destination_number?: string;
  originatee?: Originatee;
}

export interface Originatee {
  originatee_caller_profiles?: OriginateeCallerProfile[];
}

export interface OriginateeCallerProfile {
  username?: string;
  destination_number?: string;
}

export interface UploadFreeswitchCdrRequest {
  callStats: CallStats;
  variables: Variables;
  callflow: CallFlow[];
}

export interface Variables {
  direction: string;
  internal?: boolean;
  uuid: string;

  /** @format int64 */
  duration: number;

  /** @format int64 */
  billsec: number;

  /** @format int64 */
  start_epoch: number;

  /** @format int64 */
  end_epoch: number;

  /** @format int64 */
  answer_epoch?: number;

  /** @format int64 */
  progress_epoch?: number;

  /** @format int64 */
  progress_media_epoch?: number;
  hangup_cause: string;
  ep_codec_string?: string;
  recordvm?: string;
  vmanswered?: string;
  sip_from_user_stripped?: string;
  sip_from_uri?: string;
  'sip_h_X-onoff-cli'?: string;
  'sip_h_X-onoff-secondParty'?: string;
  'sip_h_X-Orig-To'?: string;
  'sip_h_X-onoff-caller'?: string;
  'sip_h_X-onoff-callee'?: string;
  'sip_h_X-onoff-rpid'?: string;
  'sip_P-Asserted-Identity'?: string;
  'sip_h_X-onoff-clir'?: string;
  sip_to_user?: string;
  'sip_h_X-onoff-direction'?: string;
  'sip_h_X-onoff-vmaction'?: 'CALLBACK' | 'VMS' | 'BUSY' | 'IVR' | 'CONNECT' | 'FORWARD' | 'CALL' | 'DROP';
  'sip_h_X-onoff-original-callee'?: string;
  sip_invite_failure_status?: string;
  rtp_audio_in_jitter_max_variance?: string;
  sip_local_network_addr?: string;
  bleg?: boolean;
  remote_media_ip?: string;
  remote_audio_ip?: string;
  'sip_h_X-onoff-greeting-ring-me'?: string;
  'sip_h_X-onoff-transfer-transaction-id'?: string;
  onoff_cdr_stirshaken_status?: string;
  onoff_cdr_stirshaken_attest?: string;
  onoff_cdr_action?: string;
  last_bridge_proto_specific_hangup_cause?: string;
  sip_from_user?: string;
  'sip_h_X-onoff-uuid': string;
  'sip_h_X-onoff-onoffTransactionId'?: string;
  'sip_h_X-onoff-billingid'?: string;
  last_sent_callee_id_number?: string;
  flow_id?: string;
  flow_step_id?: string;
  sip_h_Diversion?: string;
  'sip_h_X-onoff-voipout'?: string;
  sip_invite_to_uri?: string;
  'sip_h_X-onoff-forwardnumber'?: string;
  'sip_h_X-onoff-forwarded'?: string;
  'sip_h_X-onoff-mstenant'?: string;
  'sip_h_X-onoff-proximus-number'?: string;
  'sip_h_X-onoff-call-network'?: 'INTERNAL' | 'EXTERNAL';
  'sip_h_X-onoff-call-recording-record'?: string;
  'sip_h_X-onoff-call-recording-file'?: string;
  'sip_h_X-onoff-call-recording-bucket'?: string;
  'sip_h_X-onoff-record'?: string;
  'sip_h_X-onoff-record-bucket-name'?: string;
  'sip_h_X-onoff-record-file-name'?: string;
  onoff_cdr_transfer_service_channel?: string;
  onoff_cdr_terminated_because_balance?: string;
}

export interface PostIncomingVoicemailRequest {
  phoneNumbers: string[];
  secondPartyNumber: string;
  clir?: boolean;
  objectId: string;
  callId: string;
  fileFormat: string;

  /** @format int64 */
  audioLength: number;
}

export interface IncomingVoicemailSignedUploadUrlRequest {
  contentType: string;
}

export interface IncomingVoicemailSignedUploadUrlResponse {
  objectId?: string;
  signedUploadUrl?: string;
}

export interface MarkCallRecordingReadyRequest {
  transactionId: string;
}

export interface UserSupportRequest {
  phones: string[];
  frSubject?: string;
  frText: string;
  enSubject?: string;
  enText: string;
  useFooter?: boolean;
  communicationMethod: 'MESSAGE' | 'EMAIL' | 'MESSAGE_AND_EMAIL';
}

export interface UserSupportResponse {
  /** @format int32 */
  usersCategoryCount?: number;
}

export interface UserDataRequest {
  userId: string;
  simCountryIso?: string;
  firstName?: string;
  lastName?: string;
}

export interface UpdateUserMaxNumberAmountRequest {
  userId: string;

  /**
   * @format int32
   * @min 1
   * @max 70
   */
  maxNumberAmount: number;
}

export interface AdminUpdateCallAnswerMethodRequest {
  userId: string;
  callAnswerMethod: 'CALLBACK' | 'VOIP' | 'OFFLINE';
}

export interface UserIdRequest {
  userId?: string;
}

export interface ClearUserCacheRequest {
  userId: string;
}

export interface ResyncUserCategoriesRequest {
  userId: string;
}

export interface KycManualSimNumberValidationRequest {
  userId: string;
  userRealPhoneNumber: string;
  performerId: string;
}

export interface GetBytelSubscriptionDataRequest {
  userId: string;
}

export interface BytelSubscriptionBasicData {
  userId?: string;
  partnerCustomerId?: string;
  categoryId?: string;
}

export interface UserBytelSubscriptionsResponse {
  bytelSubscriptionBasicDataList?: BytelSubscriptionBasicData[];
}

export interface GeneratePasswordResetLinksRequest {
  userEmails?: string[];
}

export interface PasswordResetData {
  userId?: string;
  userEmail?: string;
  passwordResetLink?: string;

  /** @format date-time */
  expirationDate?: string;
}

export interface UserDeleteRequest {
  userId: string;
  performedUserId: string;
  leaveNumbersInQuarantine: boolean;
  userNote?: string;
}

export interface ChangeUserEmailResponse {
  result?: string;
  success?: boolean;
}

export interface TogglePremiumOfferRequest {
  userId: string;
  premiumEnabled?: boolean;
  performerUserId: string;
}

export interface CancelUserOfferSubscriptionAdminRequest {
  userId: string;
  subscriptionId: string;
  performerUserId: string;
}

export interface AddPremiumSubscriptionRequest {
  userId: string;

  /** @format int32 */
  months: number;
  performerUserId: string;
}

export interface PortInAdminConfirmRequest {
  phoneNumber: string;
  performerUserId?: string;
}

export interface PortInAdminCancelRequest {
  phoneNumber: string;
  userId: string;
}

export interface MoveNumberOutOfStockRequest {
  number?: string;
  numberCountryIso?: string;
  areaShortName?: string;
  numberStatuses?: string[];
  numberTypes?: string[];

  /** @format int32 */
  limit?: number;
}

export interface ListResponsePhoneNumberManagementData {
  items?: PhoneNumberManagementData[];
}

export interface PhoneNumberManagementData {
  number?: string;
  numberStatus?: string;
  userId?: string;
  countryIsoCode?: string;
  numberType?: string;
  phoneNumberProvider?: string;

  /** @format date-time */
  timeToChange?: string;
}

export interface AddNumberRequest {
  numberData: NumberRangeImportData;
}

export interface NumberRangeImportData {
  number: string;
  countryIsoCode: string;
  areaShortName?: string;
  region?: string;

  /** @format int32 */
  mcc: number;

  /** @format int32 */
  sipProvisionalResponse: number;
  numberTypeManual: boolean;
  numberType: 'REGULAR' | 'ELITE';
  numberFunctionality: NumberFunctionality;
  phoneNumberProvider: string;
  numberPoolName: 'DEFAULT' | 'VIRGIN_ONOFF' | 'B2B' | 'GREETINGS';
  tsan?: boolean;
}

export interface SpammerInfo {
  phoneNumber?: string;

  /** @format int64 */
  count?: number;
}

export interface FileRawDTO {
  /** @format byte */
  content?: string;
  contentType?: string;
}

export interface VerifiedCustomer {
  id?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;

  /** @format date-time */
  birthDate?: string;
  birthPlace?: string;
  birthCountryIsoCode?: string;
  documentNumber?: string;
  documentType?:
    | 'PASSPORT'
    | 'ID_CARD'
    | 'DRIVERS_LICENSE'
    | 'RESIDENCE_PERMIT'
    | 'VISA'
    | 'INVOICE'
    | 'UTILITY_BILL'
    | 'TAX_STATEMENT'
    | 'BANK_STATEMENT'
    | 'OTHER';
  documentIssuedCountryIsoCode?: string;

  /** @format date-time */
  documentIssueDate?: string;

  /** @format date-time */
  documentExpirationDate?: string;
  documentValidationStatus?:
    | 'NONE'
    | 'UNFINISHED'
    | 'SUBMITTED'
    | 'CONFIRMED_BY_VERIFF_NEEDS_MANUAL_POA'
    | 'CONFIRMED'
    | 'REJECTED'
    | 'REJECTED_BY_MAIN_SERVER';
  verifiedCustomerAddresses?: VerifiedCustomerAddress[];
}

export interface VerifiedCustomerAddress {
  id?: string;
  verifiedCustomerId?: string;
  fullAddress?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressNumber?: string;
  city?: string;
  postcode?: string;
  province?: string;
  countryIsoCode?: string;
  country?: string;
}

export interface VerifiedCustomerRequest {
  verifiedCustomer: VerifiedCustomer;
  documentFrontImage?: FileRawDTO;
  documentBackImage?: FileRawDTO;
  adminUserId?: string;
}

export interface KycDocumentRejectRequest {
  kycPersonalDocumentId?: string;
  userId?: string;
  rejectReason?: string;
  adminUserId?: string;
}

export interface DocumentValidationPageRequest {
  statusFilter?:
    | 'NONE'
    | 'UNFINISHED'
    | 'SUBMITTED'
    | 'CONFIRMED_BY_VERIFF_NEEDS_MANUAL_POA'
    | 'CONFIRMED'
    | 'REJECTED'
    | 'REJECTED_BY_MAIN_SERVER';
  filterUsersIdList?: string[];
  includeDeleted?: boolean;

  /** @format int32 */
  pageNr?: number;

  /** @format int32 */
  recordsCount?: number;
}

export interface DocumentValidationPageResponse {
  personalDocuments?: KycPersonalDocument[];
  documentId2UrlsMap?: Record<string, KycPersonalDocumentImage[]>;
  categoryNeedForValidationMap?: Record<string, KycCategoryRequiredDocumentValidation[]>;
  verifiedCustomerForUserMap?: Record<string, VerifiedCustomer>;
  userDataMap?: Record<string, UserCachedData>;
}

export interface KycCategoryRequiredDocumentValidation {
  categoryId?: string;
  number?: string;
  countryIso?: string;
  documentValidationRequired?: boolean;
  manualValidationRequired?: boolean;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  assignedDate?: string;
}

export interface KycPersonalDocument {
  id: string;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  updated?: string;
  userId?: string;
  documentType?:
    | 'PASSPORT'
    | 'ID_CARD'
    | 'DRIVERS_LICENSE'
    | 'RESIDENCE_PERMIT'
    | 'VISA'
    | 'INVOICE'
    | 'UTILITY_BILL'
    | 'TAX_STATEMENT'
    | 'BANK_STATEMENT'
    | 'OTHER';
  documentNumber?: string;
  documentCountryIsoCode?: string;
  documentFrontContentType?: string;
  documentFrontFileId?: string;
  documentFrontOriginalFilename?: string;
  documentBackContentType?: string;
  documentBackFileId?: string;
  documentBackOriginalFilename?: string;
  status?:
    | 'NONE'
    | 'UNFINISHED'
    | 'SUBMITTED'
    | 'CONFIRMED_BY_VERIFF_NEEDS_MANUAL_POA'
    | 'CONFIRMED'
    | 'REJECTED'
    | 'REJECTED_BY_MAIN_SERVER';
}

export interface KycPersonalDocumentImage {
  src?: string;
  contentType?: string;
}

export interface UserCachedData {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  b2bOrganizationId?: string;

  /** @format int64 */
  facebookId?: number;
  advertisingId?: string;
  googleClientId?: string;
  password?: string;
  validatedSimNumber?: string;

  /** @format int32 */
  maxNumberAmount?: number;
  nationalCountryIsoCode?: string;
  callAnswerMethod?: string;
  callOutMethod?: string;
  searchUserImageUrl?: string;

  /** @format date-time */
  deleted?: string;

  /** @format date-time */
  nextAppRateDate?: string;
  devices?: UserDeviceData[];

  /** @format int32 */
  invalidLoginCount?: number;
  communicationLocale?: string;
  blocked?: boolean;
  emailCorrect?: boolean;
  emailVerified?: boolean;
  emailUnsubscribe?: boolean;
  offersUnsubscribe?: boolean;
  offerAutoRenewable?: boolean;
  offerMultipleAutoRenewable?: boolean;
  b2bUser?: boolean;
}

export interface UserDeviceData {
  id?: string;
  userId?: string;
  deviceType?: 'ANDROID' | 'IOS' | 'WEB' | 'CHROMEAPP' | 'MSTEAMS';
  accessToken?: string;
  mobileToken?: string;
  pushToken?: string;
  pushTokenSpecial?: string;
  appVersion?: string;
  locale?: string;
  simCountryIsoCode?: string;
  timeZone?: string;
  roaming?: boolean;
  lastIpAddress?: string;
  pubnubEnabled?: boolean;
}

export interface CategoryStateUpdateRequest {
  adminId?: string;
  userId: string;
  categoryId: string;
  state: 'NORMAL' | 'VALIDATED' | 'UNVALIDATED' | 'UNVALIDATED_WAS_IN_USE' | 'TRIAL' | 'SIM';
}

export interface ResyncSubscriptionRequest {
  userId: string;
  categoryId: string;
}

export interface ExtendExpirationDateRequest {
  userId: string;

  /** @format int32 */
  expirationMonthsForExtend: number;
  categoryId: string;
  virtualPhoneNumber: string;
  planTypeForExtend:
    | 'SMALL'
    | 'MEDIUM'
    | 'MEDIUM_V2'
    | 'LARGE'
    | 'TRIAL'
    | 'PREMIUM'
    | 'BUSINESS_PLAN_1'
    | 'BUSINESS_PLAN_2'
    | 'BUSINESS_PLAN_3';
  performedUserId?: string;
}

export interface CategoryDeleteRequest {
  userId: string;
  categoryId: string;
  performedUserId: string;
  userNote?: string;
}

export interface BytelRegisterRequest {
  email: string;
  password?: string;
  recaptchaToken: string;
  pcid: string;
  checksum?: string;
  callbackUrl?: string;
}

export interface BytelRegisterResponse {
  redirectUrl?: string;
}

export interface BytelChecksumVerifyRequest {
  pcid: string;
  callbackUrl?: string;
  checksum: string;
}

export interface BytelCheckEmailRequest {
  email: string;
}

export interface BytelCheckEmailResponse {
  emailAlreadyUsed?: boolean;
  previousPhoneNumber?: string;
}

export interface BytelAttachOfferRequest {
  email: string;
  emailConfirmation: string;
  restorePreviousPhoneNumber?: boolean;
  recaptchaToken: string;
  pcid: string;
  checksum?: string;
  callbackUrl?: string;
}

export interface DecisionData {
  verification?: Verification;
}

export interface UserDefinedData {
  status?: string;
  statusCode?: string;
  reason?: string;
  reasonCode?: string;

  /** @format date-time */
  createdAt?: string;
}

export interface VeriffManualDecisionData {
  sessionId?: string;
  attemptId?: string;
  vendorData?: string;
  eventType?: string;

  /** @format date-time */
  time?: string;
  data?: DecisionData;
}

export interface Verification {
  id?: string;
  userDefinedData?: UserDefinedData;
}

export interface VeriffEventData {
  id: string;
  attemptId: string;

  /** @format int32 */
  code: number;
  action: string;
  feature: string;
  vendorData: string;
}

export interface TechnicalData {
  ip?: string;
}

export interface VeriffDecisionData {
  status?: string;
  verification?: Verification;
  technicalData?: TechnicalData;
}

export interface AppleSearchAdsAttributionResponse {
  'iad-country-or-region'?: string;
}

export interface LastAttributedTouchData {
  '~ad_set_id'?: string;
  '~campaign_id'?: string;
  '~campaign'?: string;
  '~channel'?: string;
  '+url'?: string;
  $marketing_title?: string;
  '+user_data_user_agent'?: string;
  '~keyword'?: string;
  '~advertising_partner_name'?: string;
  '~country_or_region'?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  '~tags'?: string[];
  '~secondary_publisher'?: string;
  '~ad_name'?: string;
  '~ad_set_name'?: string;
  '~ad_id'?: string;
  '+apple_search_ads_attribution_response'?: AppleSearchAdsAttributionResponse;
}

export interface OnSignupRequest {
  id?: string;

  /** @format date-time */
  event_timestamp?: string;
  last_attributed_touch_data?: LastAttributedTouchData;
  user_data?: UserData;
}

export interface UserData {
  developer_identity?: string;
}

export interface BytelCancelRequest {
  id?: string;
}

export interface BytelSuccessResponse {
  status?: string;
}

export interface BytelActivateRequest {
  id?: string;
  offer?: string;
}

export interface OAuth2FakeTokenResponse {
  access_token?: string;

  /** @format int32 */
  expires_in?: number;
  token_type?: string;
}

export interface BillingHocsResponse {
  /** @format date-time */
  created?: string;
  id?: string;
  type?:
    | 'ADD_SUBSCRIBER'
    | 'PUT_SUBSCRIBER'
    | 'GET_SUBSCRIBER'
    | 'ADD_SUBSCRIBER_GROUP'
    | 'GET_SUBSCRIBER_GROUP'
    | 'PUT_SUBSCRIBER_GROUP'
    | 'ADD_IDENTIFIER_GROUP'
    | 'PUT_IDENTIFIER_GROUP'
    | 'GET_IDENTIFIER_GROUP'
    | 'ADD_IDENTIFIER'
    | 'PUT_IDENTIFIER'
    | 'ADD_SUBSCRIBER_GROUP_BUNDLE'
    | 'PUT_SUBSCRIBER_GROUP_BUNDLE'
    | 'ADD_BUNDLE'
    | 'PUT_BUNDLE'
    | 'PUT_BUNDLE_BALANCE'
    | 'GET_BUNDLE'
    | 'GET_PRICES'
    | 'UPDATE_PRICE'
    | 'GET_BLACKLIST_INDEXES'
    | 'GET_REQUEST_TYPE_SETTINGS';
  status?: 'SUCCESS' | 'FAILURE' | 'PROCESSING';
  variables?: RequestPathVariables;
  errors?: ResponseError[];
  data?: object;
  user?: object;

  /** @format date-time */
  last_updated?: string;
  created_data?: object;
}

export interface RequestPathVariables {
  values?: Record<string, string>;
}

export interface ResponseError {
  field_name?: string;
  error_message?: string;
}

export interface B2BEmployeesCallRecordingStatusRequest {
  ids: string[];
}

export interface B2BEmployeeCallRecordingStatusRO {
  content: CallRecordingStatusResponse[];
}

export interface CallRecordingStatusResponse {
  id: string;
  callRecording: boolean;
}

export interface UpdateUsersCallRecordingPropertyRequest {
  ids: string[];
}

export interface B2BOrganizationCallTagUpdateRequest {
  id: string;
  tagName: string;
  tagColor: string;
  hidden?: boolean;
  deleted?: boolean;
}

export interface B2BOrganizationCallTagDeleteRequest {
  ids: string[];
}

export interface B2BOrganizationCallTagCreateRequest {
  tagName: string;
  tagColor: string;
}

export interface CallRecordingTranscriptionRequest {
  callLogId: string;
  userId: string;
}

export interface CallRecordingTranscriptionResponse {
  status?: 'READY' | 'IN_PROGRESS' | 'ERROR';
  transcription?: string;
  error?: Error;
}

export interface CallRecordingBulkDownloadRequest {
  files: string[];
}

export interface B2BRegisterWhisperingCallRequest {
  sipInstanceId?: string;
  callId: string;
  type?: 'WHISPERING' | 'MONITORING';
}

export interface B2BRegisterWhisperingCallResponse {
  caller: string;
  displayName: string;
  sipCredentials?: SipCredentials;
  sipConfig?: SipConfigResponse;
}

export interface DomainSrvRecord {
  name?: string;

  /** @format int32 */
  port?: number;

  /** @format int32 */
  priority?: number;

  /** @format int32 */
  weight?: number;
}

export interface SipConfigResponse {
  domain?: string;
  techDomain?: string;
  wss?: DomainSrvRecord[];
  stun?: DomainSrvRecord[];
}

export interface SipCredentials {
  instanceId?: string;
  sipUsername?: string;
  sipSecret?: string;
  dtls?: boolean;
}

export interface B2BCallLogSearchRequest {
  userIds?: string[];
  direction?: 'INBOUND' | 'OUTBOUND';
  hasRecording?: boolean;

  /** @format date-time */
  startedFrom?: string;

  /** @format date-time */
  startedTo?: string;

  /**
   * @format int32
   * @min 0
   * @max 100
   */
  limit: number;

  /**
   * @format int32
   * @min 0
   * @max 100000
   */
  offset: number;
}

export interface B2BCallLogResponse {
  content?: CallLog[];

  /** @format int64 */
  total?: number;
}

export interface Call {
  /** @format int32 */
  duration?: number;

  /** @format date-time */
  startedAt?: string;

  /** @format date-time */
  endedAt?: string;
  status?: string;
  direction?: string;
  caller?: Participant;
  callee?: Participant;
  recording?: CallRecording;
}

export interface CallLog {
  id?: string;
  call?: Call;
}

export interface CallRecording {
  link?: string;

  /** @format int32 */
  duration?: number;
}

export interface Participant {
  number?: string;
  displayName?: string;
}

export interface CallRecordingDeleteRequest {
  callLogId: string;
}

export interface B2BCallRecordingBulkDeleteRequest {
  ids: string[];
}

export interface B2BCallRecordingBulkDeleteResponse {
  result?: B2BCallRecordingBulkDeleteResult[];
}

export interface B2BCallRecordingBulkDeleteResult {
  id?: string;
  deleted?: boolean;
}

export interface RetrieveThreadsRequest {
  /**
   * ID of the last message of the last thread in the previous batch
   *
   */
  offset?: string;

  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit: number;

  /** Filter only 'unread' threads when 'true'. Default: false */
  onlyUnread?: boolean;
}

export interface ShortMessage {
  id: string;
  threadId: string;
  creatorId: string;
  sourceUserId?: string;
  sourceCategoryId?: string;
  sourcePhoneNumber: string;
  targetUserId?: string;
  targetCategoryId?: string;
  targetPhoneNumber: string;
  targetPhoneNumbers?: string[];
  body: string;
  videoThumbnail?: string;

  /** @format date-time */
  createdAt?: string;

  /** @format date-time */
  seenAt?: string;

  /** @format date-time */
  nextAttemptAt?: string;
  type: 'TEXT' | 'AUDIO' | 'VIDEO' | 'IMAGE' | 'INSTA_VIDEO';
  directType?: 'NORMAL' | 'DIRECT' | 'CONVERTED';

  /** @format int64 */
  mediaLength?: number;
  incoming: boolean;
  sms: boolean;
  mms: boolean;
  visible?: boolean;
  deleted?: boolean;
  group?: boolean;
  silent?: boolean;

  /** @format int32 */
  unreadMessages?: number;

  /** @format int32 */
  unreadThreads?: number;
}

export interface RetrieveAllMessagesRequest {
  threadId: string;
  offset?: string;

  /**
   * @format int32
   * @min 1
   * @max 300
   */
  limit: number;
}

export interface GetPreSignedURLRequest {
  messageType: 'TEXT' | 'AUDIO' | 'VIDEO' | 'IMAGE' | 'INSTA_VIDEO';

  /**
   * @format int64
   * @max 536870912
   */
  size: number;
  contentType: string;

  /** @format int64 */
  thumbSize?: number;
  thumbContentType?: string;
}

export interface GetPreSignedURLResponse {
  generatedId?: string;
  preSignedUrl?: string;
  s3BucketName?: string;
}

export interface GetCategoriesRequestV3 {
  countCalls?: boolean;
  countMessages?: boolean;
  countVoicemails?: boolean;
}

export interface GetCategoriesResponseV3 {
  categories?: CategoryDataV3Impl[];
}

export interface CategoryBadgeDataOnlyV3 {
  id?: string;

  /** @format int64 */
  callsBadge?: number;

  /** @format int64 */
  messagesBadge?: number;

  /** @format int64 */
  voicemailsBadge?: number;
}

export interface GetCategoriesBadgesResponseV3 {
  categories?: CategoryBadgeDataOnlyV3[];
}

export interface GetCallLogsRequest {
  offset?: string;
}

export interface CallLogResponse {
  id?: string;
  categoryId?: string;
  secondPartyPhone?: string;
  isTransferredCall?: boolean;
  transferorNumber?: string;
  transfereeNumber?: string;
  transferableNumber?: string;

  /** @format date-time */
  endedAt?: string;

  /** @format date-time */
  startedAt?: string;

  /** @format int64 */
  duration?: number;
  status?:
    | 'START'
    | 'ANSWER'
    | 'FALLBACK'
    | 'ABORT'
    | 'ERROR'
    | 'END'
    | 'BUSY'
    | 'UNKNOWN_SUBSCRIBER'
    | 'BROKEN_LOG'
    | 'IVR'
    | 'VMS'
    | 'VALIDATION'
    | 'FORWARD'
    | 'UNKNOWN'
    | 'DROPPED_VERIFICATION';
  incoming?: boolean;
  callRecording?: CallRecordingResponse;
  callNote?: string;
  callTags?: CallTagResponse[];
}

export interface CallTagResponse {
  tagId?: string;
  tagName?: string;
  tagColor?: string;
  hidden?: boolean;

  /** @format date-time */
  deleted?: string;
}

export interface CountriesForNumbersResponse {
  supported?: CountryCodeRO[];
  popular?: CountryCodeRO[];
  'coming-in-days'?: CountryCodeRO[];
  'coming-in-weeks'?: CountryCodeRO[];
  'coming-in-months'?: CountryCodeRO[];
}

export interface CountryCodeRO {
  code: string;
  phoneCode: string;
  name: string;
  supportedFunctionality?: NumberFunctionality;
  vipAvailable?: boolean;
}

export interface CountriesForDialerResponse {
  supported?: DialerCountryCodeRO[];
}

export interface DialerCountryCodeRO {
  code: string;
  phoneCode: string;
  name: string;
}

export interface GetVoicemailsRequest {
  offset?: string;

  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit: number;
  inclCounter: boolean;
  onlyNotListened: boolean;
}

export interface AllVoicemailsResponse {
  data?: VoicemailData[];

  /** @format int64 */
  totalCount?: number;
}

export interface VoicemailData {
  id?: string;
  userId?: string;
  originalUserId?: string;
  categoryId?: string;
  callingPartyPhone?: string;
  greetingsFlowId?: string;
  greetingsFlowBlockId?: string;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  delivered?: string;

  /** @format date-time */
  listened?: string;

  /** @format int64 */
  audioLength?: number;
  url?: string;
  clir?: boolean;
}

export interface UsagePriceRequest {
  /** @pattern ^([A-Z]){2}$ */
  from: string;

  /** @pattern ^([A-Z]){2}$ */
  to: string;
}

export interface BillingRate {
  status?: 'PAID' | 'FREE';
  type?:
    | 'SMS_MOBILE'
    | 'CALL_MOBILE'
    | 'CALL_LANDLINE'
    | 'SMS_MOBILE_INCOMING'
    | 'CALL_MOBILE_INCOMING'
    | 'CALL_LANDLINE_INCOMING';

  /** @format int32 */
  setupPrice?: number;

  /** @format int32 */
  pricePerUnit?: number;
  unit?: 'MINUTES' | 'NONE';
}

export interface OfferFeature {
  title?: string;
  description?: string;
  detailTitle?: string;
  detailDescription?: string;
  iconUrl?: string;
  animationUrl?: string;
}

export interface UserOfferDetailsResponse {
  features?: OfferFeature[];
}

export interface TestCodeRequest {
  /**
   * @format int32
   * @min 100
   * @max 599
   */
  httpStatus: number;
}

export interface IncomingCallData {
  incomingCallId?: string;
  tsan?: string;
  srcNr?: string;
  srcNiceNr?: string;
  categoryId?: string;
  transactionId?: string;
  callId?: string;
  srcName?: string;
  srcContactId?: string;
  srcCategoryId?: string;
  recording?: boolean;
  transfereeName?: string;
}

export interface ProvisionSipConfigRequest {
  instanceId: string;
  dtls?: boolean;
}

export interface ProvisionSipConfigResponse {
  sipUsername?: string;
  sipSecret?: string;
  sipDomain?: string;
  sipTechDomain?: string;
  wssSrvRecord?: DomainSrvRecord[];
  stunSrvRecord?: DomainSrvRecord[];
}

export interface OutgoingCallRequest {
  calleeNumber: string;
  categoryId: string;
  isVoipCall?: boolean;
  voipCall?: boolean;
}

export interface OutgoingCallResponse {
  tsan?: string;
  transactionId?: string;
  callId?: string;
  categoryId?: string;
  calleeNumber?: string;
  recording?: boolean;
  sipCredentials?: SipCredentials;
  sipConfig?: SipConfigResponse;
}

export interface PortInAvailableDatesRequest {
  phoneNumber?: string;

  /** @pattern ^$|^([A-Z]){2}$ */
  countryIsoCode?: string;
}

export interface PortInAvailableDatesResponse {
  portDates?: string[];
}

export interface CategoryIdRequest {
  categoryId: string;
}

export interface PlanStatisticsRO {
  name?: string;
  virtualPhoneNumber?: string;
  formattedVirtualPhoneNumber?: string;
  planType?:
    | 'SMALL'
    | 'MEDIUM'
    | 'MEDIUM_V2'
    | 'LARGE'
    | 'TRIAL'
    | 'PREMIUM'
    | 'BUSINESS_PLAN_1'
    | 'BUSINESS_PLAN_2'
    | 'BUSINESS_PLAN_3';
  status?: 'SUBSCRIPTION_ACTIVE' | 'SUBSCRIPTION_CANCELLED' | 'PURCHASE_ACTIVE' | 'EXPIRED';
  subscriptionSource?: AppStore;

  /** @format int32 */
  calls?: number;

  /** @format int32 */
  sms?: number;

  /** @format int32 */
  smsUsed?: number;

  /** @format int32 */
  callsUsed?: number;

  /** @format int32 */
  smsUsedPercentage?: number;

  /** @format int32 */
  callsUsedPercentage?: number;

  /** @format date-time */
  expires?: string;

  /** @format date-time */
  resetOn?: string;
  unlimited?: boolean;
  smsFairUsageReached?: boolean;
  showRenewButton?: boolean;
  information?: PlanInformationRO[];
  conditions?: PlanConditionRO[];
  conditionComments?: string[];
}

export interface PriceIncreasePromptInfoResponse {
  promptInfo?: PromptInfo;
}

export interface PromptInfo {
  title: string;
  description: string;
  virtualPhoneNumber: string;
  currentProductId: string;
  purchaseToken: string;
  canSkip: boolean;
  plan: Data;
  planInformation: PlanInformationRO[];
  planConditions: PlanConditionRO[];
  planConditionComments: string[];
  numberUsageNote?: NumberUsageNote;
}

export interface ListNumbersForProductRequest {
  productId: string;

  /**
   * @format int32
   * @min 0
   * @max 2000
   */
  offset: number;

  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit: number;
  newCategoryFlow?: boolean;
}

export interface ListNumbersForCountryRequest {
  /** @pattern ^([A-Z]){2}$ */
  countryIsoCode: string;

  /** @pattern ^([A-Z]){2}$ */
  areaShortCode?: string;
  numberToSearch?: string;
  vip: boolean;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  offset: number;

  /**
   * @format int32
   * @min 1
   * @max 50
   */
  limit: number;
  newCategoryFlow?: boolean;
}

export interface ListInitialNumbersRequest {
  /**
   * @format int32
   * @min 0
   * @max 10
   */
  nationalNumberCount: number;

  /**
   * @format int32
   * @min 0
   * @max 10
   */
  internationalNumberCount: number;
  newCategoryFlow?: boolean;
}

export interface ListInitialNumbersRO {
  nationalNumbers?: NumberRO[];
  internationalNumbers?: NumberRO[];
}

export interface ListCategoriesForProductRequest {
  productId: string;
}

export interface NumberUsageNoteResponse {
  numberUsageNote?: NumberUsageNote;
}

export interface OnOffCallSimValidationStatusResponse {
  countryIsoCode?: string;
  simNumber?: string;
  message?: string;
  status?: string;
}

export interface OnOffCallSimNumberResponse {
  countryIsoCode?: string;
  simNumber?: string;
}

export interface ListStatesRequest {
  countryIsoCode: string;
}

export interface StateCode {
  code?: string;
  name?: string;
  phoneCodes?: string[];
}

export interface StateDetailsResponse {
  enabledStates?: StateCode[];
  disabledStates?: StateCode[];
}

export interface ListCountriesForBillingRequest {
  /** @pattern ^([A-Z]){2}$ */
  countryIsoCodeFrom: string;
}

export interface DestinationSupportedCountriesResponse {
  supported?: CountryCodeRO[];
}

export interface KycSuccessResponse {
  kycSuccessMessage?: string;
}

export interface IconDescription {
  iconUrl?: string;
  text?: string;
}

export interface KycCategoryStatus {
  categoryId?: string;
  nextStep?: string;
}

export interface KycCountriesToValidate {
  categoryId?: string;
  countryIso?: string;
  nextStep?: string;
}

export interface KycStatusResponse {
  kycRequired?: boolean;
  description?: string;
  descriptions?: IconDescription[];
  categories?: KycCategoryStatus[];
  countriesToValidate?: KycCountriesToValidate[];
}

export interface SimNumberValidationResponse {
  status?: 'SUCCESS' | 'PENDING' | 'HIDDEN_NUMBER' | 'NUMBER_ALREADY_USED' | 'NOT_MOBILE' | 'ERROR';
  message?: string;
  nextStep?:
    | 'SIM_NUMBER_VALIDATION'
    | 'PERSONAL_DETAILS'
    | 'ADDRESS_VALIDATION'
    | 'GEO_VALIDATION_OF_ADDRESS'
    | 'DOCUMENT_VALIDATION'
    | 'MANUAL_VALIDATION'
    | 'PROOF_OF_ADDRESS'
    | 'PROOF_OF_ADDRESS_WAITING_DECISION'
    | 'KYB_VALIDATION_REQUIRED'
    | 'FINISH_STEP';
}

export interface KycVeriffSessionResponse {
  veriffSessionURL?: string;
}

export interface KycPersonalDetailsResponse {
  countrySelectorData?: CountryInfo[];
  kycPersonalDetailsInfoMessage?: string;
  requiredPersonalDetails?: InputField[];
}

export interface KycPersonalAddressInfoResponse {
  infoMessage?: string;
  countrySelectorData?: CountryInfo[];
  requiredPersonalAddressDetails?: InputField[];
  validationType?: 'WORLDWIDE' | 'COUNTRY' | 'CONFIRMED';
}

export interface IsPasswordRecoveryTokenOkRequest {
  token?: string;
}

export interface IsPasswordRecoveryTokenOkResponse {
  status?: string;
}

export interface IsDarkThemeResponse {
  darkTheme?: boolean;
}

export interface VoipCallAllowedRequest {
  destinationCategoryId: string;
}

export interface VoipFeedbackProblemResponse {
  /** @format int32 */
  problemId?: number;
  problemText?: string;
  audio?: boolean;
  video?: boolean;
}

export interface DefaultVoicemailGreeting {
  greetingUrl?: string;
  locale?: string;
}

export interface UserSettings {
  greetingUrl?: string;
  userRecordedGreeting?: boolean;
  defaultGreetingLocale?: string;
}

export interface VoicemailGreetingSettingsInfoResponse {
  defaultVoicemailGreetings?: DefaultVoicemailGreeting[];
  userSettings?: UserSettings;
}

export interface CallRecordingSettings {
  enabled?: boolean;
  acknowledgmentNeeded?: boolean;
}

export interface UserFeaturesResponse {
  webAppSmsInEnabled?: boolean;
  webAppCallInEnabled?: boolean;
  webAppSmsOutEnabled?: boolean;
  webAppCallOutEnabled?: boolean;
  multiDeviceEnabled?: boolean;
  onoffCallsEnabled?: boolean;
  voipOutEnabled?: boolean;
  categorySchedulingEnabled?: boolean;

  /** @format int32 */
  distinctRecipients?: number;
  greetingMobileAppEnabled?: boolean;
}

export interface UserResponse {
  userId: string;
  firstName?: string;
  lastName?: string;
  email: string;
  hasPassword: boolean;
  callAnswerMethod: string;
  callOutMethod: string;
  callQualitySurvey?: boolean;
  enableOfflineCalls?: boolean;
  onOffCallStatus: 'ENABLED' | 'DISABLED' | 'ALLOCATING_TSANS' | 'DEALLOCATING_TSANS';
  offersUnsubscribe?: boolean;
  countryIsoCode?: string;
  nationalCountryIsoCode: string;
  validCelebrity: boolean;
  advertisingId?: string;
  homePhoneBookDeviceToken?: string;
  phoneBookName?: string;
  sortOrder?: string;
  displayOrder?: string;
  syncContacts?: boolean;
  supportPhone?: string;
  b2bUser: boolean;
  submitReceiptOnLogin?: boolean;
  communicationLocale: string;
  pubnubEnabled?: boolean;
  emailVerified?: boolean;

  /** @format int64 */
  activeCategoriesCount?: number;
  premiumPurchaseAvailable?: boolean;
  showEmergencyCallsWarning?: boolean;

  /** Account Type of the user */
  accountType: AccountType;
  features: UserFeaturesResponse;
  callRecording: CallRecordingSettings;
}

export interface UserBalanceShort {
  totalCredits?: string;
  expirationDate?: string;
}

export interface SupportedCommunicationLocalesResponse {
  locales?: string[];
}

export interface GetPresignedUrlForDeviceLogsUploadResponse {
  presignedUrl?: string;
  fileName?: string;
}

export interface NextAppRateDateResponse {
  /** @format date-time */
  nextAppRateDate?: string;
}

export interface CreditAppProductIdResponse {
  products?: string[];
}

export interface GetCallForwardingResponse {
  forwardMode?: 'ALL_CALLS' | 'MISSED_CALLS';
  enabled?: boolean;
  name?: string;
  phoneNumber?: string;
}

export interface GetCustomerAttachmentPresignedUrlRequest {
  /**
   * @format int64
   * @max 536870912
   */
  contentSize: number;
  contentType: string;
}

export interface UserDeviceElement {
  deviceId?: string;
  name?: string;
  hardware?: string;
  os?: string;

  /** @format date-time */
  lastLogin?: string;
  loggedIn?: boolean;
  timeZone?: string;
}

export interface UserDevicesResponse {
  devices?: UserDeviceElement[];
}

export interface B2BCallTagGetRequest {
  callLogId?: string;
}

export interface B2BCallTagGetResponse {
  callLogId?: string;
  callTags?: CallTag[];
}

export interface CallTag {
  tagId?: string;
  tagName?: string;
  tagColor?: string;
}

export interface CallQualitySurveyStartResponse {
  transactionId?: string;
  secondPartyPhone?: string;
  reasons?: Reason[];
}

export interface Reason {
  key?: string;
  value?: string;
}

export interface UserOfferInfoResponse {
  subscriptionId?: string;
  status?: SubscriptionStatus;
  source?: AppStore;

  /** @format date-time */
  expirationDate?: string;

  /** @format int32 */
  planDuration?: number;

  /** @format int32 */
  nextPlanDuration?: number;
}

export enum AppProductType {
  NUMBER = 'NUMBER',
  CREDITS = 'CREDITS',
  PREMIUM = 'PREMIUM',
  COMBINED = 'COMBINED',
  PACKAGE = 'PACKAGE',
}

export interface UserOfferAvailableResponse {
  offers?: UserOfferInfo[];
}

export interface UserOfferInfo {
  offerProductId?: string;

  /** @format int32 */
  month?: number;
  autoRenewable?: boolean;
  appProductType?: AppProductType;
  trialAvailable?: boolean;
}

export interface EmailValidationEnabledResponse {
  isEnabled?: boolean;
}

export interface ColorName {
  name?: ColorType;
  color?: string;
}

export interface ColorNamesResponse {
  colorNames?: ColorName[];
  colorPicker?: ColorType[];
}

export interface HealthResponse {
  status?: string;
}

export interface VpnGetSystemRequest {
  phoneNumber: string;
}

export interface VpnGetSystemResponse {
  systemId?: string;
  used?: boolean;
  deviceType?: 'ANDROID' | 'IOS' | 'WEB' | 'CHROMEAPP' | 'MSTEAMS';
}

export interface DataToProcessExpiredGracePeriod {
  countries?: string[];

  /** @format date-time */
  currentDate?: string;
  categories?: string[];
}

export interface CndbBelgiumRecord {
  recordType?: string;
  transactionType?: 'UPDATE' | 'DISCONNECT';
  telcoOrderId?: string;
  customerFirstName?: string;
  customerLastName?: string;
  customerSuffix?: string;
  customerVatNumber?: string;
  customerTitle?: string;
  customerAliasNameType?: string;
  customerAliasTitle?: string;
  customerAliasFirstName?: string;
  customerAliasLastName?: string;
  customerLanguage?: string;
  customerCountryCode?: string;
  customerPhoneNumber?: string;
  listingUsoWpStatusFlag?: string;
  listingOtherMediaStatusFlag?: string;
  listingUsoDaStatusFlag?: string;
  listingSalesFlag?: string;
  listingReverseQueryFlag?: string;
  listingServiceType?: string;
  listingDeviceTypeId?: string;
  listingLanguage?: string;
  listingTelcoId?: string;
  listingTelcoCustomerId?: string;
  listingNacebelCode1?: string;
  listingNacebelCode2?: string;
  installationStreetName?: string;
  installationHouseNumber?: string;
  installationHouseNumberLetter?: string;
  installationHouseExtension?: string;
  installationFlatNumber?: string;
  installationZipCode?: string;
  installationLocalityName?: string;
  listingStreetName?: string;
  listingHouseNumber?: string;
  listingHouseNumberLetter?: string;
  listingHouseExtension?: string;
  listingFlatNumber?: string;
  listingZipCode?: string;
  listingLocalityName?: string;
  opid?: string;
  spid?: string;
}

export interface GetCndbBelgiumRecordResponse {
  cndbBelgiumRecordList?: CndbBelgiumRecord[];
}

export interface B2BSubscriptionsForRenewResponse {
  subscriptionIds?: string[];
}

export interface GetUserDataRequest {
  userId: string;
}

export interface GetUserDataResponse {
  userData?: UserData;
}

export interface UserOffer {
  userId?: string;
  subscriptionId?: string;
  status?: SubscriptionStatus;
  appStore?: AppStore;
  autoRenewableType?: 'PLAN' | 'VOIP_OUT' | 'PREMIUM';

  /** @format date-time */
  subscriptionExpirationDate?: string;
}

export interface UserOfferResponse {
  userOffer?: UserOffer;
}

export interface ProductMonthsResponse {
  /** @format int32 */
  months?: number;
}

export interface PriceInfoResponse {
  currency?: string;
  value?: number;
}

export interface Category {
  userId?: string;
  categoryId?: string;
  virtualPhoneNumber?: string;

  /** @format date-time */
  categoryExpirationDate?: string;
  subscriptionId?: string;
  status?: SubscriptionStatus;
  appStore?: AppStore;

  /** @format date-time */
  subscriptionExpirationDate?: string;
}

export interface UserCategoriesResponse {
  categories?: Category[];
}

export interface AutoRenewableProductResponse {
  productIds?: string[];
}

export interface OnOffCallCountryIsoCodeResponse {
  enabled?: boolean;
  countryIsoCode?: string;
}

export interface IsOnoffCallsEnabledResponse {
  enabled?: boolean;
}

export interface MessageDeliveryInfo {
  /** @format int64 */
  created?: number;
  body?: string;
}

export interface PushDevice {
  deviceId?: string;
  appVersion?: string;
  deviceType?: 'ANDROID' | 'IOS' | 'WEB' | 'CHROMEAPP' | 'MSTEAMS';
  deviceToken?: string;
  deviceTokenSpecial?: string;
}

export interface PushDevicesResponse {
  pushDevices?: PushDevice[];
}

export interface PhoneNumberRequest {
  number?: string;
}

export interface GreetingsUserDataResponse {
  userId?: string;
  categoryId?: string;
  forwardNumber?: string;
}

export interface LibPhoneData {
  valid?: boolean;
  countryIsoCode?: string;
  country?: string;
  numberType?: string;
  nationalFormat?: string;
  internationalFormat?: string;
  isPortingAllowedForCountry?: boolean;
  geoDescription?: string;
  originalCarrier?: string;
}

export interface NumberLookupResponse {
  twilioData?: TwilioData;
  libPhoneData?: LibPhoneData;
}

export interface TwilioData {
  countryIsoCode?: string;
  phoneNumber?: string;
  nationalFormat?: string;
  numberType?: string;
  mcc?: string;
  mnc?: string;
  carrierName?: string;
}

export interface GreetingsUserData {
  userId?: string;
  categoryId?: string;
  forwardNumber?: string;
}

export interface GreetingsUserDataListResponse {
  userDataList?: GreetingsUserData[];
}

export interface GetB2BUserGreetingsDataRequest {
  organizationId: string;
  userId: string;
  categoryId?: string;
}

export interface ActiveCategoryWithNameResponse {
  categoryNameList?: CategoryNameData[];
}

export interface CategoryNameData {
  categoryId?: string;
  categoryName?: string;
}

export interface B2BPlanCountryRequest {
  /** @pattern ^([A-Z]){2}$ */
  countryIsoCode: string;
  locale: string;
  national: boolean;
}

export interface B2BPlanConditionRO {
  iconUrl?: string;
  text?: string;
  link?: string;
}

export interface B2BPlanCountryRO {
  information?: B2BPlanInformationRO[];
  conditions?: B2BPlanConditionRO[];
  conditionComments?: string[];
  plans?: Data[];
}

export interface B2BPlanInformationRO {
  title?: string;
  description?: string;
  iconUrl?: string;
}

export interface B2BGetReservedNumbersRequest {
  organizationId: string;
}

export interface B2BGetReservedNumbersResponse {
  phoneNumber?: string;

  /** @format date-time */
  expirationDate?: string;
}

export interface B2BGetQuarantineNumbersRequest {
  organizationId: string;
}

export interface B2BGetQuarantineNumbersResponse {
  numbers?: B2BQuarantineNumber[];
}

export interface B2BQuarantineNumber {
  number?: string;
  countryIsoCode?: string;

  /** @format date-time */
  expirationDate?: string;
}

export interface B2BGetNumbersRequest {
  /** @pattern ^([A-Z]){2}$ */
  countryIsoCode?: string;
  region?: string;

  /**
   * @format int32
   * @max 40000
   */
  limit?: number;
  type?: 'REGULAR' | 'ELITE';
}

export interface B2BCountriesRO {
  countries?: string[];
}

export interface BillingHocsB2BGetCreditsHistoryRequest {
  organizationId: string;
  lastId?: string;

  /** @format int32 */
  size?: number;
}

export interface B2BGlobalCredits {
  id?: string;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  updated?: string;

  /** @format date-time */
  datetime?: string;
  action?: string;
  quantity?: number;
  userId?: string;
  organizationId?: string;
  description?: string;
  callerNumber?: string;
  calleeNumber?: string;
  callType?: string;

  /** @format int32 */
  duration?: number;
}

export interface B2BGetGlobalCreditsAmountRequest {
  organizationId: string;
}

export interface B2BGetGlobalCreditsAmountResponse {
  /** @format int32 */
  balance?: number;
}

export interface B2BGetUsersRequest {
  organizationId: string;
  employeeId?: string;
}

export interface B2BUsersRO {
  users?: OrganizationEmployee[];
}

export interface OrganizationEmployee {
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  organizationId?: string;
  employeeId?: string;

  /** Account Type of the user */
  accountType?: AccountType;
}

export interface B2BNumberAmountRequest {
  /** @pattern ^([A-Z]){2}$ */
  countryIsoCode: string;

  /**
   * @format int32
   * @min 1
   * @max 400
   */
  amountNeeded: number;
}

export interface B2BNumbersAvailableRO {
  /** @format int32 */
  availableAmount?: number;
}

export interface VoipCallRouteRequest {
  callerSipUri: string;
  callerInstanceId: string;
  calleeSipUri: string;
  callerDisplayName?: string;
}

export interface VoipCallRouteV2Response {
  callAllowed?: boolean;
  billingId?: string;
  mediaStackId?: string[];

  /** @format int32 */
  callSetupTimeout?: number;

  /** @format int32 */
  fallbackTimeout?: number;
  primaryUri?: string;
  secondaryUri?: string;
  announcement?: string;
  vmaction?: 'CALLBACK' | 'VMS' | 'BUSY' | 'IVR' | 'CONNECT' | 'FORWARD' | 'CALL' | 'DROP';
  callerUri?: string;
  callerIdNumber?: string;
  ringback?: string;
  sessionId?: string;
  voipout?: boolean;
  onoffDirection?:
    | 'MO'
    | 'MT_A'
    | 'MT_B'
    | 'VOIP'
    | 'IVR'
    | 'VALIDATION_CALL'
    | 'GREETINGS'
    | 'GREETINGS_MO'
    | 'GREETINGS_VALIDATION_CALL_V1'
    | 'MO_VOIP'
    | 'MO_VOIP_PORT'
    | 'MT_OFFLINE'
    | 'MO_OFFLINE';
  clir?: boolean;
}

export interface SoftswitchVoipCallRouteRequest {
  callerSipUri: string;
  calleeSipUri: string;
  uuid: string;
}

export interface VoipAuthenticationRequest {
  sipUri: string;
  instanceId: string;
  callId?: string;
}

export interface VoipAuthenticationResponse {
  sipUsername?: string;
  sipSecret?: string;
}

export interface AdminPortabilityStatusResponse {
  statuses?: StatusDetails[];
}

export interface StatusDetails {
  id?: string;
  userId?: string;
  phoneNumber?: string;
  startDate?: string;
  portDate?: string;
  portabilityCode?: string;
  updated?: string;
  status?: string;
  confirmed?: boolean;
  confirmAllowed?: boolean;
  cancelAllowed?: boolean;
  canceller?: string;
}

export interface PortabilityRecord {
  id?: string;
  created?: string;
  updated?: string;
  referenceId?: string;
  phoneNumber?: string;
  transactionId?: string;
  status?: string;
  statusInfo?: string;
  portDate?: string;
  systemId?: string;
  portabilityCode?: string;
  deviceType?: string;
  telecomTransactionId?: string;
  canceller?: string;
}

export interface PortabilityRecordResponse {
  records?: PortabilityRecord[];
}

export interface GetPortabilityCodeRequest {
  phoneNumber: string;
  sendSms: boolean;
}

export interface GetPortabilityCodeResponse {
  portabilityCode?: string;
  result: string;
}

export interface GetAutoRenewableDebugDataRequest {
  categoryId: string;
}

export interface GetAutoRenewableDebugDataResponse {
  result?: Record<string, object>;
}

export interface BytelStatusResponse {
  email?: string;
  status?: 'CREATED' | 'ACTIVE' | 'DELETED';

  /** @format date-time */
  deleted?: string;

  /** @format date-time */
  previousResetDate?: string;
  number?: string;
  offer?: string;
}

export interface B2BCallTagDefinition {
  id?: string;

  /** @format date-time */
  created?: string;

  /** @format date-time */
  updated?: string;
  organizationId?: string;
  creatorUserId?: string;
  tagName?: string;
  tagColor?: string;
  hidden?: boolean;

  /** @format date-time */
  deleted?: string;
}

export interface B2BOrganizationGetCallTagsResponse {
  callTags?: B2BCallTagDefinition[];
}

export interface B2BOrganizationCallTagsCountResponse {
  /** @format int32 */
  count?: number;
}

export interface B2BCallRecordingStatusRO {
  callRecording?: boolean;
}

export interface CallRecordingBulkDownloadResponse {
  jobId?: string;
  status?: 'RECEIVED' | 'IN_PROGRESS' | 'DONE' | 'ERROR';
  error?: ErrorResponse;
  presignedUrl?: string;

  /** @format date-time */
  expiresAt?: string;
}

export interface CallRecordingBulkDownloadsResponse {
  jobs?: CallRecordingBulkDownloadResponse[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'http://onoff-main.onoff-main.svc.cluster.local.:8080';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Main Server-Side
 * @license ONOFFAPP OÜ (https://onoff.app)
 * @baseUrl http://onoff-main.onoff-main.svc.cluster.local.:8080
 * @contact Server side team <server@onoffapp.com> (https://staging-backend-lb.onoffapp.net/)
 *
 * The Main Server-Side, the one and only
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags health-controller
   * @name TestIncapsulaMonitoring
   * @request GET:/
   */
  testIncapsulaMonitoring = (params: RequestParams = {}) =>
    this.request<EmptyResponse, string>({
      path: `/`,
      method: 'GET',
      ...params,
    });

  mobile = {
    /**
     * No description
     *
     * @tags user-controller
     * @name Logout
     * @request GET:/mobile/logout
     */
    logout: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/logout`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name Logout1
     * @request POST:/mobile/logout
     */
    logout1: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/logout`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name GetGroupThreadId
     * @request POST:/mobile/v5/get-thread-id
     */
    getGroupThreadId: (data: GetGroupThreadIdRequest, params: RequestParams = {}) =>
      this.request<GetThreadIdResponse, string>({
        path: `/mobile/v5/get-thread-id`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name SendMessage
     * @request POST:/mobile/v4/send-message
     */
    sendMessage: (data: SendMessageRequest, params: RequestParams = {}) =>
      this.request<SendMessageResponse, string>({
        path: `/mobile/v4/send-message`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name SendDelayedMessageImmediately
     * @request POST:/mobile/v4/send-delayed-messages-immediately
     */
    sendDelayedMessageImmediately: (data: StringCollectionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/v4/send-delayed-messages-immediately`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name RetrieveThreadParticipantsByThreads
     * @request POST:/mobile/v4/retrieve-thread-participants-by-threads
     */
    retrieveThreadParticipantsByThreads: (data: StringCollectionRequest, params: RequestParams = {}) =>
      this.request<MessageThreadParticipantData[], string>({
        path: `/mobile/v4/retrieve-thread-participants-by-threads`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name MarkAsUnreadMessages
     * @request POST:/mobile/v4/mark-as-unread-messages
     * @deprecated
     */
    markAsUnreadMessages: (data: StringCollectionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/v4/mark-as-unread-messages`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name MarkAsReadThreads
     * @request POST:/mobile/v4/mark-as-read-threads
     */
    markAsReadThreads: (data: StringCollectionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/v4/mark-as-read-threads`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name GetThreadId
     * @request POST:/mobile/v4/get-thread-id
     * @deprecated
     */
    getThreadId: (data: GetThreadIdRequest, params: RequestParams = {}) =>
      this.request<GetThreadIdResponse, string>({
        path: `/mobile/v4/get-thread-id`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name DeleteMessages
     * @request POST:/mobile/v4/delete-messages
     */
    deleteMessages: (data: StringCollectionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/v4/delete-messages`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name DeleteThreads
     * @request POST:/mobile/v4/delete-message-threads
     */
    deleteThreads: (data: StringCollectionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/v4/delete-message-threads`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags customer-support-controller
     * @name LeaveFeedback
     * @request POST:/mobile/v2/leave-feedback
     */
    leaveFeedback: (data: CustomerSupportRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/v2/leave-feedback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name IncomingCallStatusV2
     * @request POST:/mobile/v2/incoming-call-status
     */
    incomingCallStatusV2: (data: IncomingCallStatusRequestV2, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/v2/incoming-call-status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CheckEmailV2
     * @request POST:/mobile/v2/check-email
     */
    checkEmailV2: (data: CheckEmailRequest, params: RequestParams = {}) =>
      this.request<CheckEmailResponse, string>({
        path: `/mobile/v2/check-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voicemail-controller
     * @name UploadVoicemailGreeting
     * @request POST:/mobile/upload-voicemail-greeting
     */
    uploadVoicemailGreeting: (data: UploadVoicemailGreetingRequest, params: RequestParams = {}) =>
      this.request<VoicemailUrlResponse, string>({
        path: `/mobile/upload-voicemail-greeting`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateTimezone
     * @request POST:/mobile/update-timezone
     */
    updateTimezone: (data: UpdateTimeZoneRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-timezone`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-device-controller
     * @name UpdateSimCardDetails
     * @request POST:/mobile/update-simcard-details
     */
    updateSimCardDetails: (data: UpdateSimCardDetailsRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-simcard-details`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name UpdateSmsForCredits
     * @request POST:/mobile/update-send-sms-for-credits
     */
    updateSmsForCredits: (data: UpdateSmsForCreditsRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-send-sms-for-credits`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdatePushToken
     * @request POST:/mobile/update-push-token
     */
    updatePushToken: (data: UpdatePushTokenRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-push-token`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdatePubnubSettings
     * @request POST:/mobile/update-pubnub-settings
     */
    updatePubnubSettings: (data: UpdatePubnubSettingsRequest, params: RequestParams = {}) =>
      this.request<UpdatePubnubSettingsResponse, string>({
        path: `/mobile/update-pubnub-settings`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateOffersUnsubscribe
     * @request POST:/mobile/update-offers-unsubscribe
     */
    updateOffersUnsubscribe: (data: UpdateOffersUnsubscribeRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-offers-unsubscribe`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateNextAppRateDate
     * @request POST:/mobile/update-next-app-rate-date
     */
    updateNextAppRateDate: (data: UpdateNextAppRateDateRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-next-app-rate-date`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-device-controller
     * @name UpdateLocale
     * @request POST:/mobile/update-locale
     */
    updateLocale: (data: UpdateLocaleRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-locale`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-device-controller
     * @name UpdateStatusOfDeviceLogsUpload
     * @request POST:/mobile/update-device-logs-upload-status
     */
    updateStatusOfDeviceLogsUpload: (data: UpdateDeviceLogsUploadStatusRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-device-logs-upload-status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateDefaultCallOutMethod
     * @request POST:/mobile/update-default-call-out-method
     */
    updateDefaultCallOutMethod: (data: UpdateCallOutMethodRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-default-call-out-method`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateDefaultCallAnswerMethod
     * @request POST:/mobile/update-default-call-answer-method
     */
    updateDefaultCallAnswerMethod: (data: UpdateCallAnswerMethodRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-default-call-answer-method`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateCommunicationLocale
     * @request POST:/mobile/update-communication-locale
     */
    updateCommunicationLocale: (data: UpdateLocaleRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-communication-locale`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name UpdateCategory
     * @request POST:/mobile/update-category
     */
    updateCategory: (data: UpdateCategoryRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-category`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name UpdateDefaultVoicemailLanguage
     * @request POST:/mobile/update-category-locale
     */
    updateDefaultVoicemailLanguage: (data: UpdateCategoryLocaleRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-category-locale`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name UpdateCategoryCore
     * @request POST:/mobile/update-category-core
     */
    updateCategoryCore: (data: CategoryCoreUpdateRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-category-core`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateCallQualitySurvey
     * @request POST:/mobile/update-call-quality-survey
     */
    updateCallQualitySurvey: (data: UpdateCallQualitySurveyRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-call-quality-survey`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-device-controller
     * @name UpdateAppVersion
     * @request POST:/mobile/update-app-version
     */
    updateAppVersion: (data: UpdateAppVersionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-app-version`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateAdvertisingId
     * @request POST:/mobile/update-advertising-id
     * @deprecated
     */
    updateAdvertisingId: (data: UpdateAdvertisingIdRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/update-advertising-id`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name SwitchMultipleCategoriesVisibility
     * @request POST:/mobile/switch-multiple-categories-visibility
     */
    switchMultipleCategoriesVisibility: (data: CategoriesVisibilitySwitchRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/switch-multiple-categories-visibility`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name SwitchCategoryVisibility
     * @request POST:/mobile/switch-category-visibility
     */
    switchCategoryVisibility: (data: CategoryVisibilitySwitchRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/switch-category-visibility`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name SwitchCategoryStatus
     * @request POST:/mobile/switch-category-status
     */
    switchCategoryStatus: (data: CategoryStatusSwitchRequest, params: RequestParams = {}) =>
      this.request<CategoryDataV3Impl, string>({
        path: `/mobile/switch-category-status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name SwitchCategoryPrivacy
     * @request POST:/mobile/switch-category-privacy
     */
    switchCategoryPrivacy: (data: CategoryPrivacySwitchRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/switch-category-privacy`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-forwarding-controller
     * @name PrepareCallForSimNumberValidation
     * @request POST:/mobile/set-call-forwarding
     */
    prepareCallForSimNumberValidation: (data: SetCallForwardingRequest, params: RequestParams = {}) =>
      this.request<SetCallForwardingResponse, string>({
        path: `/mobile/set-call-forwarding`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voip-controller
     * @name SendVoipFeedback
     * @request POST:/mobile/send-voip-feedback
     * @deprecated
     */
    sendVoipFeedback: (data: VoipSendFeedbackRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/send-voip-feedback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags simple-contact-controller
     * @name SaveSimpleContactImage
     * @request POST:/mobile/save-simple-contact-image
     * @deprecated
     */
    saveSimpleContactImage: (data: SaveSimpleContactImageRequest, params: RequestParams = {}) =>
      this.request<SimpleContactImageResponse, string>({
        path: `/mobile/save-simple-contact-image`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-logs-controller
     * @name SaveCallNote
     * @request POST:/mobile/save-call-note
     */
    saveCallNote: (data: SaveCallNoteRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/save-call-note`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voicemail-controller
     * @name RestoreDefaultVoicemailGreeting
     * @request POST:/mobile/restore-default-voicemail-greeting
     */
    restoreDefaultVoicemailGreeting: (data: RestoreDefaultVoicemailGreetingRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/restore-default-voicemail-greeting`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags portability-mobile-controller
     * @name PortInStart
     * @request POST:/mobile/port-in-start
     */
    portInStart: (data: PortInStartRequest, params: RequestParams = {}) =>
      this.request<PortInStatusResponse, string>({
        path: `/mobile/port-in-start`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags portability-mobile-controller
     * @name PortInCancel
     * @request POST:/mobile/port-in-cancel
     */
    portInCancel: (data: PortInCancelRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/port-in-cancel`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name Statistics
     * @request POST:/mobile/plans/v2/statistics
     */
    statistics: (data: PlansStatisticsRequest, params: RequestParams = {}) =>
      this.request<PlanStatisticsResponse, string>({
        path: `/mobile/plans/v2/statistics`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name PlansAvailableV2
     * @request POST:/mobile/plans/v2/available
     */
    plansAvailableV2: (data: PlansAvailableRequest, params: RequestParams = {}) =>
      this.request<PlansAvailableResponseV2, string>({
        path: `/mobile/plans/v2/available`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags price-increase-prompt-controller
     * @name PostPriceIncreasePromptFeedback
     * @request POST:/mobile/plans/price-increase-prompt-feedback
     */
    postPriceIncreasePromptFeedback: (data: PriceIncreasePromptFeedbackRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/plans/price-increase-prompt-feedback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags price-increase-prompt-controller
     * @name PriceIncreaseAccepted
     * @request POST:/mobile/plans/price-increase-accepted
     */
    priceIncreaseAccepted: (data: PriceIncreaseAcceptedRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/plans/price-increase-accepted`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name BuyPlan
     * @request POST:/mobile/plans/buy
     */
    buyPlan: (data: BuyPlanRequest, params: RequestParams = {}) =>
      this.request<BuyPlanRO, string>({
        path: `/mobile/plans/buy`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name AvailablePlans
     * @request GET:/mobile/plans/available
     */
    availablePlans: (query: { request: PlansAvailableRequest }, params: RequestParams = {}) =>
      this.request<PlansAvailableRO, string>({
        path: `/mobile/plans/available`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name AvailablePlansPost
     * @request POST:/mobile/plans/available
     */
    availablePlansPost: (data: PlansAvailableRequest, params: RequestParams = {}) =>
      this.request<PlansAvailableRO, string>({
        path: `/mobile/plans/available`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name MakePasswordRecovery
     * @request POST:/mobile/password-recovery
     */
    makePasswordRecovery: (data: PasswordRecoveryRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/password-recovery`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name OutgoingCallStatus
     * @request POST:/mobile/outgoing-call-status
     */
    outgoingCallStatus: (data: OutgoingCallStatusRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/outgoing-call-status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-off-calls-controller
     * @name UpdateSettingsOfflineCalls
     * @request POST:/mobile/onoffcall/update-settings
     */
    updateSettingsOfflineCalls: (data: OnOffCallUpdateRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/onoffcall/update-settings`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-off-calls-controller
     * @name PrepareCallForSimNumberValidation1
     * @request POST:/mobile/onoffcall/sim-validation/prepare-call
     */
    prepareCallForSimNumberValidation1: (data: SimNumberValidationPrepareCallRequest, params: RequestParams = {}) =>
      this.request<RegisterValidationCallResponse, string>({
        path: `/mobile/onoffcall/sim-validation/prepare-call`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-controller
     * @name Register
     * @request POST:/mobile/onboarding/register
     */
    register: (data: OnBoardingRegisterRequest, params: RequestParams = {}) =>
      this.request<OnBoardingRegisterResponse, string>({
        path: `/mobile/onboarding/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-controller
     * @name Login
     * @request POST:/mobile/onboarding/login
     */
    login: (data: OnBoardingLoginRequest, params: RequestParams = {}) =>
      this.request<OnBoardingRegisterResponse, string>({
        path: `/mobile/onboarding/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-controller
     * @name VerifyIos
     * @request POST:/mobile/onboarding/ios/verify
     */
    verifyIos: (data: OnBoardingCheckReceiptIOSRequest, params: RequestParams = {}) =>
      this.request<OnBoardingCheckReceiptResponse, string>({
        path: `/mobile/onboarding/ios/verify`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-controller
     * @name VerifyAndroid
     * @request POST:/mobile/onboarding/android/verify
     */
    verifyAndroid: (data: OnBoardingCheckReceiptAndroidRequest, params: RequestParams = {}) =>
      this.request<OnBoardingCheckReceiptResponse, string>({
        path: `/mobile/onboarding/android/verify`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags number-validation-controller
     * @name ValidationCodeSendV3
     * @request POST:/mobile/number-validation/send-code
     */
    validationCodeSendV3: (data: SendValidationCodeRequest, params: RequestParams = {}) =>
      this.request<SendValidationCodeResponseV3, string>({
        path: `/mobile/number-validation/send-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags number-validation-controller
     * @name NumberValidationCodeCheck
     * @request POST:/mobile/number-validation/check-code
     */
    numberValidationCodeCheck: (data: CheckValidationCodeRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/number-validation/check-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voicemail-controller
     * @name MarkVoicemailsAsListened
     * @request POST:/mobile/mark-voicemails-as-listened
     */
    markVoicemailsAsListened: (data: StringCollectionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/mark-voicemails-as-listened`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-logs-controller
     * @name MarkCallLogsSeen
     * @request POST:/mobile/mark-call-logs-seen
     */
    markCallLogsSeen: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/mark-call-logs-seen`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name PrepareCallForSimNumberValidation2
     * @request POST:/mobile/kyc/sim-number-validation/prepare-call
     */
    prepareCallForSimNumberValidation2: (data: SimNumberValidationPrepareCallRequest, params: RequestParams = {}) =>
      this.request<RegisterValidationCallResponse, string>({
        path: `/mobile/kyc/sim-number-validation/prepare-call`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name SavePersonalDocumentInfo
     * @request POST:/mobile/kyc/personal-document/save
     */
    savePersonalDocumentInfo: (data: KycPersonalDocumentRequest, params: RequestParams = {}) =>
      this.request<KycPersonalAddressResponse, string>({
        path: `/mobile/kyc/personal-document/save`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name PersonalDocumentSaveAndGetNextStepInfo
     * @request POST:/mobile/kyc/personal-document/next-save-info
     */
    personalDocumentSaveAndGetNextStepInfo: (data: KycPersonalDocumentRequest, params: RequestParams = {}) =>
      this.request<KycPersonalDocumentInfoResponse, string>({
        path: `/mobile/kyc/personal-document/next-save-info`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name SavePersonalDetails
     * @request POST:/mobile/kyc/personal-details/save
     */
    savePersonalDetails: (data: PersonalDetailsSaveRequest, params: RequestParams = {}) =>
      this.request<KycPersonalDetailsSaveResponse, string>({
        path: `/mobile/kyc/personal-details/save`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name SavePersonalAddress
     * @request POST:/mobile/kyc/personal-address/save
     */
    savePersonalAddress: (data: KycPersonalAddressRequest, params: RequestParams = {}) =>
      this.request<KycPersonalAddressResponse, string>({
        path: `/mobile/kyc/personal-address/save`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name AddressStreetAutocomplete
     * @request POST:/mobile/kyc/address-autocomplete-street
     */
    addressStreetAutocomplete: (data: AddressAutocompleteRequest, params: RequestParams = {}) =>
      this.request<AddressAutocompleteResponse, string>({
        path: `/mobile/kyc/address-autocomplete-street`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name AddressCityAutocomplete
     * @request POST:/mobile/kyc/address-autocomplete-city
     */
    addressCityAutocomplete: (data: AddressAutocompleteRequest, params: RequestParams = {}) =>
      this.request<AddressAutocompleteResponse, string>({
        path: `/mobile/kyc/address-autocomplete-city`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name InitiateCallTransfer
     * @request POST:/mobile/initiate-call-transfer
     */
    initiateCallTransfer: (data: InitiateCallTransferRequest, params: RequestParams = {}) =>
      this.request<InitiateCallTransferResponse, string>({
        path: `/mobile/initiate-call-transfer`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-device-controller
     * @name UpdateData
     * @request POST:/mobile/devices/update-data
     */
    updateData: (data: DeviceDataRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/devices/update-data`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-device-controller
     * @name LogoutDevices
     * @request POST:/mobile/devices/logout
     */
    logoutDevices: (data: StringCollectionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/devices/logout`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voicemail-controller
     * @name DeleteVoicemails
     * @request POST:/mobile/delete-voicemails
     */
    deleteVoicemails: (data: DeleteVoicemailsRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/delete-voicemails`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voicemail-controller
     * @name DeleteVoicemail
     * @request POST:/mobile/delete-voicemail
     */
    deleteVoicemail: (data: DeleteVoicemailRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/delete-voicemail`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name DeleteUser
     * @request POST:/mobile/delete-user
     */
    deleteUser: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/delete-user`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags phonebook-controller
     * @name DeleteHomePhoneBook
     * @request POST:/mobile/delete-home-phonebook
     */
    deleteHomePhoneBook: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/delete-home-phonebook`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name DeleteCategoryNew
     * @request POST:/mobile/delete-category
     */
    deleteCategoryNew: (data: DeleteCategoryRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/delete-category`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-logs-controller
     * @name DeleteCallLogs
     * @request POST:/mobile/delete-call-logs-by-category
     */
    deleteCallLogs: (data: DeleteCallLogsRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/delete-call-logs-by-category`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-logs-controller
     * @name DeleteCallLogs1
     * @request POST:/mobile/delete-call-log
     */
    deleteCallLogs1: (data: DeleteCallLogRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/delete-call-log`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags phonebook-controller
     * @name CreateHomePhoneBook
     * @request POST:/mobile/create-home-phonebook
     */
    createHomePhoneBook: (data: HomePhoneBookRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/create-home-phonebook`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags web-site-controller
     * @name ChooseNewPasswordPostJson
     * @request POST:/mobile/choose-new-password
     */
    chooseNewPasswordPostJson: (data: ChooseNewPasswordRequest, params: RequestParams = {}) =>
      this.request<ChooseNewPasswordResponse, string>({
        path: `/mobile/choose-new-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name CheckPassword
     * @request POST:/mobile/check-password
     */
    checkPassword: (data: CheckPasswordRequest, params: RequestParams = {}) =>
      this.request<CheckPasswordResponse, string>({
        path: `/mobile/check-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags google-purchase-controller
     * @name CheckGoogleTransactions
     * @request POST:/mobile/check-google-transactions
     */
    checkGoogleTransactions: (data: CheckGoogleTransactionsRequest, params: RequestParams = {}) =>
      this.request<CheckGoogleTransactionsResponse, string>({
        path: `/mobile/check-google-transactions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags apple-purchase-controller
     * @name ListCountriesSuitableForProductIt
     * @request POST:/mobile/check-apple-transactions
     */
    listCountriesSuitableForProductIt: (data: CheckAppleTransactionsRequest, params: RequestParams = {}) =>
      this.request<CheckAppleTransactionsResponse, string>({
        path: `/mobile/check-apple-transactions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name ChangeUserEmail
     * @request POST:/mobile/change-user-email
     */
    changeUserEmail: (data: ChangeUserEmailRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/change-user-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name ChangePrivatePass
     * @request POST:/mobile/change-private-pass
     */
    changePrivatePass: (data: ChangePrivatePassRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/change-private-pass`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags phonebook-controller
     * @name ChangeHomePhoneBookSortOrder
     * @request POST:/mobile/change-phonebook-sort-order
     */
    changeHomePhoneBookSortOrder: (data: HomePhoneBookSortOrderRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/change-phonebook-sort-order`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags phonebook-controller
     * @name ChangeHomePhoneBookDisplayOrder
     * @request POST:/mobile/change-phonebook-display-order
     */
    changeHomePhoneBookDisplayOrder: (data: HomePhoneBookDisplayOrderRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/change-phonebook-display-order`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name ChangePassword
     * @request POST:/mobile/change-password
     */
    changePassword: (data: ChangePasswordRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/change-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name ChangeUserName
     * @request POST:/mobile/change-name
     * @deprecated
     */
    changeUserName: (data: ChangeUserNameRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/change-name`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags captcha-controller
     * @name VerifyAndRegisterToken
     * @summary To verify captcha and register time-based token to be used in next important callouts
     * @request POST:/mobile/captcha/verify-and-register-token
     */
    verifyAndRegisterToken: (data: CaptchaVerifyRegisterTokenRequest, params: RequestParams = {}) =>
      this.request<CaptchaVerifyRegisterTokenResponse, string>({
        path: `/mobile/captcha/verify-and-register-token`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags captcha-controller
     * @name Status
     * @request POST:/mobile/captcha/status
     */
    status: (data: CaptchaStatusRequest, params: RequestParams = {}) =>
      this.request<CaptchaStatusResponse, string>({
        path: `/mobile/captcha/status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-transfer-controller
     * @name WarmTransferComplete
     * @request POST:/mobile/calls/transfer-complete
     */
    warmTransferComplete: (data: WarmTransferCompleteRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/calls/transfer-complete`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-transfer-controller
     * @name WarmTransferCancel
     * @request POST:/mobile/calls/transfer-cancel
     */
    warmTransferCancel: (data: WarmTransferCancelRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/calls/transfer-cancel`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name CancelCallRecording
     * @request POST:/mobile/call/call-recording/cancel
     */
    cancelCallRecording: (data: CancelCallRecordingRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/call/call-recording/cancel`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name AcknowledgeCallRecording
     * @request POST:/mobile/call/call-recording/acknowledge
     */
    acknowledgeCallRecording: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/call/call-recording/acknowledge`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-tag-controller
     * @name Assign
     * @request POST:/mobile/call-tag/assign
     */
    assign: (data: B2BCallTagAssignRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/call-tag/assign`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-quality-survey-controller
     * @name SubmitSurvey
     * @request POST:/mobile/call-quality-survey/submit
     */
    submitSurvey: (data: CallQualitySurveySubmitRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/call-quality-survey/submit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name BlockNumber
     * @request POST:/mobile/block-number
     */
    blockNumber: (data: BlockNumberRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/block-number`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-offer-controller
     * @name CancelSubscription
     * @request POST:/mobile/authenticated/user-offer/cancel-subscription
     */
    cancelSubscription: (data: CancelUserOfferSubscriptionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/authenticated/user-offer/cancel-subscription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-offer-controller
     * @name Buy
     * @request POST:/mobile/authenticated/user-offer/buy
     */
    buy: (data: UserOfferBuyRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/authenticated/user-offer/buy`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name PlansAvailableV21
     * @summary Get available plans and lock/reserve number to client
     * @request POST:/mobile/authenticated/onboarding/v2/plans-available
     */
    plansAvailableV21: (data: OnBoardingPlansAvailableRequest, params: RequestParams = {}) =>
      this.request<PlansAvailableResponseV2, string>({
        path: `/mobile/authenticated/onboarding/v2/plans-available`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name AvailablePlansPostAuthenticated
     * @summary Get available plans and lock/reserve number to client
     * @request POST:/mobile/authenticated/onboarding/plans-available
     */
    availablePlansPostAuthenticated: (data: OnBoardingPlansAvailableRequest, params: RequestParams = {}) =>
      this.request<PlansAvailableRO, string>({
        path: `/mobile/authenticated/onboarding/plans-available`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name GetListOfNumbersForCountryAuthenticated
     * @request POST:/mobile/authenticated/onboarding/list-numbers-for-country
     */
    getListOfNumbersForCountryAuthenticated: (
      data: OnBoardingListNumbersForCountryRequest,
      params: RequestParams = {},
    ) =>
      this.request<ListNumbersRO, string>({
        path: `/mobile/authenticated/onboarding/list-numbers-for-country`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name ListCountries
     * @request POST:/mobile/authenticated/onboarding/list-countries
     */
    listCountries: (data: OnBoardingListCountriesRequest, params: RequestParams = {}) =>
      this.request<OnBoardingListCountriesResponse, string>({
        path: `/mobile/authenticated/onboarding/list-countries`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name ListCountries1
     * @request POST:/mobile/onboarding/list-countries
     */
    listCountries1: (data: OnBoardingListCountriesRequest, params: RequestParams = {}) =>
      this.request<OnBoardingListCountriesResponse, string>({
        path: `/mobile/onboarding/list-countries`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name ListCountries2
     * @request POST:/mobile/anonymous/onboarding/list-countries
     */
    listCountries2: (data: OnBoardingListCountriesRequest, params: RequestParams = {}) =>
      this.request<OnBoardingListCountriesResponse, string>({
        path: `/mobile/anonymous/onboarding/list-countries`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-validation-controller
     * @name VerifyExistingEmail
     * @summary Verifies email verification code & marks existing email as verified
     * @request POST:/mobile/authenticated/email-validation/verify-existing-mail
     */
    verifyExistingEmail: (data: EmailValidationVerifyCodeRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/authenticated/email-validation/verify-existing-mail`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-validation-controller
     * @name SendCodeAuthenticated
     * @summary Generates 6-digit verification code and sends it to provided (or user existing) email
     * @request POST:/mobile/authenticated/email-validation/send-code
     */
    sendCodeAuthenticated: (data: EmailValidationSendCodeAuthenticatedRequest, params: RequestParams = {}) =>
      this.request<EmailValidationSendCodeResponse, string>({
        path: `/mobile/authenticated/email-validation/send-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-scheduling-controller
     * @name GetCategorySchedulingV2
     * @request GET:/mobile/authenticated/category/v2/schedule
     */
    getCategorySchedulingV2: (query: { categoryId: string }, params: RequestParams = {}) =>
      this.request<CategorySchedulingResponseV2, string>({
        path: `/mobile/authenticated/category/v2/schedule`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-scheduling-controller
     * @name ChangeCategorySchedulingV2
     * @request POST:/mobile/authenticated/category/v2/schedule
     */
    changeCategorySchedulingV2: (data: AddCategorySchedulingRequestV2, params: RequestParams = {}) =>
      this.request<CategorySchedulingResponseV2, string>({
        path: `/mobile/authenticated/category/v2/schedule`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-scheduling-controller
     * @name GetCategoryScheduling
     * @request GET:/mobile/authenticated/category/schedule
     * @deprecated
     */
    getCategoryScheduling: (query: { categoryId: string }, params: RequestParams = {}) =>
      this.request<CategorySchedulingResponse, string>({
        path: `/mobile/authenticated/category/schedule`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-scheduling-controller
     * @name ChangeCategoryScheduling
     * @request POST:/mobile/authenticated/category/schedule
     * @deprecated
     */
    changeCategoryScheduling: (data: AddCategorySchedulingRequest, params: RequestParams = {}) =>
      this.request<CategorySchedulingResponse, string>({
        path: `/mobile/authenticated/category/schedule`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name CancelSubscription1
     * @request POST:/mobile/authenticated/category/cancel-subscription
     */
    cancelSubscription1: (data: CancelSubscriptionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/authenticated/category/cancel-subscription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags apple-account-resolve-controller
     * @name ResolveAppleAccount
     * @request POST:/mobile/apple-resolve-account
     * @deprecated
     */
    resolveAppleAccount: (data: AppleResolveAccountRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/apple-resolve-account`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name PlansAvailableV22
     * @summary Get available plans and lock/reserve number to client
     * @request POST:/mobile/anonymous/onboarding/v2/plans-available
     */
    plansAvailableV22: (data: OnBoardingPlansAvailableRequestV2, params: RequestParams = {}) =>
      this.request<PlansAvailableResponseV2, string>({
        path: `/mobile/anonymous/onboarding/v2/plans-available`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name AvailablePlansPost1
     * @summary Get available plans and lock/reserve number to client
     * @request POST:/mobile/onboarding/plans-available
     */
    availablePlansPost1: (data: OnBoardingPlansAvailableRequest, params: RequestParams = {}) =>
      this.request<PlansAvailableRO, string>({
        path: `/mobile/onboarding/plans-available`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name AvailablePlansPost2
     * @summary Get available plans and lock/reserve number to client
     * @request POST:/mobile/anonymous/onboarding/plans-available
     */
    availablePlansPost2: (data: OnBoardingPlansAvailableRequest, params: RequestParams = {}) =>
      this.request<PlansAvailableRO, string>({
        path: `/mobile/anonymous/onboarding/plans-available`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name GetListOfNumbersForCountry
     * @request POST:/mobile/onboarding/list-numbers-for-country
     */
    getListOfNumbersForCountry: (data: OnBoardingListNumbersForCountryRequest, params: RequestParams = {}) =>
      this.request<ListNumbersRO, string>({
        path: `/mobile/onboarding/list-numbers-for-country`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-boarding-v-2-controller
     * @name GetListOfNumbersForCountry1
     * @request POST:/mobile/anonymous/onboarding/list-numbers-for-country
     */
    getListOfNumbersForCountry1: (data: OnBoardingListNumbersForCountryRequest, params: RequestParams = {}) =>
      this.request<ListNumbersRO, string>({
        path: `/mobile/anonymous/onboarding/list-numbers-for-country`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-validation-controller
     * @name SendCodeAnonymous
     * @summary Generates 6-digit verification code and sends it to provided email
     * @request POST:/mobile/anonymous/email-validation/send-code
     */
    sendCodeAnonymous: (data: EmailValidationSendCodeRequest, params: RequestParams = {}) =>
      this.request<EmailValidationSendCodeResponse, string>({
        path: `/mobile/anonymous/email-validation/send-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags credits-controller
     * @name AddUserCredits
     * @request POST:/mobile/add-user-credits
     */
    addUserCredits: (data: AddCreditsRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/add-user-credits`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name RetrieveThreads
     * @request GET:/mobile/v4/retrieve-threads
     */
    retrieveThreads: (query: { request: RetrieveThreadsRequest }, params: RequestParams = {}) =>
      this.request<ShortMessage[], string>({
        path: `/mobile/v4/retrieve-threads`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name RetrieveAllMessages
     * @request GET:/mobile/v4/retrieve-thread-messages
     */
    retrieveAllMessages: (query: { request: RetrieveAllMessagesRequest }, params: RequestParams = {}) =>
      this.request<ShortMessage[], string>({
        path: `/mobile/v4/retrieve-thread-messages`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags message-controller
     * @name GetStoreContentPreSignedUrl
     * @request GET:/mobile/v4/generate-message-content-url
     */
    getStoreContentPreSignedUrl: (query: { request: GetPreSignedURLRequest }, params: RequestParams = {}) =>
      this.request<GetPreSignedURLResponse[], string>({
        path: `/mobile/v4/generate-message-content-url`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name GetCategories
     * @request GET:/mobile/v3/get-categories
     */
    getCategories: (query: { request: GetCategoriesRequestV3 }, params: RequestParams = {}) =>
      this.request<GetCategoriesResponseV3, string>({
        path: `/mobile/v3/get-categories`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name GetCategoriesBadgeData
     * @request GET:/mobile/v3/get-categories-badges
     */
    getCategoriesBadgeData: (query: { request: GetCategoriesRequestV3 }, params: RequestParams = {}) =>
      this.request<GetCategoriesBadgesResponseV3, string>({
        path: `/mobile/v3/get-categories-badges`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-logs-controller
     * @name RetrieveAllCallLogs
     * @request GET:/mobile/v2/retrieve-all-call-logs
     */
    retrieveAllCallLogs: (query: { request: GetCallLogsRequest }, params: RequestParams = {}) =>
      this.request<CallLogResponse[], string>({
        path: `/mobile/v2/retrieve-all-call-logs`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags country-controller
     * @name ListCountriesForNumbers
     * @request GET:/mobile/v2/list-countries-for-numbers
     */
    listCountriesForNumbers: (params: RequestParams = {}) =>
      this.request<CountriesForNumbersResponse, string>({
        path: `/mobile/v2/list-countries-for-numbers`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags country-controller
     * @name ListCountriesForDialer
     * @request GET:/mobile/v2/list-countries-for-dialer
     */
    listCountriesForDialer: (params: RequestParams = {}) =>
      this.request<CountriesForDialerResponse, string>({
        path: `/mobile/v2/list-countries-for-dialer`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags voicemail-controller
     * @name GetAllVoicemails
     * @request GET:/mobile/v2/get-voicemails
     */
    getAllVoicemails: (query: { request: GetVoicemailsRequest }, params: RequestParams = {}) =>
      this.request<AllVoicemailsResponse, string>({
        path: `/mobile/v2/get-voicemails`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags price-controller
     * @name GetBillingRatesOld
     * @request GET:/mobile/v2/get-billing-rates
     */
    getBillingRatesOld: (query: { request: UsagePriceRequest }, params: RequestParams = {}) =>
      this.request<BillingRate[], string>({
        path: `/mobile/v2/get-billing-rates`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-offer-controller
     * @name Details
     * @request GET:/mobile/user-offer/details
     */
    details: (params: RequestParams = {}) =>
      this.request<UserOfferDetailsResponse, string>({
        path: `/mobile/user-offer/details`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-response-code-controller
     * @name TestConflictErrorLozalization
     * @request GET:/mobile/test-conflict-error-localization
     * @deprecated
     */
    testConflictErrorLozalization: (params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/mobile/test-conflict-error-localization`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-response-code-controller
     * @name TestCode
     * @request GET:/mobile/test-code
     * @deprecated
     */
    testCode: (query: { request: TestCodeRequest }, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/mobile/test-code`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name RetrieveLastIncomingCall
     * @request GET:/mobile/retrieve-last-incoming-call
     */
    retrieveLastIncomingCall: (params: RequestParams = {}) =>
      this.request<IncomingCallData, string>({
        path: `/mobile/retrieve-last-incoming-call`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name RetrieveBlockedNumbers
     * @request GET:/mobile/retrieve-blocked-numbers
     */
    retrieveBlockedNumbers: (params: RequestParams = {}) =>
      this.request<string[], string>({
        path: `/mobile/retrieve-blocked-numbers`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags voip-controller
     * @name ProvisionSipConfig
     * @request GET:/mobile/provision-sip-config
     * @deprecated
     */
    provisionSipConfig: (query: { request: ProvisionSipConfigRequest }, params: RequestParams = {}) =>
      this.request<ProvisionSipConfigResponse, string>({
        path: `/mobile/provision-sip-config`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name PrepareOutgoingCall
     * @request GET:/mobile/prepare-outgoing-call
     */
    prepareOutgoingCall: (query: { ocf: OutgoingCallRequest }, params: RequestParams = {}) =>
      this.request<OutgoingCallResponse, string>({
        path: `/mobile/prepare-outgoing-call`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags portability-mobile-controller
     * @name GetAvailableDates
     * @request GET:/mobile/port-in/available-dates
     */
    getAvailableDates: (query: { request: PortInAvailableDatesRequest }, params: RequestParams = {}) =>
      this.request<PortInAvailableDatesResponse, string>({
        path: `/mobile/port-in/available-dates`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags portability-mobile-controller
     * @name GetPortInStatus
     * @request GET:/mobile/port-in-status
     */
    getPortInStatus: (params: RequestParams = {}) =>
      this.request<PortInStatusResponse[], string>({
        path: `/mobile/port-in-status`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name Statistics1
     * @request GET:/mobile/plans/statistics
     */
    statistics1: (query: { request: CategoryIdRequest }, params: RequestParams = {}) =>
      this.request<PlanStatisticsRO, string>({
        path: `/mobile/plans/statistics`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags price-increase-prompt-controller
     * @name GetPricePromptInfo
     * @request GET:/mobile/plans/price-increase-prompt-info
     */
    getPricePromptInfo: (params: RequestParams = {}) =>
      this.request<PriceIncreasePromptInfoResponse, string>({
        path: `/mobile/plans/price-increase-prompt-info`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name ListNumbersForProduct
     * @request GET:/mobile/plans/list-numbers-for-product
     */
    listNumbersForProduct: (query: { request: ListNumbersForProductRequest }, params: RequestParams = {}) =>
      this.request<ListNumbersRO, string>({
        path: `/mobile/plans/list-numbers-for-product`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name ListNumbersForCountry
     * @request GET:/mobile/plans/list-numbers-for-country
     */
    listNumbersForCountry: (query: { request: ListNumbersForCountryRequest }, params: RequestParams = {}) =>
      this.request<ListNumbersRO, string>({
        path: `/mobile/plans/list-numbers-for-country`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name ListInitialNumbers
     * @request GET:/mobile/plans/list-initial-numbers
     */
    listInitialNumbers: (query: { request: ListInitialNumbersRequest }, params: RequestParams = {}) =>
      this.request<ListInitialNumbersRO, string>({
        path: `/mobile/plans/list-initial-numbers`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name ListCategoriesForProduct
     * @request GET:/mobile/plans/list-categories-for-product
     */
    listCategoriesForProduct: (query: { request: ListCategoriesForProductRequest }, params: RequestParams = {}) =>
      this.request<string[], string>({
        path: `/mobile/plans/list-categories-for-product`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans-controller
     * @name UserNumberUsageNote
     * @request GET:/mobile/plans/get-number-user-note
     */
    userNumberUsageNote: (query: { countryIsoCode: string }, params: RequestParams = {}) =>
      this.request<NumberUsageNoteResponse, string>({
        path: `/mobile/plans/get-number-user-note`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-off-calls-controller
     * @name GetSimValidationStatus
     * @request GET:/mobile/onoffcall/sim-validation/status
     */
    getSimValidationStatus: (params: RequestParams = {}) =>
      this.request<OnOffCallSimValidationStatusResponse, string>({
        path: `/mobile/onoffcall/sim-validation/status`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-off-calls-controller
     * @name FindSimCardNumber
     * @request GET:/mobile/onoffcall/sim-validation/find-sim-card-number
     */
    findSimCardNumber: (params: RequestParams = {}) =>
      this.request<OnOffCallSimNumberResponse, string>({
        path: `/mobile/onoffcall/sim-validation/find-sim-card-number`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags country-controller
     * @name ListStatesDetailed
     * @request GET:/mobile/list-states-detailed
     */
    listStatesDetailed: (query: { request: ListStatesRequest }, params: RequestParams = {}) =>
      this.request<StateDetailsResponse, string>({
        path: `/mobile/list-states-detailed`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags country-controller
     * @name ListDestinationSupportedCountries
     * @request GET:/mobile/list-countries-for-billing
     */
    listDestinationSupportedCountries: (
      query: { request: ListCountriesForBillingRequest },
      params: RequestParams = {},
    ) =>
      this.request<DestinationSupportedCountriesResponse, string>({
        path: `/mobile/list-countries-for-billing`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name KycSuccess
     * @request GET:/mobile/kyc/success
     */
    kycSuccess: (params: RequestParams = {}) =>
      this.request<KycSuccessResponse, string>({
        path: `/mobile/kyc/success`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name Status1
     * @request GET:/mobile/kyc/status
     */
    status1: (query?: { categoryId?: string }, params: RequestParams = {}) =>
      this.request<KycStatusResponse, string>({
        path: `/mobile/kyc/status`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name SimNumberValidationStatus
     * @request GET:/mobile/kyc/sim-number-validation/status
     */
    simNumberValidationStatus: (query: { categoryId: string }, params: RequestParams = {}) =>
      this.request<SimNumberValidationResponse, string>({
        path: `/mobile/kyc/sim-number-validation/status`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name SimNumberValidationInfo
     * @request GET:/mobile/kyc/sim-number-validation/info
     */
    simNumberValidationInfo: (query: { categoryId: string }, params: RequestParams = {}) =>
      this.request<SimNumberValidationResponse, string>({
        path: `/mobile/kyc/sim-number-validation/info`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name GetVeriffPoaSessionUrl
     * @request GET:/mobile/kyc/proof-of-address/create-session
     */
    getVeriffPoaSessionUrl: (query: { categoryId: string }, params: RequestParams = {}) =>
      this.request<KycVeriffSessionResponse, string>({
        path: `/mobile/kyc/proof-of-address/create-session`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name PersonalDocumentInfo
     * @request GET:/mobile/kyc/personal-document/info
     */
    personalDocumentInfo: (query?: { categoryId?: string }, params: RequestParams = {}) =>
      this.request<KycPersonalDocumentInfoResponse, string>({
        path: `/mobile/kyc/personal-document/info`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name PersonalDocumentInfo1
     * @request GET:/mobile/kyc/personal-document-v2/info
     */
    personalDocumentInfo1: (params: RequestParams = {}) =>
      this.request<KycPersonalDocumentInfoResponse, string>({
        path: `/mobile/kyc/personal-document-v2/info`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name GetVeriffIdvSessionUrl
     * @request GET:/mobile/kyc/personal-document-v2/create-session
     */
    getVeriffIdvSessionUrl: (query: { categoryId: string }, params: RequestParams = {}) =>
      this.request<KycVeriffSessionResponse, string>({
        path: `/mobile/kyc/personal-document-v2/create-session`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name PersonalDetailsInfo
     * @request GET:/mobile/kyc/personal-details/info
     */
    personalDetailsInfo: (query: { categoryId: string }, params: RequestParams = {}) =>
      this.request<KycPersonalDetailsResponse, string>({
        path: `/mobile/kyc/personal-details/info`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name GetPersonalAddressInfo
     * @request GET:/mobile/kyc/personal-address/info
     */
    getPersonalAddressInfo: (query: { categoryId: string }, params: RequestParams = {}) =>
      this.request<KycPersonalAddressInfoResponse, string>({
        path: `/mobile/kyc/personal-address/info`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags web-site-controller
     * @name IsPasswordRecoveryTokenOk
     * @request GET:/mobile/is-password-recovery-token-ok
     */
    isPasswordRecoveryTokenOk: (query: { request: IsPasswordRecoveryTokenOkRequest }, params: RequestParams = {}) =>
      this.request<IsPasswordRecoveryTokenOkResponse, string>({
        path: `/mobile/is-password-recovery-token-ok`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name IsDarkTheme
     * @request GET:/mobile/is-dark-theme
     * @deprecated
     */
    isDarkTheme: (params: RequestParams = {}) =>
      this.request<IsDarkThemeResponse, string>({
        path: `/mobile/is-dark-theme`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags voip-controller
     * @name IsCallAllowed
     * @request GET:/mobile/is-call-allowed
     */
    isCallAllowed: (query: { request: VoipCallAllowedRequest }, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/is-call-allowed`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voip-controller
     * @name GetVoipFeedbackList
     * @request GET:/mobile/get-voip-feedback-list
     */
    getVoipFeedbackList: (params: RequestParams = {}) =>
      this.request<VoipFeedbackProblemResponse[], string>({
        path: `/mobile/get-voip-feedback-list`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags voicemail-controller
     * @name GetVoicemailGreetingInfo
     * @request GET:/mobile/get-voicemail-greeting-info
     */
    getVoicemailGreetingInfo: (query: { request: CategoryIdRequest }, params: RequestParams = {}) =>
      this.request<VoicemailGreetingSettingsInfoResponse, string>({
        path: `/mobile/get-voicemail-greeting-info`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sync-controller
     * @name GetUser
     * @request GET:/mobile/get-user
     */
    getUser: (params: RequestParams = {}) =>
      this.request<UserResponse, string>({
        path: `/mobile/get-user`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags credits-controller
     * @name GetUserCredits
     * @request GET:/mobile/get-user-credits
     */
    getUserCredits: (params: RequestParams = {}) =>
      this.request<UserBalanceShort, string>({
        path: `/mobile/get-user-credits`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetSupportedCommunicationLocales
     * @request GET:/mobile/get-supported-communication-locale
     */
    getSupportedCommunicationLocales: (params: RequestParams = {}) =>
      this.request<SupportedCommunicationLocalesResponse, string>({
        path: `/mobile/get-supported-communication-locale`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-response-code-controller
     * @name GetPushes
     * @request GET:/mobile/get-pushes-slow
     * @deprecated
     */
    getPushes: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/mobile/get-pushes-slow`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-device-controller
     * @name GetPresignedUrlForDeviceLogsUpload
     * @request GET:/mobile/get-presigned-urls-for-device-logs-upload
     */
    getPresignedUrlForDeviceLogsUpload: (params: RequestParams = {}) =>
      this.request<GetPresignedUrlForDeviceLogsUploadResponse[], string>({
        path: `/mobile/get-presigned-urls-for-device-logs-upload`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetNextAppRateDate
     * @request GET:/mobile/get-next-app-rate-date
     */
    getNextAppRateDate: (params: RequestParams = {}) =>
      this.request<NextAppRateDateResponse, string>({
        path: `/mobile/get-next-app-rate-date`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags credits-controller
     * @name GetCreditAppProductIds
     * @request GET:/mobile/get-credit-app-product-ids
     */
    getCreditAppProductIds: (params: RequestParams = {}) =>
      this.request<CreditAppProductIdResponse, string>({
        path: `/mobile/get-credit-app-product-ids`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetCommunicationLocale
     * @request GET:/mobile/get-communication-locale
     */
    getCommunicationLocale: (params: RequestParams = {}) =>
      this.request<SimpleValueResponseString, string>({
        path: `/mobile/get-communication-locale`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-forwarding-controller
     * @name GetCallForwardingDetails
     * @request GET:/mobile/get-call-forwarding-details
     */
    getCallForwardingDetails: (query: { categoryId: string }, params: RequestParams = {}) =>
      this.request<GetCallForwardingResponse, string>({
        path: `/mobile/get-call-forwarding-details`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags customer-support-controller
     * @name GenerateCustomerAttachmentPresignedUrl
     * @request GET:/mobile/generate-customer-attachment-url
     */
    generateCustomerAttachmentPresignedUrl: (
      query: { request: GetCustomerAttachmentPresignedUrlRequest },
      params: RequestParams = {},
    ) =>
      this.request<GetPreSignedURLResponse, string>({
        path: `/mobile/generate-customer-attachment-url`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-device-controller
     * @name GetDevicesList1
     * @request GET:/mobile/devices/list
     */
    getDevicesList1: (params: RequestParams = {}) =>
      this.request<UserDevicesResponse, string>({
        path: `/mobile/devices/list`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-tag-controller
     * @name Get
     * @request GET:/mobile/call-tag/get
     */
    get: (query: { request: B2BCallTagGetRequest }, params: RequestParams = {}) =>
      this.request<B2BCallTagGetResponse, string>({
        path: `/mobile/call-tag/get`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-quality-survey-controller
     * @name StartSurvey
     * @request GET:/mobile/call-quality-survey/start
     */
    startSurvey: (params: RequestParams = {}) =>
      this.request<CallQualitySurveyStartResponse, string>({
        path: `/mobile/call-quality-survey/start`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-offer-controller
     * @name Info
     * @request GET:/mobile/authenticated/user-offer/info
     */
    info: (params: RequestParams = {}) =>
      this.request<UserOfferInfoResponse, string>({
        path: `/mobile/authenticated/user-offer/info`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-offer-controller
     * @name GetAvailableUserOffers
     * @request GET:/mobile/authenticated/user-offer/available
     */
    getAvailableUserOffers: (params: RequestParams = {}) =>
      this.request<UserOfferAvailableResponse, string>({
        path: `/mobile/authenticated/user-offer/available`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-validation-controller
     * @name IsEnabled
     * @summary Email validation feature toggle value to enable/disable flow
     * @request GET:/mobile/anonymous/email-validation/is-enabled
     */
    isEnabled: (params: RequestParams = {}) =>
      this.request<EmailValidationEnabledResponse, string>({
        path: `/mobile/anonymous/email-validation/is-enabled`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-validation-controller
     * @name IsEnabled1
     * @summary Email validation feature toggle value to enable/disable flow
     * @request GET:/mobile/authenticated/email-validation/is-enabled
     */
    isEnabled1: (params: RequestParams = {}) =>
      this.request<EmailValidationEnabledResponse, string>({
        path: `/mobile/authenticated/email-validation/is-enabled`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags category-controller
     * @name GetColorNames
     * @request GET:/mobile/authenticated/category/get-color-names
     */
    getColorNames: (params: RequestParams = {}) =>
      this.request<ColorNamesResponse, string>({
        path: `/mobile/authenticated/category/get-color-names`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-offer-controller
     * @name GetAvailableUserOffersAnonymous
     * @request GET:/mobile/anonymous/user-offer/available
     */
    getAvailableUserOffersAnonymous: (params: RequestParams = {}) =>
      this.request<UserOfferAvailableResponse, string>({
        path: `/mobile/anonymous/user-offer/available`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-report-controller
     * @name TriggerB2BReportsGeneration
     * @request GET:/mobile/anonymous/experimental/b2b/report-generation
     * @deprecated
     */
    triggerB2BReportsGeneration: (query?: { fromDate?: string; toDate?: string }, params: RequestParams = {}) =>
      this.request<HealthResponse, string>({
        path: `/mobile/anonymous/experimental/b2b/report-generation`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
  webSite = {
    /**
     * No description
     *
     * @tags web-site-controller
     * @name UnsubscribeFromEmails
     * @request POST:/web-site/email-unsubscribe
     */
    unsubscribeFromEmails: (data: EmailUnsubscribeRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/web-site/email-unsubscribe`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  internal = {
    /**
     * No description
     *
     * @tags virtual-phone-number-controller
     * @name UpdateProvider
     * @request POST:/internal/virtual-phone-number/update-provider
     */
    updateProvider: (data: VpnUpdateProviderRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/virtual-phone-number/update-provider`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-phone-number-controller
     * @name Unregister
     * @request POST:/internal/virtual-phone-number/unregister
     */
    unregister: (data: VpnUnregisterRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/virtual-phone-number/unregister`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-phone-number-controller
     * @name ReleasePortIn
     * @request POST:/internal/virtual-phone-number/release-portin
     */
    releasePortIn: (data: VpnReleasePortInRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/virtual-phone-number/release-portin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-phone-number-controller
     * @name Register1
     * @request POST:/internal/virtual-phone-number/register
     */
    register1: (data: VpnRegisterRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/virtual-phone-number/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-phone-number-controller
     * @name AssistPortIn
     * @request POST:/internal/virtual-phone-number/assist-portin
     */
    assistPortIn: (data: VpnAssistPortInRequest, params: RequestParams = {}) =>
      this.request<VpnAssistPortInResponse, string>({
        path: `/internal/virtual-phone-number/assist-portin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-users-controller
     * @name LogoutInactiveDevices
     * @request POST:/internal/users/logout-inactive-devices
     */
    logoutInactiveDevices: (params: RequestParams = {}) =>
      this.request<string[], string>({
        path: `/internal/users/logout-inactive-devices`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-users-controller
     * @name UpdateUserEmailCorrectness
     * @request POST:/internal/users/email-correct
     */
    updateUserEmailCorrectness: (data: UpdateUserEmailCorrectnessRequest, params: RequestParams = {}) =>
      this.request<SimpleValueResponseBoolean, string>({
        path: `/internal/users/email-correct`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-users-controller
     * @name FindUsersForClosing
     * @request POST:/internal/users/close-inactive-users/find-candidates
     */
    findUsersForClosing: (data: UsersForClosingRequest, params: RequestParams = {}) =>
      this.request<UsersForClosingResponse, string>({
        path: `/internal/users/close-inactive-users/find-candidates`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-users-controller
     * @name CloseInactiveUserAccounts
     * @request POST:/internal/users/close-inactive-users/delete
     */
    closeInactiveUserAccounts: (params: RequestParams = {}) =>
      this.request<string[], string>({
        path: `/internal/users/close-inactive-users/delete`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name ProcessIncomingCall
     * @request POST:/internal/telco/process-incoming-call
     */
    processIncomingCall: (data: ProcessIncomingCallRequest, params: RequestParams = {}) =>
      this.request<ProcessIncomingCallResponse, string>({
        path: `/internal/telco/process-incoming-call`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name ProcessAnonymousCall
     * @request POST:/internal/telco/process-anonymous-call
     */
    processAnonymousCall: (data: AnonymousCallCallbackRequest, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/internal/telco/process-anonymous-call`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name PrepareOfflineOutgoingCall
     * @request POST:/internal/telco/prepare-offline-outgoing-call
     */
    prepareOfflineOutgoingCall: (data: PrepareOfflineOutgoingCallRequest, params: RequestParams = {}) =>
      this.request<PrepareOfflineOutgoingCallResponse, string>({
        path: `/internal/telco/prepare-offline-outgoing-call`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name HandleIncomingCall
     * @request POST:/internal/telco/handle-incoming-call
     */
    handleIncomingCall: (data: IncomingCallTelecomRequest, params: RequestParams = {}) =>
      this.request<IncomingCallTelecomResponse, string>({
        path: `/internal/telco/handle-incoming-call`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name GetCalleeInformation
     * @request POST:/internal/telco/get-callee-information
     */
    getCalleeInformation: (data: GetCalleeInformationRequest, params: RequestParams = {}) =>
      this.request<GetCalleeInformationResponse, string>({
        path: `/internal/telco/get-callee-information`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-controller
     * @name CancelIncomingCall
     * @request POST:/internal/telco/MT_CancelCALL
     */
    cancelIncomingCall: (data: IncomingCallCancelTelecomRequest, params: RequestParams = {}) =>
      this.request<IncomingCallCancelTelecomResponse, string>({
        path: `/internal/telco/MT_CancelCALL`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags refund-controller
     * @name RefundByReceiptUniqueId
     * @summary Process refunds based on receipt unique IDs
     * @request POST:/internal/steward/refund/refund-by-receipt-unique-id
     */
    refundByReceiptUniqueId: (data: IdListRequest, params: RequestParams = {}) =>
      this.request<IdListResponse, string>({
        path: `/internal/steward/refund/refund-by-receipt-unique-id`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags customer-sms-discount-steward-controller
     * @name GetCustomersForDiscountSms
     * @request POST:/internal/steward/marketing/send-discount-sms-to-customers
     */
    getCustomersForDiscountSms: (data: SendCustomerDiscountSmsRequest, params: RequestParams = {}) =>
      this.request<StatusOkResponse, string>({
        path: `/internal/steward/marketing/send-discount-sms-to-customers`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags customer-sms-discount-steward-controller
     * @name GetCustomersWithDiscountSms
     * @request POST:/internal/steward/marketing/get-customers-with-discount
     */
    getCustomersWithDiscountSms: (data: GetCustomersWithDiscountRequest, params: RequestParams = {}) =>
      this.request<GetCustomersForDiscountResponse, string>({
        path: `/internal/steward/marketing/get-customers-with-discount`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-steward-controller
     * @name MarkGracePeriodOverProcessed
     * @request POST:/internal/steward/maintenance/kyc-mark-grace-period-over-processed
     */
    markGracePeriodOverProcessed: (data: MarkGracePeriodOverProcessedRequest, params: RequestParams = {}) =>
      this.request<StatusOkResponse, string>({
        path: `/internal/steward/maintenance/kyc-mark-grace-period-over-processed`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-steward-controller
     * @name HandleKycChangeForCategory
     * @request POST:/internal/steward/maintenance/kyc-handle-kyc-change-for-category
     */
    handleKycChangeForCategory: (data: ListRequestString, params: RequestParams = {}) =>
      this.request<StatusOkResponse, string>({
        path: `/internal/steward/maintenance/kyc-handle-kyc-change-for-category`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags autorenewable-steward-controller
     * @name GetAutoRenewableSubscriptions
     * @summary Returns auto-renewable credit card subscriptions based on request parameters (to be used as basis to trigger renewal payments)
     * @request POST:/internal/steward/autorenewable/credit-card/get-subscriptions-for-renew
     */
    getAutoRenewableSubscriptions: (data: GetAutoRenewableSubscriptionsRequest, params: RequestParams = {}) =>
      this.request<GetAutoRenewableSubscriptionsResponse, string>({
        path: `/internal/steward/autorenewable/credit-card/get-subscriptions-for-renew`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags autorenewable-steward-controller
     * @name CancelAutorenewableSubscription
     * @summary Cancels auto-renewable subscription so it can be bought/manually-renew again
     * @request POST:/internal/steward/autorenewable/credit-card/cancel-subscription
     */
    cancelAutorenewableSubscription: (data: CancelAutoRenewableSubscriptionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/steward/autorenewable/credit-card/cancel-subscription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags autorenewable-steward-controller
     * @name RenewB2BSubscription
     * @request POST:/internal/steward/autorenewable/b2b/renew-subscription
     */
    renewB2BSubscription: (data: B2BAutorenewableSubscriptionRenewRequest, params: RequestParams = {}) =>
      this.request<StatusOkResponse, string>({
        path: `/internal/steward/autorenewable/b2b/renew-subscription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sms-controller
     * @name ProcessIncomingSms
     * @request POST:/internal/smpp/submit
     */
    processIncomingSms: (data: SmppMessageRequest, params: RequestParams = {}) =>
      this.request<IncomingSmppMessageResponse, string>({
        path: `/internal/smpp/submit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sms-controller
     * @name DeliveredByTelecom
     * @request POST:/internal/smpp/delivered
     */
    deliveredByTelecom: (data: SmppDeliveredRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/smpp/delivered`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags relay-controller
     * @name AppleSubscriptionInfoV2
     * @request POST:/internal/relay/v2/apple-subscription-info
     */
    appleSubscriptionInfoV2: (data: AppleSubscriptionInfoV2Request, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/relay/v2/apple-subscription-info`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags relay-controller
     * @name AppleSubscriptionInfo
     * @request POST:/internal/relay/apple-subscription-info
     */
    appleSubscriptionInfo: (data: AppleSubscriptionInfoRequest, params: RequestParams = {}) =>
      this.request<AppleSubscriptionInfoResponse, string>({
        path: `/internal/relay/apple-subscription-info`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags relay-controller
     * @name AndroidSubscriptionInfo
     * @request POST:/internal/relay/android-subscription-info
     */
    androidSubscriptionInfo: (data: AndroidSubscriptionInfoRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/relay/android-subscription-info`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags refund-controller
     * @name RefundV2
     * @request POST:/internal/refund/refund-google-play-purchases
     * @deprecated
     */
    refundV2: (data: GooglePlayRefundRequest, params: RequestParams = {}) =>
      this.request<string[], string>({
        path: `/internal/refund/refund-google-play-purchases`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name ValidateNumberAndPlanV2
     * @summary To be used before new purchase to validate input (number & plan availability, prolong number reservation if needed)
     * @request POST:/internal/purchase/v2/prevalidate-purchase
     */
    validateNumberAndPlanV2: (data: PrevalidatePurchaseRequestV2, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/purchase/v2/prevalidate-purchase`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name ValidateUserOfferPurchase
     * @summary To be used before user offer purchase to validate inputs
     * @request POST:/internal/purchase/user-offer/prevalidate-purchase
     */
    validateUserOfferPurchase: (data: PrevalidateUserOfferPurchaseRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/purchase/user-offer/prevalidate-purchase`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name EnableUserOffer
     * @summary To store user offer purchase in Main side
     * @request POST:/internal/purchase/user-offer/enable
     */
    enableUserOffer: (data: EnableUserOfferRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/purchase/user-offer/enable`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name RenewSubscription
     * @summary Renews existing plan (to be used after payment)
     * @request POST:/internal/purchase/renew-subscription
     */
    renewSubscription: (data: RenewSubscriptionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/purchase/renew-subscription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags refund-controller
     * @name Refund
     * @summary Process a refund for a subscription
     * @request POST:/internal/purchase/refund/make-refund
     */
    refund: (data: RefundRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/purchase/refund/make-refund`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name ValidatePurchaseForExistingCategory
     * @summary To be used before purchase for existing category to validate input
     * @request POST:/internal/purchase/prevalidate-purchase-for-existing-category
     */
    validatePurchaseForExistingCategory: (
      data: PrevalidatePurchaseForExistingCategoryRequest,
      params: RequestParams = {},
    ) =>
      this.request<EmptyResponse, string>({
        path: `/internal/purchase/prevalidate-purchase-for-existing-category`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name GetProductsDurations
     * @summary To retrieve amount of months for productId
     * @request POST:/internal/purchase/get-products-durations
     */
    getProductsDurations: (data: GetProductsDurations, params: RequestParams = {}) =>
      this.request<GetProductsDurationsResponse, string>({
        path: `/internal/purchase/get-products-durations`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name CreateCategoryAssignPlan
     * @summary To enable number for first purchase - create category and assign plan (to be used after payment)
     * @request POST:/internal/purchase/create-category-assign-plan
     */
    createCategoryAssignPlan: (data: CreateCategoryAssignPlanRequest, params: RequestParams = {}) =>
      this.request<CreateCategoryAssignPlanResponse, string>({
        path: `/internal/purchase/create-category-assign-plan`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name AssignPlanForExistingCategory
     * @summary To enable purchase for existing category (to be used after payment)
     * @request POST:/internal/purchase/assign-plan-for-existing-category
     */
    assignPlanForExistingCategory: (data: AssignPlanForExistingCategoryRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/purchase/assign-plan-for-existing-category`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags portability-steward-controller
     * @name CreateAlarmsForDelayedPortingInOperations
     * @request POST:/internal/portability/notify-about-delayed
     */
    createAlarmsForDelayedPortingInOperations: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/portability/notify-about-delayed`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags portability-steward-controller
     * @name CancelUnconfirmedPortInRequests
     * @request POST:/internal/portability/cancel-unconfirmed-port-in
     */
    cancelUnconfirmedPortInRequests: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/portability/cancel-unconfirmed-port-in`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags portability-service-controller
     * @name PortOutStatusUpdate
     * @request POST:/internal/port-out/update-status
     */
    portOutStatusUpdate: (data: PortOutStatusUpdateRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/port-out/update-status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags portability-service-controller
     * @name PortOutPortabilityCode
     * @request POST:/internal/port-out/portability-code
     */
    portOutPortabilityCode: (data: PortOutPortabilityCodeRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/port-out/portability-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags portability-service-controller
     * @name PortOutCheckEligibility
     * @request POST:/internal/port-out/check-eligibility
     */
    portOutCheckEligibility: (data: PortOutCheckEligibilityRequest, params: RequestParams = {}) =>
      this.request<PortOutCheckEligibilityResponse, string>({
        path: `/internal/port-out/check-eligibility`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags portability-service-controller
     * @name PortInStatusUpdate
     * @request POST:/internal/port-in/update-status
     */
    portInStatusUpdate: (data: PortInStatusUpdateRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/port-in/update-status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags police-reports-controller
     * @name VoiceTrafficHistory
     * @request POST:/internal/police-reports/voice-traffic-history
     */
    voiceTrafficHistory: (data: VoiceTrafficHistoryRequest, params: RequestParams = {}) =>
      this.request<VoiceTrafficHistoryResponse, string>({
        path: `/internal/police-reports/voice-traffic-history`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags police-reports-controller
     * @name TrafficHistory
     * @request POST:/internal/police-reports/traffic-history
     * @deprecated
     */
    trafficHistory: (data: TrafficHistoryRequest, params: RequestParams = {}) =>
      this.request<TrafficHistoryResponse, string>({
        path: `/internal/police-reports/traffic-history`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-sender-controller
     * @name SendEmail
     * @request POST:/internal/purchase/send-email
     */
    sendEmail: (data: SendEmailRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/purchase/send-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags email-sender-controller
     * @name SendEmail1
     * @request POST:/internal/police-reports/send-email
     */
    sendEmail1: (data: SendEmailRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/police-reports/send-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags police-reports-controller
     * @name MessageThreadsList
     * @request POST:/internal/police-reports/message-threads-list
     */
    messageThreadsList: (data: MessageThreadsRequest, params: RequestParams = {}) =>
      this.request<MessageThreadsResponse, string>({
        path: `/internal/police-reports/message-threads-list`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags police-reports-controller
     * @name MessageHistory
     * @request POST:/internal/police-reports/message-history
     */
    messageHistory: (data: MessageHistoryRequest, params: RequestParams = {}) =>
      this.request<MessageHistoryResponse, string>({
        path: `/internal/police-reports/message-history`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags police-reports-controller
     * @name FindUser
     * @request POST:/internal/police-reports/find-user
     */
    findUser: (data: FindUserRequest, params: RequestParams = {}) =>
      this.request<FindUserResponse, string>({
        path: `/internal/police-reports/find-user`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-off-calls-controller
     * @name IsOnOffCallsEnabledAndPhonesLimitValid
     * @request POST:/internal/onoffcall/validate-calls-enabled-and-phones-limit
     */
    isOnOffCallsEnabledAndPhonesLimitValid: (
      data: CallsEnablingAndPhonesLimitValidationRequest,
      params: RequestParams = {},
    ) =>
      this.request<OnOffCallCountryCodeData, string>({
        path: `/internal/onoffcall/validate-calls-enabled-and-phones-limit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-off-calls-controller
     * @name UpdateStatus
     * @request POST:/internal/onoffcall/update-status
     */
    updateStatus: (data: OnOffCallStatusRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/onoffcall/update-status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-off-calls-controller
     * @name UpdateSimValidationStatus
     * @request POST:/internal/onoffcall/sim-validation/callback
     */
    updateSimValidationStatus: (data: SimNumberValidationCallbackRequest, params: RequestParams = {}) =>
      this.request<StatusOkResponse, string>({
        path: `/internal/onoffcall/sim-validation/callback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-off-calls-controller
     * @name SyncTsansNotification
     * @request POST:/internal/onoffcall/send-sync-tsans-notification
     */
    syncTsansNotification: (data: OnOffCallSyncTsansRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/onoffcall/send-sync-tsans-notification`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mms-controller
     * @name ProcessIncomingMms
     * @request POST:/internal/mms/submit
     */
    processIncomingMms: (data: MmsMessageRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/mms/submit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mms-controller
     * @name DeliveredByTelecom1
     * @request POST:/internal/mms/delivered
     */
    deliveredByTelecom1: (data: SmppDeliveredRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/mms/delivered`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mms-controller
     * @name IsOnoffNumber
     * @request POST:/internal/mms/check-number
     */
    isOnoffNumber: (data: MmsCheckNumberRequest, params: RequestParams = {}) =>
      this.request<MmsCheckNumberResponse, string>({
        path: `/internal/mms/check-number`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags localization-controller
     * @name GetMessage
     * @request POST:/internal/localization/message
     */
    getMessage: (data: LocalizationRequest, params: RequestParams = {}) =>
      this.request<SimpleValueResponseString, string>({
        path: `/internal/localization/message`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-controller
     * @name SimNumberValidationCallback
     * @request POST:/internal/kyc/sim-number-validation/callback
     */
    simNumberValidationCallback: (data: SimNumberValidationCallbackRequest, params: RequestParams = {}) =>
      this.request<StatusOkResponse, string>({
        path: `/internal/kyc/sim-number-validation/callback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags integrations-controller
     * @name SendSms
     * @request POST:/internal/integrations/send-sms
     */
    sendSms: (data: SendSmsByIntegrationsRequest, params: RequestParams = {}) =>
      this.request<SendSmsByIntegrationsResponse, string>({
        path: `/internal/integrations/send-sms`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags greetings-controller
     * @name ValidateForwardingRoute
     * @request POST:/internal/greetings/validate/forwarding-route
     */
    validateForwardingRoute: (data: GreetingsForwardingRouteRequest, params: RequestParams = {}) =>
      this.request<GreetingsForwardingRouteResponse, string>({
        path: `/internal/greetings/validate/forwarding-route`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags greetings-controller
     * @name RegisterValidationCall
     * @request POST:/internal/greetings/register/validation-call
     */
    registerValidationCall: (data: GreetingsNumberValidationRequest, params: RequestParams = {}) =>
      this.request<RegisterValidationCallResponse, string>({
        path: `/internal/greetings/register/validation-call`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags greetings-controller
     * @name FilterOnoffPhoneNumbers
     * @request POST:/internal/greetings/onoff-numbers-filter
     */
    filterOnoffPhoneNumbers: (data: PhoneNumbersRequest, params: RequestParams = {}) =>
      this.request<PhoneNumbersResponse, string>({
        path: `/internal/greetings/onoff-numbers-filter`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags greetings-controller
     * @name FilterOnoffDisabledCategoryPhoneNumbers
     * @request POST:/internal/greetings/onoff-numbers-disabled
     */
    filterOnoffDisabledCategoryPhoneNumbers: (data: PhoneNumbersRequest, params: RequestParams = {}) =>
      this.request<PhoneNumbersResponse, string>({
        path: `/internal/greetings/onoff-numbers-disabled`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-contacts-controller
     * @name ValidateRateLimit
     * @request POST:/internal/contacts/validate-ratelimit
     */
    validateRateLimit: (data: ContactRateLimitValidationRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/contacts/validate-ratelimit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-contacts-controller
     * @name ValidateCategories
     * @request POST:/internal/contacts/validate-categories
     */
    validateCategories: (data: CategoriesValidationRequest, params: RequestParams = {}) =>
      this.request<ValidateCategoriesResponse, string>({
        path: `/internal/contacts/validate-categories`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-contacts-controller
     * @name GetActiveCategoryWithName
     * @request POST:/internal/contacts/get-category-info
     */
    getActiveCategoryWithName: (data: GetCategoryInfoRequest, params: RequestParams = {}) =>
      this.request<GetCategoryInfoResponse, string>({
        path: `/internal/contacts/get-category-info`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-transfer-controller
     * @name PushTransferCall
     * @request POST:/internal/calls/transfer-push
     */
    pushTransferCall: (data: TransferCallPushRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/calls/transfer-push`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing-v-2-queue-operation-controller
     * @name ProcessBillingRequest
     * @request POST:/internal/billing/process-billing-hocs-operation
     */
    processBillingRequest: (data: BillingQueueOperationRequestV2, params: RequestParams = {}) =>
      this.request<BillingQueueResponse, string>({
        path: `/internal/billing/process-billing-hocs-operation`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name UpdateUserBlockedStatus
     * @summary Blocks or unblocks ether one specified user or all the users belonging to the organization.
     * @request POST:/internal/b2b/update-user-blocked-status
     */
    updateUserBlockedStatus: (data: B2BUpdateUserBlockedStatusRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/b2b/update-user-blocked-status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-global-credits-controller
     * @name UpdateGlobalCredits
     * @request POST:/internal/b2b/update-global-credits
     */
    updateGlobalCredits: (data: B2BUpdateGlobalCreditsAmountRequest, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/internal/b2b/update-global-credits`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-plans-controller
     * @name UpdateCategoryType
     * @request POST:/internal/b2b/update-category-type
     */
    updateCategoryType: (data: B2BUpdateCategoryTypeRequest, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/update-category-type`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2b-number-controller
     * @name UnReserveNumbers
     * @request POST:/internal/b2b/unreserve-numbers
     */
    unReserveNumbers: (data: B2BUnReserveNumbersRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/b2b/unreserve-numbers`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name ToggleKybRequiredForUser
     * @request POST:/internal/b2b/toggle-kyb-required
     */
    toggleKybRequiredForUser: (data: B2BToggleKybRequiredRequest, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/toggle-kyb-required`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name SendOnBoardingEmail
     * @request POST:/internal/b2b/send-on-boarding-emails
     */
    sendOnBoardingEmail: (data: B2BSendEmailRequest, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/send-on-boarding-emails`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name SendMigrationEmail
     * @request POST:/internal/b2b/send-migration-emails
     */
    sendMigrationEmail: (data: B2BSendMigrationEMailRequest, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/send-migration-emails`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-plans-controller
     * @name ResumeCategoryPlan
     * @request POST:/internal/b2b/resume-category-plan
     */
    resumeCategoryPlan: (data: B2BResumePlansForCategoryRequest, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/resume-category-plan`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name MakePasswordRecovery1
     * @request POST:/internal/b2b/reset-user-password
     */
    makePasswordRecovery1: (data: B2BResetUserPasswordRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/b2b/reset-user-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2b-number-controller
     * @name ReserveNumbers
     * @request POST:/internal/b2b/reserve-numbers
     */
    reserveNumbers: (data: B2BReserveNumbersRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/b2b/reserve-numbers`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-plans-controller
     * @name RenewPlans
     * @request POST:/internal/b2b/renew-plans
     */
    renewPlans: (data: B2BRenewPlansRequest, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/renew-plans`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2b-number-controller
     * @name ReleaseB2CNumberForB2BUser
     * @request POST:/internal/b2b/release-b2c-number-for-b2b-user
     */
    releaseB2CNumberForB2BUser: (data: B2BReleaseB2CNumberToB2BUserRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/b2b/release-b2c-number-for-b2b-user`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-global-credits-controller
     * @name RegisterB2BOrganization
     * @request POST:/internal/b2b/register-organization
     */
    registerB2BOrganization: (data: BillingHocsOperationRegisterOrganizationRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/b2b/register-organization`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-plans-controller
     * @name ReallocateCategory
     * @request POST:/internal/b2b/reallocate-category
     */
    reallocateCategory: (data: B2BReallocateCategoryRequest, params: RequestParams = {}) =>
      this.request<B2BReallocateCategoryRO, string>({
        path: `/internal/b2b/reallocate-category`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-greetings-controller
     * @name ManageGreetingsFeatureForCategory
     * @request POST:/internal/b2b/manage-greetings-for-category
     */
    manageGreetingsFeatureForCategory: (data: ManageGreetingsForCategoryRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/b2b/manage-greetings-for-category`,
        method: 'POST',
        body: data,
        ...params,
      }),

    /**
     * No description
     *
     * @tags statistics-controller
     * @name GenerateB2BDetailedReport
     * @request POST:/internal/b2b/generate-detailed-report
     */
    generateB2BDetailedReport: (data: B2BDetailedReportRequest, params: RequestParams = {}) =>
      this.request<void, string>({
        path: `/internal/b2b/generate-detailed-report`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name EditB2BUserDetails
     * @request POST:/internal/b2b/edit-b2b-details
     */
    editB2BUserDetails: (data: B2BUpdateUserRequest, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/edit-b2b-details`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name LogoutDevices1
     * @request POST:/internal/b2b/devices-logout
     */
    logoutDevices1: (data: UserDevicesForLogoutRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/b2b/devices-logout`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name GetDevicesList
     * @request POST:/internal/b2b/devices-list
     */
    getDevicesList: (data: UserIdsRequest, params: RequestParams = {}) =>
      this.request<UserIdDevicesResponse[], string>({
        path: `/internal/b2b/devices-list`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-global-credits-controller
     * @name DeleteB2BOrganization
     * @request POST:/internal/b2b/delete-organization
     */
    deleteB2BOrganization: (data: BillingHocsOperationDeleteOrganizationRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/b2b/delete-organization`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-plans-controller
     * @name DeleteCategory
     * @request POST:/internal/b2b/delete-category
     */
    deleteCategory: (data: B2BDeleteCategoryRequest, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/delete-category`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name DeleteB2BUsers
     * @request POST:/internal/b2b/delete-b2b-users
     */
    deleteB2BUsers: (data: B2BDeleteUsersRequest, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/delete-b2b-users`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-plans-controller
     * @name CreateCategoriesAssignPlans
     * @request POST:/internal/b2b/create-categories-assign-plans
     */
    createCategoriesAssignPlans: (data: B2BCreateCategoriesPlansRequest, params: RequestParams = {}) =>
      this.request<B2BCreateCategoriesPlansRO, string>({
        path: `/internal/b2b/create-categories-assign-plans`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name CreateB2BUsers
     * @request POST:/internal/b2b/create-b2b-users
     */
    createB2BUsers: (data: B2BCreateUsersRequest, params: RequestParams = {}) =>
      this.request<B2BCreateUsersRO, string>({
        path: `/internal/b2b/create-b2b-users`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name CheckEmailAvailability
     * @request POST:/internal/b2b/check-email-availability
     */
    checkEmailAvailability: (data: B2BEmailListRequest, params: RequestParams = {}) =>
      this.request<B2BEmailAvailabilityRO, string>({
        path: `/internal/b2b/check-email-availability`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name ChangeB2BAccountType
     * @request POST:/internal/b2b/change-account-type
     */
    changeB2BAccountType: (data: B2BChangeAccountTypeRequest, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/change-account-type`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-plans-controller
     * @name CancelCategoryPlan
     * @request POST:/internal/b2b/cancel-category-plan
     */
    cancelCategoryPlan: (data: B2BCancelPlansForCategory, params: RequestParams = {}) =>
      this.request<B2BEmptyRO, string>({
        path: `/internal/b2b/cancel-category-plan`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags token-info-controller
     * @name GetTokenInfoV2
     * @request POST:/internal/auth/tokeninfo
     */
    getTokenInfoV2: (data: TokenInfoRequest, params: RequestParams = {}) =>
      this.request<TokenInfoV2Response, string>({
        path: `/internal/auth/tokeninfo`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth-password-recovery-controller
     * @name Requested
     * @request POST:/internal/auth/password/recovery/requested
     */
    requested: (data: PasswordRecoveryRequestedRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/auth/password/recovery/requested`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth-password-recovery-controller
     * @name Changed
     * @request POST:/internal/auth/password/changed
     */
    changed: (data: PasswordChangedRequest, params: RequestParams = {}) =>
      this.request<PasswordChangedResponse, string>({
        path: `/internal/auth/password/changed`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags freeswitch-call-logs-controller
     * @name UploadCdr
     * @request POST:/internal/anonymous/voip/upload-cdr
     */
    uploadCdr: (data: UploadFreeswitchCdrRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/anonymous/voip/upload-cdr`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voicemail-telco-controller
     * @name PostIncomingVoicemailV2
     * @request POST:/internal/anonymous/voip/post-incoming-voicemail
     */
    postIncomingVoicemailV2: (data: PostIncomingVoicemailRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/anonymous/voip/post-incoming-voicemail`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voicemail-telco-controller
     * @name GetVoicemailSignedUploadUrl
     * @request POST:/internal/anonymous/voip/post-incoming-voicemail-signed-upload-url
     */
    getVoicemailSignedUploadUrl: (data: IncomingVoicemailSignedUploadUrlRequest, params: RequestParams = {}) =>
      this.request<IncomingVoicemailSignedUploadUrlResponse, string>({
        path: `/internal/anonymous/voip/post-incoming-voicemail-signed-upload-url`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voip-service-controller
     * @name MarkCallRecordingReady
     * @request POST:/internal/anonymous/voip/call-recording/mark-ready
     */
    markCallRecordingReady: (data: MarkCallRecordingReadyRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/anonymous/voip/call-recording/mark-ready`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name UserSupport
     * @request POST:/internal/admin/user/user-support
     */
    userSupport: (data: UserSupportRequest, params: RequestParams = {}) =>
      this.request<UserSupportResponse, string>({
        path: `/internal/admin/user/user-support`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name UpdateUserData
     * @request POST:/internal/admin/user/update-user-data
     */
    updateUserData: (data: UserDataRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user/update-user-data`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name UpdateMaxNumberAmount
     * @request POST:/internal/admin/user/update-max-number-amount
     */
    updateMaxNumberAmount: (data: UpdateUserMaxNumberAmountRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user/update-max-number-amount`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name UpdateDefaultCallAnswerMethod1
     * @request POST:/internal/admin/user/update-default-call-answer-method
     */
    updateDefaultCallAnswerMethod1: (data: AdminUpdateCallAnswerMethodRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user/update-default-call-answer-method`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name UnblockUserWithInvalidLoginCount
     * @request POST:/internal/admin/user/unblock-user-with-invalid-login-count
     */
    unblockUserWithInvalidLoginCount: (data: UserIdRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user/unblock-user-with-invalid-login-count`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name UserChangePush
     * @request POST:/internal/admin/user/send-user-change-push
     */
    userChangePush: (data: ClearUserCacheRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user/send-user-change-push`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name ResyncUserCategories
     * @request POST:/internal/admin/user/resync-categories
     */
    resyncUserCategories: (data: ResyncUserCategoriesRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user/resync-categories`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name ManuallyValidateSimNumberForKyc
     * @request POST:/internal/admin/user/manually-validate-sim-number-for-kyc
     */
    manuallyValidateSimNumberForKyc: (data: KycManualSimNumberValidationRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user/manually-validate-sim-number-for-kyc`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name LogoutDevices2
     * @request POST:/internal/admin/user/logout-user-devices
     */
    logoutDevices2: (data: UserDevicesForLogoutRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user/logout-user-devices`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name GetBytelSubscriptionDataForUser
     * @request POST:/internal/admin/user/get-bytel-subscriptions
     */
    getBytelSubscriptionDataForUser: (data: GetBytelSubscriptionDataRequest, params: RequestParams = {}) =>
      this.request<UserBytelSubscriptionsResponse, string>({
        path: `/internal/admin/user/get-bytel-subscriptions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name GeneratePasswordResetLinks
     * @request POST:/internal/admin/user/generate-password-reset-links
     */
    generatePasswordResetLinks: (data: GeneratePasswordResetLinksRequest, params: RequestParams = {}) =>
      this.request<PasswordResetData[], string>({
        path: `/internal/admin/user/generate-password-reset-links`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name DeleteUser1
     * @request POST:/internal/admin/user/delete
     */
    deleteUser1: (data: UserDeleteRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user/delete`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name ClearUserCache
     * @request POST:/internal/admin/user/clear-cache
     */
    clearUserCache: (data: ClearUserCacheRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user/clear-cache`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-user-controller
     * @name ChangeUserEmail1
     * @request POST:/internal/admin/user/change-user-email
     */
    changeUserEmail1: (data: ChangeUserEmailRequest, params: RequestParams = {}) =>
      this.request<ChangeUserEmailResponse, string>({
        path: `/internal/admin/user/change-user-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-category-controller
     * @name TogglePremiumOffer
     * @request POST:/internal/admin/user-offer/toggle-premium
     */
    togglePremiumOffer: (data: TogglePremiumOfferRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user-offer/toggle-premium`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-category-controller
     * @name CancelSubscription2
     * @request POST:/internal/admin/user-offer/cancel-subscription
     */
    cancelSubscription2: (data: CancelUserOfferSubscriptionAdminRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user-offer/cancel-subscription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-category-controller
     * @name AddPremiumSubscription
     * @request POST:/internal/admin/user-offer/add-premium-subscription
     */
    addPremiumSubscription: (data: AddPremiumSubscriptionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/user-offer/add-premium-subscription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-portability-controller
     * @name PortInConfirmByAdmin
     * @request POST:/internal/admin/portability/port-in-confirm
     */
    portInConfirmByAdmin: (data: PortInAdminConfirmRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/portability/port-in-confirm`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-portability-controller
     * @name PortInCancelByAdmin
     * @request POST:/internal/admin/portability/port-in-cancel
     */
    portInCancelByAdmin: (data: PortInAdminCancelRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/portability/port-in-cancel`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-number-controller
     * @name MoveNumberOutOfStock
     * @request POST:/internal/admin/numbers/move-out-of-stock
     */
    moveNumberOutOfStock: (data: MoveNumberOutOfStockRequest, params: RequestParams = {}) =>
      this.request<SimpleValueResponseBoolean, string>({
        path: `/internal/admin/numbers/move-out-of-stock`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-number-controller
     * @name MoveNumberOutOfStock1
     * @request POST:/internal/admin/numbers/move-out-of-stock-by-ids
     */
    moveNumberOutOfStock1: (data: ListRequestString, params: RequestParams = {}) =>
      this.request<SimpleValueResponseBoolean, string>({
        path: `/internal/admin/numbers/move-out-of-stock-by-ids`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-number-controller
     * @name ListNumbersForRemoval
     * @request POST:/internal/admin/numbers/list-for-removal
     */
    listNumbersForRemoval: (data: MoveNumberOutOfStockRequest, params: RequestParams = {}) =>
      this.request<ListResponsePhoneNumberManagementData, string>({
        path: `/internal/admin/numbers/list-for-removal`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-number-controller
     * @name ListNumbersForRemoval1
     * @request POST:/internal/admin/numbers/list-for-removal-by-ids
     */
    listNumbersForRemoval1: (data: ListRequestString, params: RequestParams = {}) =>
      this.request<ListResponsePhoneNumberManagementData, string>({
        path: `/internal/admin/numbers/list-for-removal-by-ids`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-number-controller
     * @name AddNumber
     * @request POST:/internal/admin/numbers/add
     */
    addNumber: (data: AddNumberRequest, params: RequestParams = {}) =>
      this.request<SimpleValueResponseBoolean, string>({
        path: `/internal/admin/numbers/add`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-misc-controller
     * @name GetListOfSpammers
     * @request POST:/internal/admin/misc/spammers
     */
    getListOfSpammers: (params: RequestParams = {}) =>
      this.request<SpammerInfo[], string>({
        path: `/internal/admin/misc/spammers`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-kyc-controller
     * @name UpdateVerifiedCustomer
     * @request POST:/internal/admin/kyc/verified-customer/update
     */
    updateVerifiedCustomer: (data: VerifiedCustomerRequest, params: RequestParams = {}) =>
      this.request<VerifiedCustomer, string>({
        path: `/internal/admin/kyc/verified-customer/update`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-kyc-controller
     * @name KycDocumentReject
     * @request POST:/internal/admin/kyc/personal-document/validation-reject
     */
    kycDocumentReject: (data: KycDocumentRejectRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/kyc/personal-document/validation-reject`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-kyc-controller
     * @name PrepareDataForDocumentValidationPage
     * @request POST:/internal/admin/kyc/personal-document/validation-page-init
     */
    prepareDataForDocumentValidationPage: (data: DocumentValidationPageRequest, params: RequestParams = {}) =>
      this.request<DocumentValidationPageResponse, string>({
        path: `/internal/admin/kyc/personal-document/validation-page-init`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-category-controller
     * @name UpdateCategoryState
     * @request POST:/internal/admin/category/update-state
     */
    updateCategoryState: (data: CategoryStateUpdateRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/category/update-state`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-category-controller
     * @name ResyncSubscription
     * @request POST:/internal/admin/category/resync-subscription
     */
    resyncSubscription: (data: ResyncSubscriptionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/category/resync-subscription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-category-controller
     * @name ExtendExpirationDate
     * @request POST:/internal/admin/category/extend-expiration-date
     */
    extendExpirationDate: (data: ExtendExpirationDateRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/category/extend-expiration-date`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-category-controller
     * @name CategoryDelete
     * @request POST:/internal/admin/category/delete
     */
    categoryDelete: (data: CategoryDeleteRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/category/delete`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-category-controller
     * @name CancelSubscription3
     * @request POST:/internal/admin/category/cancel-subscription
     */
    cancelSubscription3: (data: CancelSubscriptionRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/admin/category/cancel-subscription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags virtual-phone-number-controller
     * @name GetSystem
     * @request GET:/internal/virtual-phone-number/get-system
     */
    getSystem: (query: { request: VpnGetSystemRequest }, params: RequestParams = {}) =>
      this.request<VpnGetSystemResponse, string>({
        path: `/internal/virtual-phone-number/get-system`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags refund-controller
     * @name GetApplePurchasesForRefund
     * @request GET:/internal/steward/refund/get-apple-purchases-for-refund
     */
    getApplePurchasesForRefund: (query: { fromDateMS: number }, params: RequestParams = {}) =>
      this.request<IdListResponse, string>({
        path: `/internal/steward/refund/get-apple-purchases-for-refund`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc-steward-controller
     * @name GetDataToProcessGracePeriodOver
     * @request GET:/internal/steward/maintenance/kyc-retrieve-data-to-process-grace-period-over
     */
    getDataToProcessGracePeriodOver: (params: RequestParams = {}) =>
      this.request<DataToProcessExpiredGracePeriod, string>({
        path: `/internal/steward/maintenance/kyc-retrieve-data-to-process-grace-period-over`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cndb-belgium-detail-record-controller
     * @name GetCndbBelgiumDetailRecordData
     * @request GET:/internal/steward/cndb-belgium-records
     */
    getCndbBelgiumDetailRecordData: (query?: { offset?: number; limit?: number }, params: RequestParams = {}) =>
      this.request<GetCndbBelgiumRecordResponse, string>({
        path: `/internal/steward/cndb-belgium-records`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags autorenewable-steward-controller
     * @name GetB2BSubscriptionsForRenew
     * @request GET:/internal/steward/autorenewable/b2b/get-subscriptions-for-renew
     */
    getB2BSubscriptionsForRenew: (params: RequestParams = {}) =>
      this.request<B2BSubscriptionsForRenewResponse, string>({
        path: `/internal/steward/autorenewable/b2b/get-subscriptions-for-renew`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags refund-controller
     * @name GetApplePurchasesForRefundDeprecated
     * @request GET:/internal/refund/get-apple-purchases-for-refund
     * @deprecated
     */
    getApplePurchasesForRefundDeprecated: (query: { fromDateMS: number }, params: RequestParams = {}) =>
      this.request<IdListResponse, string>({
        path: `/internal/refund/get-apple-purchases-for-refund`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-users-controller
     * @name GetUserData
     * @request GET:/internal/purchase/users/get-user-data
     */
    getUserData: (query: { request: GetUserDataRequest }, params: RequestParams = {}) =>
      this.request<GetUserDataResponse, string>({
        path: `/internal/purchase/users/get-user-data`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name GetUserOffer
     * @summary To retrieve user offer info with subscription data
     * @request GET:/internal/purchase/get-user-offer
     */
    getUserOffer: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<UserOfferResponse, string>({
        path: `/internal/purchase/get-user-offer`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name GetProductMonths
     * @summary To retrieve amount of months for productId
     * @request GET:/internal/purchase/get-product-months
     */
    getProductMonths: (query: { productId: string }, params: RequestParams = {}) =>
      this.request<ProductMonthsResponse, string>({
        path: `/internal/purchase/get-product-months`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name GetPriceInEur
     * @summary Convert provided currency and price value to EUR
     * @request GET:/internal/purchase/get-price-in-eur
     */
    getPriceInEur: (query: { currency: string; value: number }, params: RequestParams = {}) =>
      this.request<PriceInfoResponse, string>({
        path: `/internal/purchase/get-price-in-eur`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name GetUserCategories
     * @summary To retrieve categories info with subscription data
     * @request GET:/internal/purchase/get-categories
     */
    getUserCategories: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<UserCategoriesResponse, string>({
        path: `/internal/purchase/get-categories`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchase-controller
     * @name GetAutoRenewableProduct
     * @summary To retrieve autorenewable productIds from provided productId
     * @request GET:/internal/purchase/get-autorenewable-product
     */
    getAutoRenewableProduct: (query: { productId: string }, params: RequestParams = {}) =>
      this.request<AutoRenewableProductResponse, string>({
        path: `/internal/purchase/get-autorenewable-product`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-off-calls-controller
     * @name FindSimCardNumber1
     * @request GET:/internal/onoffcall/find-sim-card-number
     */
    findSimCardNumber1: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<OnOffCallCountryIsoCodeResponse, string>({
        path: `/internal/onoffcall/find-sim-card-number`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags on-off-calls-controller
     * @name GetOnOffCallsEnabled
     * @request GET:/internal/onoffcall/enabled
     */
    getOnOffCallsEnabled: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<IsOnoffCallsEnabledResponse, string>({
        path: `/internal/onoffcall/enabled`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-messages-controller
     * @name RetrieveCallLogsForPeriod
     * @request GET:/internal/messages/retrieve
     */
    retrieveCallLogsForPeriod: (
      query: { user: string; to: string; from: string; time?: number },
      params: RequestParams = {},
    ) =>
      this.request<MessageDeliveryInfo[], string>({
        path: `/internal/messages/retrieve`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-user-device-controller
     * @name GetPushDevices
     * @request GET:/internal/contacts/get-push-devices
     */
    getPushDevices: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<PushDevicesResponse, string>({
        path: `/internal/contacts/get-push-devices`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-user-device-controller
     * @name GetPushDevices1
     * @request GET:/internal/auth/get-push-devices
     */
    getPushDevices1: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<PushDevicesResponse, string>({
        path: `/internal/auth/get-push-devices`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-user-device-controller
     * @name GetPushDevices2
     * @request GET:/internal/integrations/get-push-devices
     */
    getPushDevices2: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<PushDevicesResponse, string>({
        path: `/internal/integrations/get-push-devices`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags greetings-controller
     * @name GetUserDataByPhoneNumber
     * @request GET:/internal/greetings/user-data
     */
    getUserDataByPhoneNumber: (query: { request: PhoneNumberRequest }, params: RequestParams = {}) =>
      this.request<GreetingsUserDataResponse, string>({
        path: `/internal/greetings/user-data`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags greetings-controller
     * @name NumberLookup
     * @request GET:/internal/greetings/number-lookup
     */
    numberLookup: (query: { phoneNumber: string }, params: RequestParams = {}) =>
      this.request<NumberLookupResponse, string>({
        path: `/internal/greetings/number-lookup`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags greetings-controller
     * @name GetUserDataById
     * @request GET:/internal/greetings/b2c-user-data
     */
    getUserDataById: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<GreetingsUserDataListResponse, string>({
        path: `/internal/greetings/b2c-user-data`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-greetings-controller
     * @name GetB2BUserData
     * @request GET:/internal/greetings/b2b-user-data
     */
    getB2BUserData: (query: { request: GetB2BUserGreetingsDataRequest }, params: RequestParams = {}) =>
      this.request<GreetingsUserDataListResponse, string>({
        path: `/internal/greetings/b2b-user-data`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags service-contacts-controller
     * @name GetActiveCategoryWithName1
     * @request GET:/internal/contacts/active-category-with-name
     */
    getActiveCategoryWithName1: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<ActiveCategoryWithNameResponse, string>({
        path: `/internal/contacts/active-category-with-name`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-recording-controller
     * @name Access
     * @request GET:/internal/call-recording/access
     */
    access: (query: { objectId: string; userId: string; organizationId?: string }, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/internal/call-recording/access`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-plans-controller
     * @name GetPlanForCountry
     * @request GET:/internal/b2b/plan-for-country
     */
    getPlanForCountry: (query: { request: B2BPlanCountryRequest }, params: RequestParams = {}) =>
      this.request<B2BPlanCountryRO, string>({
        path: `/internal/b2b/plan-for-country`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2b-number-controller
     * @name ListB2BReservedNumbers
     * @request GET:/internal/b2b/list-reserved-numbers
     */
    listB2BReservedNumbers: (query: { request: B2BGetReservedNumbersRequest }, params: RequestParams = {}) =>
      this.request<Record<string, B2BGetReservedNumbersResponse[]>, string>({
        path: `/internal/b2b/list-reserved-numbers`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2b-number-controller
     * @name GetB2BQuarantineNumbers
     * @request GET:/internal/b2b/list-quarantine-numbers
     */
    getB2BQuarantineNumbers: (query: { request: B2BGetQuarantineNumbersRequest }, params: RequestParams = {}) =>
      this.request<B2BGetQuarantineNumbersResponse, string>({
        path: `/internal/b2b/list-quarantine-numbers`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2b-number-controller
     * @name ListB2BNumbers
     * @request GET:/internal/b2b/list-b2b-numbers
     */
    listB2BNumbers: (query: { request: B2BGetNumbersRequest }, params: RequestParams = {}) =>
      this.request<Record<string, string[]>, string>({
        path: `/internal/b2b/list-b2b-numbers`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-plans-controller
     * @name ListAvailableCountries
     * @request GET:/internal/b2b/list-available-countries
     */
    listAvailableCountries: (params: RequestParams = {}) =>
      this.request<B2BCountriesRO, string>({
        path: `/internal/b2b/list-available-countries`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-global-credits-controller
     * @name GetGlobalCreditsHistory
     * @request GET:/internal/b2b/get-global-credit-history
     */
    getGlobalCreditsHistory: (query: { request: BillingHocsB2BGetCreditsHistoryRequest }, params: RequestParams = {}) =>
      this.request<B2BGlobalCredits[], string>({
        path: `/internal/b2b/get-global-credit-history`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-global-credits-controller
     * @name GetGlobalCreditsAmount
     * @request GET:/internal/b2b/get-global-credit-amount
     */
    getGlobalCreditsAmount: (query: { request: B2BGetGlobalCreditsAmountRequest }, params: RequestParams = {}) =>
      this.request<B2BGetGlobalCreditsAmountResponse, string>({
        path: `/internal/b2b/get-global-credit-amount`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-price-controller
     * @name GetBillingRatesB2B
     * @request GET:/internal/b2b/get-billing-rates
     */
    getBillingRatesB2B: (query: { request: UsagePriceRequest }, params: RequestParams = {}) =>
      this.request<BillingRate[], string>({
        path: `/internal/b2b/get-billing-rates`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-controller
     * @name GetUsers
     * @request GET:/internal/b2b/get-b2b-users
     */
    getUsers: (query: { request: B2BGetUsersRequest }, params: RequestParams = {}) =>
      this.request<B2BUsersRO, string>({
        path: `/internal/b2b/get-b2b-users`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-plans-controller
     * @name NumbersAvailableForCountry
     * @request GET:/internal/b2b/available-number-amount
     */
    numbersAvailableForCountry: (query: { request: B2BNumberAmountRequest }, params: RequestParams = {}) =>
      this.request<B2BNumbersAvailableRO, string>({
        path: `/internal/b2b/available-number-amount`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voip-service-controller
     * @name AuthorizeOutgoingVoipCallV2
     * @request GET:/internal/anonymous/voip/authorize-outgoing-call-route
     */
    authorizeOutgoingVoipCallV2: (query: { request: VoipCallRouteRequest }, params: RequestParams = {}) =>
      this.request<VoipCallRouteV2Response, string>({
        path: `/internal/anonymous/voip/authorize-outgoing-call-route`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voip-service-controller
     * @name AuthorizeIncomingVoipCallV2
     * @request GET:/internal/anonymous/voip/authorize-call-route-softswitch
     */
    authorizeIncomingVoipCallV2: (query: { request: SoftswitchVoipCallRouteRequest }, params: RequestParams = {}) =>
      this.request<VoipCallRouteV2Response, string>({
        path: `/internal/anonymous/voip/authorize-call-route-softswitch`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voip-service-controller
     * @name AuthenticateVoipUser
     * @request GET:/internal/anonymous/voip/authentication
     */
    authenticateVoipUser: (query: { request: VoipAuthenticationRequest }, params: RequestParams = {}) =>
      this.request<VoipAuthenticationResponse, string>({
        path: `/internal/anonymous/voip/authentication`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-portability-controller
     * @name GetPortOutStatusAll
     * @request GET:/internal/admin/portability/port-out-status
     */
    getPortOutStatusAll: (params: RequestParams = {}) =>
      this.request<AdminPortabilityStatusResponse, string>({
        path: `/internal/admin/portability/port-out-status`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-portability-controller
     * @name GetPortInStatusAll
     * @request GET:/internal/admin/portability/port-in-status
     */
    getPortInStatusAll: (params: RequestParams = {}) =>
      this.request<AdminPortabilityStatusResponse, string>({
        path: `/internal/admin/portability/port-in-status`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-portability-controller
     * @name GetPortabilityHistory
     * @request GET:/internal/admin/portability/port-in-history
     */
    getPortabilityHistory: (query: { phoneNumber: string }, params: RequestParams = {}) =>
      this.request<PortabilityRecordResponse, string>({
        path: `/internal/admin/portability/port-in-history`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-portability-controller
     * @name GetRioCode
     * @request GET:/internal/admin/portability/get-rio-code
     */
    getRioCode: (query: { request: GetPortabilityCodeRequest }, params: RequestParams = {}) =>
      this.request<GetPortabilityCodeResponse, string>({
        path: `/internal/admin/portability/get-rio-code`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-portability-controller
     * @name GetPortabilityRecords
     * @request GET:/internal/admin/portability/get-records
     */
    getPortabilityRecords: (query: { phoneNumber: string }, params: RequestParams = {}) =>
      this.request<PortabilityRecordResponse, string>({
        path: `/internal/admin/portability/get-records`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-kyc-controller
     * @name GetVerifiedCustomer
     * @request GET:/internal/admin/kyc/verified-customer/get
     */
    getVerifiedCustomer: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<VerifiedCustomer, string>({
        path: `/internal/admin/kyc/verified-customer/get`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags internal-admin-category-controller
     * @name GetAutoRenewableDebugData
     * @request GET:/internal/admin/category/get-auto-renewable-debug-data
     */
    getAutoRenewableDebugData: (query: { request: GetAutoRenewableDebugDataRequest }, params: RequestParams = {}) =>
      this.request<GetAutoRenewableDebugDataResponse, string>({
        path: `/internal/admin/category/get-auto-renewable-debug-data`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
  external = {
    /**
     * No description
     *
     * @tags bytel-registration-controller
     * @name Register2
     * @request POST:/external/web-site/bytel/register
     */
    register2: (data: BytelRegisterRequest, params: RequestParams = {}) =>
      this.request<BytelRegisterResponse, string>({
        path: `/external/web-site/bytel/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bytel-registration-controller
     * @name CheckChecksum
     * @request POST:/external/web-site/bytel/checksum-verify
     */
    checkChecksum: (data: BytelChecksumVerifyRequest, params: RequestParams = {}) =>
      this.request<StatusOkResponse, string>({
        path: `/external/web-site/bytel/checksum-verify`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bytel-registration-controller
     * @name CheckEmailGetMethod
     * @request GET:/external/web-site/bytel/check-email
     */
    checkEmailGetMethod: (query: { request: BytelCheckEmailRequest }, params: RequestParams = {}) =>
      this.request<BytelCheckEmailResponse, string>({
        path: `/external/web-site/bytel/check-email`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bytel-registration-controller
     * @name CheckEmail
     * @request POST:/external/web-site/bytel/check-email
     */
    checkEmail: (data: BytelCheckEmailRequest, params: RequestParams = {}) =>
      this.request<BytelCheckEmailResponse, string>({
        path: `/external/web-site/bytel/check-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bytel-registration-controller
     * @name AssignToExistingAccount
     * @request POST:/external/web-site/bytel/attach-offer
     */
    assignToExistingAccount: (data: BytelAttachOfferRequest, params: RequestParams = {}) =>
      this.request<BytelRegisterResponse, string>({
        path: `/external/web-site/bytel/attach-offer`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags veriff-webhook-controller
     * @name HandleManualPoaDecision
     * @request POST:/external/veriff/poa-manual-decision
     */
    handleManualPoaDecision: (data: VeriffManualDecisionData, params: RequestParams = {}) =>
      this.request<void, string>({
        path: `/external/veriff/poa-manual-decision`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags veriff-webhook-controller
     * @name HandlePoaEvent
     * @request POST:/external/veriff/poa-event
     */
    handlePoaEvent: (data: VeriffEventData, params: RequestParams = {}) =>
      this.request<void, string>({
        path: `/external/veriff/poa-event`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags veriff-webhook-controller
     * @name HandlePoaDecision
     * @request POST:/external/veriff/poa-decision
     */
    handlePoaDecision: (data: VeriffDecisionData, params: RequestParams = {}) =>
      this.request<void, string>({
        path: `/external/veriff/poa-decision`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags veriff-webhook-controller
     * @name HandleManualIdvDecision
     * @request POST:/external/veriff/idv-manual-decision
     */
    handleManualIdvDecision: (data: VeriffManualDecisionData, params: RequestParams = {}) =>
      this.request<void, string>({
        path: `/external/veriff/idv-manual-decision`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags veriff-webhook-controller
     * @name HandleIdvEvent
     * @request POST:/external/veriff/idv-event
     */
    handleIdvEvent: (data: VeriffEventData, params: RequestParams = {}) =>
      this.request<void, string>({
        path: `/external/veriff/idv-event`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags veriff-webhook-controller
     * @name HandleIdvDecision
     * @request POST:/external/veriff/idv-decision
     */
    handleIdvDecision: (data: VeriffDecisionData, params: RequestParams = {}) =>
      this.request<void, string>({
        path: `/external/veriff/idv-decision`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags branch-controller
     * @name SubscribeCustomer
     * @request POST:/external/branch/on-signup
     */
    subscribeCustomer: (data: OnSignupRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/external/branch/on-signup`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bouygues-controller
     * @name Terminate
     * @request POST:/external/bouygues/subscription/cancel
     */
    terminate: (data: BytelCancelRequest, params: RequestParams = {}) =>
      this.request<BytelSuccessResponse, string>({
        path: `/external/bouygues/subscription/cancel`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bouygues-controller
     * @name Activate
     * @request POST:/external/bouygues/subscription/activate
     */
    activate: (data: BytelActivateRequest, params: RequestParams = {}) =>
      this.request<BytelSuccessResponse, string>({
        path: `/external/bouygues/subscription/activate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags o-auth-2-fake-controller
     * @name CreateToken
     * @request POST:/external/bouygues/oauth2/token
     */
    createToken: (query?: { grant_type?: string }, params: RequestParams = {}) =>
      this.request<OAuth2FakeTokenResponse, string>({
        path: `/external/bouygues/oauth2/token`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags billing-v-2-webhook-controller
     * @name ReceiveLifecycleResponse
     * @request POST:/external/billing/webhook/response
     */
    receiveLifecycleResponse: (data: BillingHocsResponse, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/external/billing/webhook/response`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bouygues-controller
     * @name Status2
     * @request GET:/external/bouygues/subscription/status
     */
    status2: (query?: { id?: string }, params: RequestParams = {}) =>
      this.request<BytelStatusResponse, string>({
        path: `/external/bouygues/subscription/status`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
  b2BConsole = {
    /**
     * No description
     *
     * @tags b-2-b-call-recording-controller
     * @name EmployeesCallRecordingStatus
     * @request POST:/b2b-console/organization/employee/call-recording/status
     */
    employeesCallRecordingStatus: (data: B2BEmployeesCallRecordingStatusRequest, params: RequestParams = {}) =>
      this.request<B2BEmployeeCallRecordingStatusRO, string>({
        path: `/b2b-console/organization/employee/call-recording/status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-recording-controller
     * @name EnableCallRecordingForEmployee
     * @request POST:/b2b-console/organization/employee/call-recording/enable
     */
    enableCallRecordingForEmployee: (data: UpdateUsersCallRecordingPropertyRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/b2b-console/organization/employee/call-recording/enable`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-recording-controller
     * @name DisableCallRecordingForEmployee
     * @request POST:/b2b-console/organization/employee/call-recording/disable
     */
    disableCallRecordingForEmployee: (data: UpdateUsersCallRecordingPropertyRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/b2b-console/organization/employee/call-recording/disable`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-tag-definition-controller
     * @name UpdateCallTag
     * @request POST:/b2b-console/organization/call-tag/update
     */
    updateCallTag: (data: B2BOrganizationCallTagUpdateRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/b2b-console/organization/call-tag/update`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-tag-definition-controller
     * @name DeleteCallTags
     * @request POST:/b2b-console/organization/call-tag/delete
     */
    deleteCallTags: (data: B2BOrganizationCallTagDeleteRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/b2b-console/organization/call-tag/delete`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-tag-definition-controller
     * @name CreateCallTag
     * @request POST:/b2b-console/organization/call-tag/create
     */
    createCallTag: (data: B2BOrganizationCallTagCreateRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/b2b-console/organization/call-tag/create`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-recording-controller
     * @name TranscribeCallRecording
     * @request POST:/b2b-console/organization/call-recording/transcription
     */
    transcribeCallRecording: (data: CallRecordingTranscriptionRequest, params: RequestParams = {}) =>
      this.request<CallRecordingTranscriptionResponse, string>({
        path: `/b2b-console/organization/call-recording/transcription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-recording-controller
     * @name EnableCallRecording
     * @request POST:/b2b-console/organization/call-recording/enable
     */
    enableCallRecording: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/b2b-console/organization/call-recording/enable`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-recording-controller
     * @name DisableCallRecording
     * @request POST:/b2b-console/organization/call-recording/disable
     */
    disableCallRecording: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/b2b-console/organization/call-recording/disable`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-recording-controller
     * @name RegisterCallRecordingBulkDownload1
     * @request GET:/b2b-console/organization/call-recording/bulk-download
     */
    registerCallRecordingBulkDownload1: (params: RequestParams = {}) =>
      this.request<CallRecordingBulkDownloadsResponse, string>({
        path: `/b2b-console/organization/call-recording/bulk-download`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-recording-controller
     * @name RegisterCallRecordingBulkDownload
     * @request POST:/b2b-console/organization/call-recording/bulk-download
     */
    registerCallRecordingBulkDownload: (data: CallRecordingBulkDownloadRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/b2b-console/organization/call-recording/bulk-download`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-whispering-call-controller
     * @name RegisterWhisperCall
     * @request POST:/b2b-console/calls/whispering/register
     */
    registerWhisperCall: (data: B2BRegisterWhisperingCallRequest, params: RequestParams = {}) =>
      this.request<B2BRegisterWhisperingCallResponse, string>({
        path: `/b2b-console/calls/whispering/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-calls-controller
     * @name SearchLogsV2
     * @request POST:/b2b-console/calls/logs
     */
    searchLogsV2: (data: B2BCallLogSearchRequest, params: RequestParams = {}) =>
      this.request<B2BCallLogResponse, string>({
        path: `/b2b-console/calls/logs`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-calls-controller
     * @name DeleteRecording
     * @request POST:/b2b-console/calls/logs/recordings/delete
     */
    deleteRecording: (data: CallRecordingDeleteRequest, params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/b2b-console/calls/logs/recordings/delete`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-calls-controller
     * @name DeleteRecordings
     * @request POST:/b2b-console/calls/logs/recordings/bulk-delete
     */
    deleteRecordings: (data: B2BCallRecordingBulkDeleteRequest, params: RequestParams = {}) =>
      this.request<B2BCallRecordingBulkDeleteResponse, string>({
        path: `/b2b-console/calls/logs/recordings/bulk-delete`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-tag-definition-controller
     * @name GetCallTags
     * @request GET:/b2b-console/organization/call-tag/get
     */
    getCallTags: (query: { hidden: boolean; deleted: boolean }, params: RequestParams = {}) =>
      this.request<B2BOrganizationGetCallTagsResponse, string>({
        path: `/b2b-console/organization/call-tag/get`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-tag-definition-controller
     * @name Count
     * @request GET:/b2b-console/organization/call-tag/count
     */
    count: (query: { hidden: boolean; deleted: boolean }, params: RequestParams = {}) =>
      this.request<B2BOrganizationCallTagsCountResponse, string>({
        path: `/b2b-console/organization/call-tag/count`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags b-2-b-call-recording-controller
     * @name GetCallRecordingStatus
     * @request GET:/b2b-console/organization/call-recording/status
     */
    getCallRecordingStatus: (params: RequestParams = {}) =>
      this.request<B2BCallRecordingStatusRO, string>({
        path: `/b2b-console/organization/call-recording/status`,
        method: 'GET',
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @tags health-controller
     * @name Health
     * @request GET:/health
     */
    health: (params: RequestParams = {}) =>
      this.request<EmptyResponse, string>({
        path: `/health`,
        method: 'GET',
        ...params,
      }),
  };
  healthOk = {
    /**
     * No description
     *
     * @tags health-controller
     * @name HealthOk
     * @request GET:/healthOK
     */
    healthOk: (params: RequestParams = {}) =>
      this.request<HealthResponse, string>({
        path: `/healthOK`,
        method: 'GET',
        ...params,
      }),
  };
}

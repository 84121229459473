import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { PLANS_AND_OFFERS } from '@constants';

import { PlansAndOffersActionTypes, PlansAndOffersReduxState, REDUX_ACTION_TYPES } from '../../types';

export const plansAndOffersInitialState: PlansAndOffersReduxState = {
  statuses: {
    fetchUserOffer: Status.IDLE,
    cancelUserOffer: Status.IDLE,
    fetchUserPlanPremiumPrice: Status.IDLE,
    submitUserPlanPremium: Status.IDLE,
    fetchAvailableOffersAndPlans: Status.IDLE,
    changeDuration: Status.IDLE,
    cancelChangeDuration: Status.IDLE,
  },
  userOffer: {
    subscriptionId: undefined,
    status: undefined,
    source: undefined,
    expirationDate: undefined,
    planDuration: undefined,
  },
  premiumPlans: [],
  selectedPremiumPlan: {
    month: PLANS_AND_OFFERS.DEFAULT_PREMIUM_PERIOD,
    price: 0,
    monthlyPrice: 0,
    yearlyPrice: 0,
    currencyCode: '',
    productId: '',
  },
  availableOffers: [],
  availablePlans: {},
  subscriptionIdForRenewalPeriodChange: '',
};

export const plansAndOffersReducer: Reducer<PlansAndOffersReduxState, PlansAndOffersActionTypes> = (state, action) => {
  const reducerState = state ?? plansAndOffersInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_STATUS_FETCH_USEROFFER: {
      const { status } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchUserOffer: status,
        },
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_STATUS_CANCEL_USEROFFER: {
      const { status } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          cancelUserOffer: status,
        },
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_USEROFFER: {
      const { userOffer } = action;

      return {
        ...reducerState,
        userOffer,
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_PREMIUM_OFFER_PRICE_CONFIG: {
      const { priceConfig } = action;

      return {
        ...reducerState,
        premiumPlans: priceConfig,
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_SELECTED_PREMIUM_OFFER: {
      const { premiumPlan } = action;

      return {
        ...reducerState,
        selectedPremiumPlan: premiumPlan,
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_PREMIUM_FETCH_PRICE_STATUS: {
      const { status } = action;

      if (status === Status.FAILED) {
        return {
          ...reducerState,
          premiumPlans: plansAndOffersInitialState.premiumPlans,
          selectedPremiumPlan: plansAndOffersInitialState.selectedPremiumPlan,
          statuses: {
            ...reducerState.statuses,
            fetchUserPlanPremiumPrice: status,
          },
        };
      }

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchUserPlanPremiumPrice: status,
        },
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_PREMIUM_SUBMIT_OFFER_STATUS: {
      const { status } = action;
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          submitUserPlanPremium: status,
        },
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_RESET_PREMIUM_OFFER_PRICE_CONFIG: {
      return {
        ...reducerState,
        premiumPlans: plansAndOffersInitialState.premiumPlans,
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_STATUS_FETCH_AVAILABLE_OFFERS_AND_PLANS: {
      const { status } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchAvailableOffersAndPlans: status,
        },
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_AVAILABLE_OFFERS: {
      const { availableOffers } = action;

      return {
        ...reducerState,
        availableOffers,
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_ADD_PLANS: {
      const { plans } = action;

      return {
        ...reducerState,
        availablePlans: {
          ...reducerState.availablePlans,
          ...plans,
        },
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_STATUS_CHANGE_DURATION: {
      const { status } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          changeDuration: status,
        },
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_STATUS_CANCEL_CHANGE_DURATION: {
      const { status } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          cancelChangeDuration: status,
        },
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_USEROFFER_NEXT_PLAN_DURATION: {
      return {
        ...reducerState,
        userOffer: {
          ...reducerState.userOffer,
          nextPlanDuration: action.nextPlanDuration,
        },
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_REMOVE_USEROFFER_NEXT_PLAN_DURATION: {
      const { nextPlanDuration: _propertyToDelete, ...updatedUserOffer } = reducerState.userOffer;

      return {
        ...reducerState,
        userOffer: {
          ...updatedUserOffer,
        },
      };
    }

    case REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_SUBSCRIPTION_ID_FOR_RENEWAL_PERIOD_CHANGE: {
      return {
        ...reducerState,
        subscriptionIdForRenewalPeriodChange: action.subscriptionIdForRenewalPeriodChange,
      };
    }

    default:
      return reducerState;
  }
};

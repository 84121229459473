import { setUser as sentrySetUserInfo } from '@sentry/react';

import { AuthLoginRequest, GAEventKey, Status } from 'types';

import {
  authenticationHasAuth,
  authenticationRemoveAndRedirectToRoot,
  getUserAgentData,
  isApiError,
  isString,
  multiDeviceGetToken,
} from 'helpers';
import { getFunnelEventVariables, localStorageRemoveFunnelAnalyticsData } from 'helpers/funnelAnalytics';

import { API_ERRORS, LOCALSTORAGE_KEYS, SESSIONSTORAGE_KEYS, SUPPORTED_LANGUAGES } from '@constants';

import { selectUserGeoInfoData, selectUserLanguage } from '../../selectors';
import {
  AuthenticationActionSetCaptchaRendered,
  AuthenticationActionSetCaptchaRequire,
  AuthenticationActionSetLoginErrorCode,
  AuthenticationActionSetStatusCaptchaCheck,
  AuthenticationActionSetStatusCaptchaVerifyAndRegisterToken,
  AuthenticationActionSetStatusLogin,
  AuthenticationActionSetStatusLogout,
  AuthenticationActionSetStatusPasswordRecoveryChange,
  AuthenticationActionSetStatusPasswordRecoveryRequest,
  AuthenticationActionSetStatusPasswordRecoveryVerify,
  AuthenticationActionSetStatusRenewAuthKey,
  AuthenticationCaptchaVerifyAndRegisterTokenProps,
  AuthenticationLoginHandlerProps,
  AuthenticationLogoutHandlerProps,
  AuthenticationPasswordRecoveryChangePasswordHandlerProps,
  AuthenticationPasswordRecoveryRequestRecoveryHandlerProps,
  AuthenticationPasswordRecoveryVerifyRecoveryTokenHandlerProps,
  REDUX_ACTION_TYPES,
  ThunkResult,
} from '../../types';

export const authenticationSetStatusCaptchaCheck = (
  statusCaptchaCheck: Status,
): AuthenticationActionSetStatusCaptchaCheck => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_CAPTCHA_CHECK,
  statusCaptchaCheck,
});

export const authenticationSetStatusCaptchaVerifyAndRegisterToken = (
  statusCaptchaVerifyAndRegisterToken: Status,
): AuthenticationActionSetStatusCaptchaVerifyAndRegisterToken => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_CAPTCHA_VERIFY_AND_REGISTER,
  statusCaptchaVerifyAndRegisterToken,
});

export const authenticationSetStatusRenewAuthKey = (
  statusRenewAuthKey: Status,
): AuthenticationActionSetStatusRenewAuthKey => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_RENEW_AUTH_KEY,
  statusRenewAuthKey,
});

export const authenticationSetStatusLogin = (statusLogin: Status): AuthenticationActionSetStatusLogin => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_LOGIN,
  statusLogin,
});

export const authenticationSetStatusLogout = (statusLogout: Status): AuthenticationActionSetStatusLogout => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_LOGOUT,
  statusLogout,
});

export const authenticationSetStatusPasswordRecoveryRequest = (
  statusPasswordRecoveryRequest: Status,
): AuthenticationActionSetStatusPasswordRecoveryRequest => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_REQUEST,
  statusPasswordRecoveryRequest,
});

export const authenticationSetStatusPasswordRecoveryVerify = (
  statusPasswordRecoveryVerify: Status,
): AuthenticationActionSetStatusPasswordRecoveryVerify => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_VERIFY,
  statusPasswordRecoveryVerify,
});

export const authenticationSetStatusPasswordRecoveryChange = (
  statusPasswordRecoveryChange: Status,
): AuthenticationActionSetStatusPasswordRecoveryChange => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_CHANGE,
  statusPasswordRecoveryChange,
});

export const authenticationSetCaptchaRequire = (isCaptchaRequired: boolean): AuthenticationActionSetCaptchaRequire => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_CAPTCHA_REQUIRE,
  isCaptchaRequired,
});

export const authenticationSetCaptchaRendered = (
  isCaptchaRendered: boolean,
): AuthenticationActionSetCaptchaRendered => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_CAPTCHA_RENDERED,
  isCaptchaRendered,
});

export const authenticationSetLoginErrorCode = (loginErrorCode: string): AuthenticationActionSetLoginErrorCode => ({
  type: REDUX_ACTION_TYPES.AUTHENTICATION_SET_LOGIN_ERROR_CODE,
  loginErrorCode,
});

export const authenticationCaptchaCheckStatusHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(authenticationSetStatusCaptchaVerifyAndRegisterToken(Status.IDLE));

      dispatch(authenticationSetStatusCaptchaCheck(Status.LOADING));

      const mobileToken = multiDeviceGetToken();
      const { captchaRequired: isCaptchaRequired = true } = await services.authService.captchaCheckStatus({
        mobileToken,
      });

      dispatch(authenticationSetCaptchaRequire(isCaptchaRequired));

      dispatch(authenticationSetStatusCaptchaCheck(Status.SUCCEEDED));

      if (isCaptchaRequired === false) {
        dispatch(authenticationSetStatusCaptchaVerifyAndRegisterToken(Status.SUCCEEDED));
      }
    } catch {
      dispatch(authenticationSetCaptchaRequire(true));
      dispatch(authenticationSetStatusCaptchaCheck(Status.FAILED));
    }
  };

export const authenticationCaptchaVerifyAndRegisterTokenHandler =
  ({ captchaTokenByGoogle }: AuthenticationCaptchaVerifyAndRegisterTokenProps): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(authenticationSetStatusCaptchaVerifyAndRegisterToken(Status.LOADING));

      /**
       * The API will return the same "mobileToken" (as a value) in response
       * because we are generating and posting our own "mobileToken" during the request.
       * If we are not generating a token, they will generate a new token on behalf of us.
       * We want to have this generated token in our control,
       * because of SIP Calls, Multi-Device Management and so on...
       */
      const webAppMobileToken = multiDeviceGetToken();
      const { mobileToken: _apiMobileTokenSameAsWebAppMobileToken } =
        await services.authService.captchaVerifyAndRegisterToken({
          recaptchaToken: captchaTokenByGoogle,
          mobileToken: webAppMobileToken,
        });

      dispatch(authenticationSetStatusCaptchaVerifyAndRegisterToken(Status.SUCCEEDED));
    } catch {
      dispatch(authenticationSetStatusCaptchaVerifyAndRegisterToken(Status.FAILED));
    }
  };

export const authenticationCaptchaResetStatusesHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    dispatch(authenticationSetStatusCaptchaCheck(Status.IDLE));
    dispatch(authenticationSetStatusCaptchaVerifyAndRegisterToken(Status.IDLE));
    dispatch(authenticationSetCaptchaRequire(true));
    dispatch(authenticationSetCaptchaRendered(false));
  };

export const authenticationRenewAuthKeyHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(authenticationSetStatusRenewAuthKey(Status.LOADING));

      const isUserAuthenticated = authenticationHasAuth();
      if (isUserAuthenticated === false) {
        /**
         * Old WebApp Users
         */
        throw new Error('Dev Error: Necessarily Tokens are not exist, Logout the User');
      }

      const { key: authKey = '', userDeviceId = '' } = await services.authService.renewAuthKey();

      if (authKey.length === 0 || userDeviceId.length === 0) {
        throw new Error('Dev Error: Renew Auth Key Response is not valid');
      }

      window.localStorage.setItem(LOCALSTORAGE_KEYS.AUTH_KEY, authKey);
      window.localStorage.setItem(LOCALSTORAGE_KEYS.USER_DEVICE_ID, userDeviceId);

      dispatch(authenticationSetStatusRenewAuthKey(Status.SUCCEEDED));
    } catch {
      dispatch(authenticationSetStatusRenewAuthKey(Status.FAILED));

      authenticationRemoveAndRedirectToRoot();
    }
  };

export const authenticationLoginHandler =
  ({ email, password }: AuthenticationLoginHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services) => {
    try {
      dispatch(authenticationSetStatusLogin(Status.LOADING));
      dispatch(authenticationSetLoginErrorCode(''));

      const googleClientId = await services.analyticsService.fetchGoogleTagManagerClientId();
      const userLanguage = selectUserLanguage(getState());
      const { countryCode, timeZone } = selectUserGeoInfoData(getState());
      const { os, browser, device } = getUserAgentData();
      const loginRequestData: AuthLoginRequest = {
        email,
        password,
        googleClientId,
        mobileToken: multiDeviceGetToken(),
        locale: userLanguage,
        timeZone,
        simCountryIsoCodes: [countryCode],
        deviceName: `${device?.vendor || os.name} - ${device?.model || browser.name}`,
        deviceOSName: `${os.name}/${os.version}`,
        deviceHardware: `${browser.name}/${browser.version}`,
      };

      /**
       * First: Fetch and Set User Access Token to the LocalStorage (Plus API Instance)
       * Plus: Do error handling
       */
      const { accessToken = '' } = await services.authService.login(loginRequestData);
      if (accessToken.length === 0) {
        throw new Error('Dev Error: User Login Response (AccessToken) is not valid');
      }
      window.localStorage.setItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN, accessToken);

      /**
       * Second: Make the Renew Auth Key Request to get the AuthKey and UserDeviceId and Set Them to the LocalStorage
       * Plus: Do error handling
       */
      const { key: authKey = '', userDeviceId = '' } = await services.authService.renewAuthKey();
      if (authKey.length === 0 || userDeviceId.length === 0) {
        throw new Error('Dev Error: User Login Response (AuthKey or UserDeviceId) is not valid');
      }
      window.localStorage.setItem(LOCALSTORAGE_KEYS.AUTH_KEY, authKey);
      window.localStorage.setItem(LOCALSTORAGE_KEYS.USER_DEVICE_ID, userDeviceId);

      // Second: Change the State
      dispatch(authenticationSetStatusLogin(Status.SUCCEEDED));
    } catch (error) {
      /**
       * We handle the errors if we know them, and return
       */
      const isKnownErrorCode =
        isApiError(error) &&
        (
          [
            API_ERRORS.USER.USER_BLOCKED,
            API_ERRORS.USER.USER_BLOCKED_INVALID_PASSWORD,
            API_ERRORS.USER.USER_VALIDATION_INVALID,
            API_ERRORS.USER.USER_B2C_LOGIN_B2B,
            API_ERRORS.USER.USER_B2B_LOGIN_B2C,
          ] as string[]
        ).includes(error.code);

      if (isKnownErrorCode) {
        dispatch(authenticationSetLoginErrorCode(error.code));
        dispatch(authenticationSetStatusLogin(Status.FAILED));
        return;
      }

      /**
       * Even if we don't know what was the error
       * we set it up, to be able to reset state on login page
       */
      dispatch(authenticationSetLoginErrorCode(API_ERRORS.HTTP.UNKNOWN_ERROR));
      dispatch(authenticationSetStatusLogin(Status.FAILED));

      // And, these unknown errors will be handled in Global Error Handler
      throw error;
    }
  };

export const authenticationLogoutHandler =
  ({ shouldCallLogoutAPI = true }: AuthenticationLogoutHandlerProps = {}): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services) => {
    try {
      dispatch(authenticationSetStatusLogout(Status.LOADING));

      // If we force logout through PubNub, the API call will return 401 Unauthorized, hence skip it
      if (shouldCallLogoutAPI) {
        await services.authService.logout();
      }

      // Authentication Related
      window.localStorage.removeItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN);
      window.localStorage.removeItem(LOCALSTORAGE_KEYS.AUTH_KEY);
      window.localStorage.removeItem(LOCALSTORAGE_KEYS.USER_DEVICE_ID);

      // Purchase Related
      window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.PURCHASE_CATEGORY_ID);
      window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.PURCHASE_PHONE_NUMBER);
      window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.PURCHASE_EMAIL);
      window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.CHECKOUT_CALLBACK_REDIRECTION_PATH);
      window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.SELECTED_PREMIUM_PLAN);

      // Browser tabs Related
      window.localStorage.removeItem(LOCALSTORAGE_KEYS.BROWSER_TABS_ACTIVE_ID);
      window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.BROWSER_TABS_CURRENT_ID);

      // SIP/VoIP
      window.localStorage.removeItem(LOCALSTORAGE_KEYS.SIP_INSTANCE_ID);

      sentrySetUserInfo(null);

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.FUNNEL_ANALYTICS_LOG_OUT,
        variables: getFunnelEventVariables({}),
      });

      localStorageRemoveFunnelAnalyticsData();

      dispatch(authenticationSetStatusLogout(Status.SUCCEEDED));
    } catch (error) {
      dispatch(authenticationSetStatusLogout(Status.FAILED));
      throw error;
    }
  };

export const authenticationPasswordRecoveryRequestRecoveryHandler =
  ({ email }: AuthenticationPasswordRecoveryRequestRecoveryHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(authenticationSetStatusPasswordRecoveryRequest(Status.LOADING));

      const mobileToken = multiDeviceGetToken();
      await services.authService.passwordRecoveryRequestRecovery({ email, mobileToken });

      dispatch(authenticationSetStatusPasswordRecoveryRequest(Status.SUCCEEDED));
    } catch {
      dispatch(authenticationSetStatusPasswordRecoveryRequest(Status.FAILED));
    }
  };

export const authenticationPasswordRecoveryVerifyRecoveryTokenHandler =
  ({ tokenRecovery }: AuthenticationPasswordRecoveryVerifyRecoveryTokenHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(authenticationSetStatusPasswordRecoveryVerify(Status.LOADING));

      const { status } = await services.authService.passwordRecoveryVerifyRecoveryToken({
        token: tokenRecovery,
      });

      if (!isString(status) || status.toLocaleLowerCase(SUPPORTED_LANGUAGES.EN) !== 'ok') {
        throw new Error('Dev Error: Verify Recovery Token Response is not valid');
      }

      dispatch(authenticationSetStatusPasswordRecoveryVerify(Status.SUCCEEDED));
    } catch {
      dispatch(authenticationSetStatusPasswordRecoveryVerify(Status.FAILED));
    }
  };

export const authenticationPasswordRecoveryChangePasswordHandler =
  ({
    passwordNew,
    passwordRepeat,
    tokenRecovery,
  }: AuthenticationPasswordRecoveryChangePasswordHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(authenticationSetStatusPasswordRecoveryChange(Status.LOADING));

      const { status } = await services.authService.passwordRecoveryChangePassword({
        firstPwd: passwordNew,
        secondPwd: passwordRepeat,
        token: tokenRecovery,
      });

      if (!isString(status) || status.toLocaleLowerCase(SUPPORTED_LANGUAGES.EN) !== 'ok') {
        throw new Error('Dev Error: Change Password Response is not valid');
      }

      dispatch(authenticationSetStatusPasswordRecoveryChange(Status.SUCCEEDED));
    } catch {
      dispatch(authenticationSetStatusPasswordRecoveryChange(Status.FAILED));
    }
  };

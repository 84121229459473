import { setUser as sentrySetUserInfo } from '@sentry/react';
import { CountryCode } from 'libphonenumber-js';

import { showErrorToast, showSuccessToast } from '@onoff/toast-notification';

import {
  AccountType,
  AllowedCountries,
  AvailableCountries,
  GAEventKey,
  NotificationsModalTypes,
  Status,
  SupportedLanguage,
  User,
  UserCallAnswerMethods,
} from 'types';

import { generateAndSetSIPInstanceId } from 'helpers';
import { handleApplicationError } from 'helpers/errorHandling';
import { getFunnelEventVariables } from 'helpers/funnelAnalytics';

import { SUPPORTED_LANGUAGES } from '@constants';
import { getIntl, setIntlLanguage } from '@intl';

import { selectUserIsCallQualitySurveyPermissionEnabled } from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  UserDeleteHandlerProps,
  UserPubNubUpdateAccountTypeAndFeaturesHandlerProps,
  UserResetStoreAction,
  UserSetAction,
  UserSetAllowedCountriesAction,
  UserSetAvailableCountriesAction,
  UserSetUpdateEmailStatusAction,
  UserSetUpdateLanguageStatusAction,
  UserSetUpdateNameStatusAction,
  UserSetUpdatePasswordStatusAction,
  UserUpdateAccountTypeAndFeaturesAction,
  UserUpdateAccountTypeAndFeaturesActionProps,
  UserUpdateAction,
  UserUpdateLocaleAction,
  UserUpdateNameHandlerProps,
  UserUpdatePasswordHandlerProps,
} from '../../types';
import { analyticsAddUserDataToFunnelAnalyticsData, analyticsResendDataLayerVariables } from '../analytics';
import { authenticationLogoutHandler, authenticationSetLoginErrorCode } from '../authentication';
import { browserTabsSetCurrentBrowserTabAsActiveHandler } from '../browserTabs';
import { callLogsFetchDataHandler } from '../callLogs';
import { fetchCategories } from '../categories';
import { fetchContacts } from '../contacts';
import { integrationsFetchActiveIntegrationsHandler } from '../integrations';
import { multiDeviceFetchDeviceListHandler } from '../multiDevice';
import { notificationsModalSetData } from '../notificationsModal';
import { privacyFetchBlockedNumbersHandler } from '../privacy';
import { pushDeletePushesHandler, pushGetPushesHandler } from '../push';
import { threadsFetchHandler } from '../threads';
import { userGeoInfoFetchGeoInfoDataHandler } from '../userGeoInfo';
import { voicemailsFetchVoicemailsHandler } from '../voicemails';

export const userSetUpdateLanguageStatus = (status: Status): UserSetUpdateLanguageStatusAction => ({
  type: REDUX_ACTION_TYPES.USER_SET_UPDATE_LANGUAGE_STATUS,
  status,
});

export const userSetUpdateNameStatus = (status: Status): UserSetUpdateNameStatusAction => ({
  type: REDUX_ACTION_TYPES.USER_SET_UPDATE_NAME_STATUS,
  status,
});

export const userSetUpdateEmailStatus = (status: Status): UserSetUpdateEmailStatusAction => ({
  type: REDUX_ACTION_TYPES.USER_SET_UPDATE_EMAIL_STATUS,
  status,
});

export const userSetUpdatePasswordStatus = (status: Status): UserSetUpdatePasswordStatusAction => ({
  type: REDUX_ACTION_TYPES.USER_SET_UPDATE_PASSWORD_STATUS,
  status,
});

export const userSet = (user: User): UserSetAction => ({
  type: REDUX_ACTION_TYPES.USER_SET,
  user,
});

export const userUpdate = (user: Partial<User>): UserUpdateAction => ({
  user,
  type: REDUX_ACTION_TYPES.USER_UPDATE,
});

export const userUpdateAccountTypeAndFeatures = ({
  accountType,
  features,
}: UserUpdateAccountTypeAndFeaturesActionProps): UserUpdateAccountTypeAndFeaturesAction => ({
  type: REDUX_ACTION_TYPES.USER_SET_ACCOUNT_TYPE_AND_FEATURES,
  accountType,
  features,
});

export const userSetAllowedCountries = (countries: AllowedCountries): UserSetAllowedCountriesAction => ({
  type: REDUX_ACTION_TYPES.USER_SET_ALLOWED_COUNTRIES,
  countries,
});

export const userSetAvailableCountries = (countries: AvailableCountries): UserSetAvailableCountriesAction => ({
  type: REDUX_ACTION_TYPES.USER_SET_AVAILABLE_COUNTRIES,
  countries,
});

export const userSetLanguage = (language: SupportedLanguage): UserUpdateLocaleAction => ({
  type: REDUX_ACTION_TYPES.USER_SET_LANGUAGE,
  language,
});

export const userResetStore = (): UserResetStoreAction => ({
  type: REDUX_ACTION_TYPES.USER_RESET_STORE,
});

export const userFetchAllowedCountriesHandler =
  (): ThunkResult<Promise<void>> =>
  (dispatch, _, services): Promise<void> =>
    services.userService.fetchUserAllowedCountries().then((allowedCountries) => {
      dispatch(userSetAllowedCountries(allowedCountries));
    });

export const userFetchAvailableCountriesHandler =
  (): ThunkResult<Promise<void>> =>
  (dispatch, _, services): Promise<void> =>
    services.userService.fetchUserAvailableCountries().then((availableCountries) => {
      dispatch(userSetAvailableCountries(availableCountries));
    });

export const userFetchKycStatus =
  (): ThunkResult<Promise<void>> =>
  (dispatch, _, services): Promise<void> =>
    services.userService.fetchKycStatus().then((status) => {
      dispatch(userUpdate({ kycStatus: status }));
    });

export const userSetLanguageHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    const supportedLanguage = services.localizationService.getLocale();

    setIntlLanguage(supportedLanguage);

    dispatch(userSetLanguage(supportedLanguage));
  };

export const userInitHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(browserTabsSetCurrentBrowserTabAsActiveHandler());

      generateAndSetSIPInstanceId();

      const user = await services.userService.fetchUser();

      if (user.userId === undefined || user.email === undefined) {
        throw new Error('Dev Error: User Response is not valid');
      }

      sentrySetUserInfo({ id: user.userId });

      const userWebApp: User = {
        ...user,
        userId: user.userId,
        email: user.email,
        b2bUser: user.b2bUser === true,
        kycStatus: {},
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        showEmergencyCallsWarning: !!user.showEmergencyCallsWarning,
        nationalCountryIsoCode: (user.nationalCountryIsoCode || SUPPORTED_LANGUAGES.FR) as CountryCode,
        countryIsoCode: (user.nationalCountryIsoCode || SUPPORTED_LANGUAGES.FR) as CountryCode,
        communicationLocale: (user.communicationLocale || SUPPORTED_LANGUAGES.FR) as SupportedLanguage,
        callAnswerMethod: (user.callAnswerMethod || UserCallAnswerMethods.VOIP) as UserCallAnswerMethods,
        allowedCountries: { supported: [] },
        availableCountries: { supported: [] },
      };

      await Promise.all([
        // user related
        dispatch(userSet(userWebApp)),
        dispatch(userGeoInfoFetchGeoInfoDataHandler()),
        dispatch(userFetchAllowedCountriesHandler()),
        dispatch(userFetchAvailableCountriesHandler()),
        dispatch(userFetchKycStatus()),

        // global
        dispatch(multiDeviceFetchDeviceListHandler()),
        dispatch(privacyFetchBlockedNumbersHandler()),
        dispatch(integrationsFetchActiveIntegrationsHandler()),

        // pubnub
        dispatch(pushDeletePushesHandler()),
        dispatch(pushGetPushesHandler()),

        dispatch(fetchCategories()),

        dispatch(analyticsAddUserDataToFunnelAnalyticsData()),
      ]);

      dispatch(analyticsResendDataLayerVariables(GAEventKey.INIT_USER));
    } catch (error) {
      dispatch(authenticationSetLoginErrorCode('500'));
      throw error;
    }
  };

export const userFetchContentHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    try {
      await Promise.all([
        dispatch(fetchContacts()),
        dispatch(callLogsFetchDataHandler()),
        dispatch(threadsFetchHandler()),
        dispatch(voicemailsFetchVoicemailsHandler()),
      ]);
    } catch (error) {
      handleApplicationError({ error });
    }
  };

export const userDeleteHandler =
  ({ password, errorNotification, deletionReasonId }: UserDeleteHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, __, services): Promise<void> => {
    const { result: isValidPassword } = await services.userService.checkPassword(password);

    if (isValidPassword) {
      await services.userService.deleteUser();

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.USER_ACCOUNT_DELETED,
        variables: { user_account_deletion_reason: deletionReasonId },
      });

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.FUNNEL_ANALYTICS_ACCOUNT_DELETE,
        variables: getFunnelEventVariables({}),
      });

      dispatch(authenticationLogoutHandler({ shouldCallLogoutAPI: false }));
    } else {
      showErrorToast({
        heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_error' }),
        message: errorNotification,
      });
    }
  };

export const userUpdateEmailHandler =
  (email: User['email']): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(userSetUpdateEmailStatus(Status.LOADING));

      await services.userService.updateEmail(email);

      showSuccessToast({
        heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_success' }),
        message: getIntl().formatMessage({ id: 'Settings.update_email_success' }),
      });

      dispatch(userUpdate({ email }));

      services.analyticsService.pushToDataLayer({ event: GAEventKey.USER_EMAIL_CHANGE_SUCCESS });

      dispatch(userSetUpdateEmailStatus(Status.SUCCEEDED));
    } catch (error) {
      handleApplicationError({ error });
      dispatch(userSetUpdateEmailStatus(Status.FAILED));
    }
  };

export const userUpdatePasswordHandler =
  ({ password, newPassword }: UserUpdatePasswordHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, __, services): Promise<void> => {
    try {
      dispatch(userSetUpdatePasswordStatus(Status.LOADING));

      await services.userService.updatePassword(password, newPassword);

      services.analyticsService.pushToDataLayer({ event: GAEventKey.USER_CHANGE_PASSWORD_SUCCESS });

      dispatch(userSetUpdatePasswordStatus(Status.SUCCEEDED));
    } catch (error) {
      dispatch(userSetUpdatePasswordStatus(Status.FAILED));
      throw error;
    }
  };

export const userUpdateLanguageHandler =
  (language: SupportedLanguage): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    try {
      dispatch(userSetUpdateLanguageStatus(Status.LOADING));

      await services.userService.updateLanguage(language);

      services.localizationService.setLocale(language);

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.USER_LANGUAGE_CHANGED,
        variables: { user_selected_language: language },
      });

      setIntlLanguage(language);

      dispatch(userSetLanguage(language));
      dispatch(userSetUpdateLanguageStatus(Status.SUCCEEDED));
    } catch (error) {
      dispatch(userSetUpdateLanguageStatus(Status.FAILED));
      throw error;
    }
  };

export const userUpdateNameHandler =
  ({ firstName, lastName }: UserUpdateNameHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(userSetUpdateNameStatus(Status.LOADING));

      await services.userService.updateName(firstName, lastName);

      dispatch(userUpdate({ firstName, lastName }));
      dispatch(userSetUpdateNameStatus(Status.SUCCEEDED));
    } catch (error) {
      dispatch(userSetUpdateNameStatus(Status.FAILED));
      throw error;
    }
  };

export const userPubNubUpdateAccountTypeAndFeaturesHandler =
  ({ accountType, userFeatures }: UserPubNubUpdateAccountTypeAndFeaturesHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    dispatch(
      userUpdateAccountTypeAndFeatures({
        accountType,
        features: userFeatures,
      }),
    );

    if (accountType === AccountType.PREMIUM) {
      dispatch(
        notificationsModalSetData({
          isVisible: true,
          modalType: NotificationsModalTypes.PREMIUM_OFFER_PUBNUB,
        }),
      );
    }
  };

export const userToggleCallQualitySurveyPermissionHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    const currentCallQualitySurvey = selectUserIsCallQualitySurveyPermissionEnabled(getState());

    try {
      dispatch(userUpdate({ callQualitySurvey: !currentCallQualitySurvey }));
      await services.userService.toggleCallQualitySurveyPermission({ enabled: !currentCallQualitySurvey });
    } catch (error) {
      dispatch(userUpdate({ callQualitySurvey: currentCallQualitySurvey }));
      throw error;
    }
  };

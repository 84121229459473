import {
  DataLayerVariablesFunnelEvent,
  EnvDevice,
  EnvTemplate,
  EventVariableUserPremium,
  FunnelAnalyticsData,
} from 'types';

import { isString, sessionStoragePersistenceGetSelectedPremiumPlan } from 'helpers';

import { BREAKPOINTS, LOCALSTORAGE_KEYS } from '@constants';
import { ApplicationPaths } from 'routes';
import services from 'services';

type GetFunnelEventVariablesProps = {
  pathname?: string;
  customVariables?: Partial<DataLayerVariablesFunnelEvent>;
  customECommerceVariables?: Partial<DataLayerVariablesFunnelEvent['ecommerce']>;
};

const localStorageGetFunnelAnalyticsData = () => {
  const data = window.localStorage.getItem(LOCALSTORAGE_KEYS.FUNNEL_ANALYTICS_DATA);
  if (isString(data)) {
    return JSON.parse(data) as FunnelAnalyticsData;
  }
  return undefined;
};

export const localStorageAddToFunnelAnalyticsData = (data: FunnelAnalyticsData): void => {
  const currentData = localStorageGetFunnelAnalyticsData();
  window.localStorage.setItem(
    LOCALSTORAGE_KEYS.FUNNEL_ANALYTICS_DATA,
    JSON.stringify({
      ...currentData,
      ...data,
    }),
  );
};

export const localStorageRemoveFunnelAnalyticsData = (): void => {
  window.localStorage.removeItem(LOCALSTORAGE_KEYS.FUNNEL_ANALYTICS_DATA);
};

const getEnvDevice = (): DataLayerVariablesFunnelEvent['envDevice'] => {
  const isMobile = window.matchMedia(`(max-width: ${BREAKPOINTS.MAX_WIDTH_MOBILE})`).matches;
  const isTablet = !isMobile && window.matchMedia(`(max-width: ${BREAKPOINTS.MAX_WIDTH_TABLET})`).matches;

  if (isMobile) {
    return EnvDevice.M;
  }

  if (isTablet) {
    return EnvDevice.T;
  }

  return EnvDevice.D;
};

const getEnvTemplate = (pathname = ''): DataLayerVariablesFunnelEvent['envTemplate'] | undefined => {
  switch (pathname) {
    case ApplicationPaths.ROOT:
      return EnvTemplate.LOGIN;
    case ApplicationPaths.PASSWORD_RECOVERY:
      return EnvTemplate.PASS_RESET;
    case ApplicationPaths.CALLS:
      return EnvTemplate.WEBAPP_CALLS;
    case ApplicationPaths.MESSAGES:
      return EnvTemplate.WEBAPP_MESSAGE;
    case ApplicationPaths.VOICEMAILS:
      return EnvTemplate.WEBAPP_VOICEMAILS;
    case ApplicationPaths.CONTACTS:
      return EnvTemplate.WEBAPP_CONTACTS;
    case ApplicationPaths.SETTINGS_ACCOUNT:
      return EnvTemplate.WEBAPP_ACC_SETTINGS;
    case ApplicationPaths.SETTINGS_PLANS:
      return EnvTemplate.WEBAPP_PLANS;
    case ApplicationPaths.SETTINGS_BILLING:
      return EnvTemplate.WEBAPP_BILLING;
    case ApplicationPaths.SETTINGS_PREFERENCES:
      return EnvTemplate.WEBAPP_PREFERENCES;
    case ApplicationPaths.PURCHASE_PREMIUM_UPGRADE:
      return EnvTemplate.PREMIUM_UPGRADE;
    default:
      return undefined;
  }
};

export const getFunnelEventVariables = ({
  pathname,
  customVariables,
  customECommerceVariables,
}: GetFunnelEventVariablesProps): DataLayerVariablesFunnelEvent => {
  const { userId, userEmail, userFirstname, userLastname, isUserPremium, paymentMethod } =
    localStorageGetFunnelAnalyticsData() || {};

  const eventVariables: DataLayerVariablesFunnelEvent = {
    envDevice: getEnvDevice(),
    envLanguage: services.localizationService.getLocale(),
    envWork: process.env.REACT_APP_ENV ?? '',
  };

  const envTemplate = getEnvTemplate(pathname);
  if (envTemplate) {
    eventVariables.envTemplate = envTemplate;
  }

  if (![userId, userEmail, userFirstname, userLastname].includes(undefined)) {
    eventVariables.userId = userId;
    eventVariables.userEmail = userEmail;
    eventVariables.userFirstname = userFirstname;
    eventVariables.userLastname = userLastname;
    eventVariables.userPremium = isUserPremium ? EventVariableUserPremium.Y : EventVariableUserPremium.N;
  }

  const selectedPremiumPlan = sessionStoragePersistenceGetSelectedPremiumPlan();

  if (selectedPremiumPlan) {
    eventVariables.ecommerce = { items: [] };

    eventVariables.ecommerce.items?.push({
      index: 0,
      item_id: selectedPremiumPlan.productId,
      price: selectedPremiumPlan.price,
      quantity: 1,
    });
  }

  if (customECommerceVariables) {
    eventVariables.ecommerce = {
      ...eventVariables.ecommerce,
      payment_type: paymentMethod,
      ...customECommerceVariables,
    };
  }

  return { ...eventVariables, ...customVariables };
};

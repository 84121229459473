import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@onoff/ui';

import { OfferRenewalPeriod } from 'types';

import {
  plansAndOfferSetSelectedPremiumOfferWithPersistence,
  selectPlansAndOffersSelectedPremium,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import { InfoBoxSimple, InfoBoxVariant } from 'components/InfoBox';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalSize } from 'components/Modal';
import { useUserCapabilities } from 'hooks';
import { usePlanPricesToDisplay } from 'hooks/usePlanPricesToDisplay';

import ModalsPremiumPlanFeatureIcon from './components/ModalsPremiumPlanFeatureIcon';
import PremiumPlanSelector from './components/PremiumPlanSelector';
import { ModalsPremiumPlanTypes } from './enums';
import { getFeatureList, getLabels } from './helpers';

import styles from './ModalsPremiumPlan.module.scss';

export interface ModalsPremiumPlanProps {
  isOpen: boolean;
  type: ModalsPremiumPlanTypes;
  loading?: boolean;
  onRequestClose?: () => void;
  onSubmit?: () => void;
}

export { ModalsPremiumPlanTypes } from './enums';

export const ModalsPremiumPlan: React.FC<ModalsPremiumPlanProps> = ({
  isOpen,
  type,
  loading = false,
  onRequestClose = () => null,
  onSubmit = () => null,
}) => {
  const isTypeInfo = type === ModalsPremiumPlanTypes.INFO;
  const isTypeUpgrade = type === ModalsPremiumPlanTypes.UPGRADE;
  const isTypeUnsubscribe = type === ModalsPremiumPlanTypes.UNSUBSCRIBE;
  const isTypeTrial = type === ModalsPremiumPlanTypes.TRIAL;

  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { isUserB2B } = useUserCapabilities();
  const selectedPremiumPlan = useWebAppSelector(selectPlansAndOffersSelectedPremium);
  const [selectedPeriod, setSelectedPeriod] = useState<OfferRenewalPeriod>(selectedPremiumPlan.month);
  const planPricesToDisplay = usePlanPricesToDisplay();
  const planPricesAvailable = planPricesToDisplay.filter((plan) => (isTypeTrial ? plan.trialAvailable : plan));

  useEffect(() => {
    setSelectedPeriod(selectedPremiumPlan.month);
  }, [selectedPremiumPlan]);

  const featureList = getFeatureList(intl);
  const labels = getLabels({
    intl,
    type,
    currencyCode: selectedPremiumPlan.currencyCode,
    price: planPricesAvailable.find((plan) => plan.month === selectedPeriod)?.price,
    period: selectedPeriod,
  });

  return (
    <Modal
      zIndex={20}
      isOpen={isOpen}
      size={ModalSize.XXL}
      onRequestClose={onRequestClose}
      className={styles.root}
    >
      <ModalHeader
        className={styles.header}
        classNameTitle={styles.headerTitle}
        title={labels.title}
        onCloseButtonClick={onRequestClose}
        isCloseButtonDisabled={loading}
      />

      <ModalBody className={styles.content}>
        <p className={styles.description}>{labels.description}</p>

        {(isTypeTrial || isTypeUpgrade) && planPricesAvailable.length === 2 && (
          <PremiumPlanSelector
            plans={planPricesAvailable}
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
          />
        )}

        {isTypeUnsubscribe && (
          <InfoBoxSimple
            variant={InfoBoxVariant.WARNING}
            className={styles.infoBox}
          >
            {labels.infoBox}
          </InfoBoxSimple>
        )}

        <div className={styles.features}>
          {featureList.map((feature) => (
            <div
              key={feature.title}
              className={styles.feature}
            >
              <ModalsPremiumPlanFeatureIcon
                iconType={feature.icon}
                className={styles.featureIcon}
              />
              <div className={styles.featureContent}>
                <h4 className={styles.featureTitle}>{feature.title}</h4>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        {isTypeTrial && <p className={styles.trialInfo}>{labels.infoBox}</p>}
      </ModalBody>

      {!isTypeInfo && (
        <ModalFooter className={classNames(styles.footer, { [styles.upgrade]: isTypeUpgrade || isTypeTrial })}>
          {(isTypeUpgrade || isTypeTrial) && (
            <div className={styles.footerSubscriptionInfo}>
              <div className={styles.footerTitle}>{labels.footerTitle}</div>
              <div className={styles.footerPriceInfo}>{labels.footerPriceInfo}</div>
            </div>
          )}

          {isTypeUnsubscribe && (
            <Button
              className={styles.footerButton}
              colorScheme="black"
              variant="ghost"
              size="large"
              onClick={onRequestClose}
              disabled={loading}
            >
              {labels.buttonCancel}
            </Button>
          )}

          <Button
            className={styles.footerButton}
            colorScheme={isUserB2B ? 'brand-b2b' : 'brand-b2c'}
            variant="solid"
            size="large"
            onClick={() => {
              dispatch(plansAndOfferSetSelectedPremiumOfferWithPersistence(selectedPeriod));
              onSubmit();
            }}
            loading={loading}
            disabled={loading}
          >
            {labels.buttonConfirm}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

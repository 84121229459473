import classnames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import { LINKS } from '@constants';
import { Balance } from 'components/Balance';
import { ButtonColorScheme, ButtonDashboard, ButtonDashboardVariant } from 'components/Button';
import CategoryList from 'components/CategoryList';
import { useUserCapabilities } from 'hooks';
import StealthMode from 'layouts/components/StealthMode';

import ButtonSupport from '../ButtonSupport';

import styles from './SideMenuCategories.module.scss';

export interface SideMenuCategoriesProps {
  isMinimized: boolean;
}

const SideMenuCategories: React.FC<SideMenuCategoriesProps> = ({ isMinimized }) => {
  const intl = useIntl();
  const { isUserB2C, isUserB2B, hasCategories } = useUserCapabilities();
  const showGetNumberButton = !isMinimized && hasCategories && isUserB2C;

  const handleGetNumberButtonClick = () => {
    window.open(LINKS.WEBSITE_WSJ, '_blank');
  };

  return (
    <div
      className={classnames(styles.root, {
        [styles.minimized]: isMinimized,
      })}
    >
      {!isUserB2B && !isMinimized && <StealthMode />}

      {!isUserB2B && (
        <div className={styles.balance}>
          <Balance isMinimized={isMinimized} />
        </div>
      )}

      <div
        className={classnames(styles.categories, {
          [styles.isB2B]: isUserB2B,
        })}
      >
        <CategoryList isMinimized={isMinimized} />

        {showGetNumberButton && (
          <ButtonDashboard
            className={styles.getNumberButton}
            colorScheme={ButtonColorScheme.B2C}
            variant={ButtonDashboardVariant.OUTLINED}
            onClick={handleGetNumberButtonClick}
            text={intl.formatMessage({ id: 'SideMenuCategories.get_number_button_text' })}
          />
        )}
      </div>

      <div className={styles.support}>
        <ButtonSupport isMinimized={isMinimized} />
      </div>

      <span className={styles.shadow}></span>
    </div>
  );
};

export default SideMenuCategories;

import { FormattedMessage } from 'react-intl';

import styles from './KYCRequirement.module.scss';

type KYCRequirementProps = {
  isUserB2C: boolean;
};

const KYCRequirement = ({ isUserB2C }: KYCRequirementProps) => (
  <>
    <h2 className={styles.header}>
      <FormattedMessage id={`FeatureBlockSplash.kyc_required_header_${isUserB2C ? 'b2c' : 'b2b'}`} />
    </h2>
    <div>
      <FormattedMessage id={`FeatureBlockSplash.kyc_required_description_${isUserB2C ? 'b2c' : 'b2b'}`} />
    </div>
  </>
);

export default KYCRequirement;

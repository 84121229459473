import { useLocation } from 'react-router';

import { selectHasCategories, selectUserKycRequired, useWebAppSelector } from '@redux';
import { FeatureBlockSplashFeature } from 'components/FeatureBlockSplash/enums';
import { useUserCapabilities } from 'hooks';
import { ApplicationPaths } from 'routes';

type UseApplicationPathBlock = () => {
  isCurrentPathBlocked: boolean;
  featureBlockFeatureType: FeatureBlockSplashFeature | undefined;
};

const useApplicationPathBlock: UseApplicationPathBlock = () => {
  const { pathname } = useLocation();
  const { isUserStarter, isUserPremium, isUserB2B } = useUserCapabilities();
  const isKycRequired = useWebAppSelector(selectUserKycRequired);
  const hasCategories = useWebAppSelector(selectHasCategories);

  const currentPath = pathname as ApplicationPaths;

  const blockedPaths: ApplicationPaths[] = [
    ApplicationPaths.CALLS,
    ApplicationPaths.MESSAGES,
    ApplicationPaths.VOICEMAILS,
    ApplicationPaths.CONTACTS,
  ];

  const blockedPathsForPremiumOrB2BWithoutCategories: ApplicationPaths[] = [
    ApplicationPaths.CALLS,
    ApplicationPaths.MESSAGES,
    ApplicationPaths.VOICEMAILS,
  ];

  const pathToFeatureMap = new Map([
    [ApplicationPaths.CALLS, FeatureBlockSplashFeature.CALLS],
    [ApplicationPaths.MESSAGES, FeatureBlockSplashFeature.MESSAGES],
    [ApplicationPaths.VOICEMAILS, FeatureBlockSplashFeature.VOICEMAILS],
    [ApplicationPaths.CONTACTS, FeatureBlockSplashFeature.CONTACTS],
  ]);

  const isCurrentPathBlocked =
    (!!isKycRequired && blockedPaths.includes(currentPath)) ||
    (!hasCategories &&
      (isUserPremium || isUserB2B) &&
      blockedPathsForPremiumOrB2BWithoutCategories.includes(currentPath)) ||
    (isUserStarter && blockedPaths.includes(currentPath));

  const featureBlockFeatureType = pathToFeatureMap.get(currentPath);

  return { isCurrentPathBlocked, featureBlockFeatureType };
};

export default useApplicationPathBlock;
